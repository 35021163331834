import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MbApi_OperatorDelete, MbApi_OperatorList } from "../../mb_api/mb_api_operator";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Permission, RecordsForPage, usePermission } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import { MbAnagraficaNewOperator } from "../mb_anagrafica_new_operator";

const MbAnagraficaOperator = ({isValidator, callbackFromValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(0);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const navigate                              = useNavigate();
    const permission                            = usePermission();

    function fetchData(){
        MbApi_OperatorList(page, search).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.operators.map((item, index) => 
                        items.push([item.id, item.code, item.description, item.name, item.access_code, item.device_setting ? item.device_setting.code : "", item])
                    )
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        
        MbSaveColumnsForTable("Operatori", [
            "ID",
            "Codice", 
            "Descrizione", 
            "Nome", 
            //"Codice di accesso", 
            //"Parametri"
        ]);

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewOperator
                        comeback={() => {
                            setPage(-1);
                            setStructUpdate(null);
                            //navigate(-1);
                        }}
                        structure={structUpdate}
                    />
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"Operatori"}
                        columns={
                            MbProposeColumns(
                                'Operatori',
                                [
					                {"name": "ID"}, 
                                    {"name": "Codice"}, 
                                    {"name": "Descrizione"}, 
                                    {"name": "Nome"}, 
                                    //{"name": "Codice di accesso"}, 
                                    //{"name": "Parametri"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={permission.p_operator_delete == 1}
                        displayNew={permission.p_operator_create == 1}
                        displayRead
                        displayUpdate={permission.p_operator_create == 1}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={(data) => {
                            setStructUpdate(data);
                            window.location.hash = '#new';
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_OperatorDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(data) => {
                            if(isValidator){
                                callbackFromValidator(data);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_OPERATORI"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaOperator
}