import { Button, TextField, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_ProductionDepartmentCreate } from "../mb_api/mb_api_production_department";

const MbAnagraficaNewProductionDepartment = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [production_departmentCode, setProductionDepartmentCode] = useState(null);
    const [production_departmentDesc, setProductionDepartmentDesc] = useState(null);
    const [printer_model, setPrinter_model] = useState(null);
    const [ip_address, setIp_address] = useState(null);
    const [port, setPort] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){ }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            setId(structure[0]);
            setProductionDepartmentCode(structure[1]);
            setProductionDepartmentDesc(structure[2]);
            setPrinter_model(structure[3].toLowerCase());
            setIp_address(structure[4]);
            setPort(structure[5]);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} reparto di produzione</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={production_departmentCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setProductionDepartmentCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={production_departmentDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setProductionDepartmentDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label" shrink>Stampante</InputLabel>
                <Select
                    notched 
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={printer_model}
                    label="Stampante"
                    onChange={(e) => {
                        setPrinter_model(e.target.value);
                    }}
                >
                    <MenuItem value={'custom'}>Custom</MenuItem>
                    <MenuItem value={'epson'}>Epson</MenuItem>
                </Select>
            </FormControl>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Indirizzo IPv4"} style={{width: "100%"}} value={ip_address} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setIp_address(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Porta"} style={{width: "100%"}} value={port} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setPort(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!production_departmentCode || !production_departmentDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(production_departmentCode.length > 5 || production_departmentDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!ip_address || !port || !printer_model){
                        setMessage('Stampante, ip e porta sono campi obbligatori!');
                        return;                        
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_ProductionDepartmentCreate(JSON.stringify({
                            id: id,
                            code: production_departmentCode,
                            description: production_departmentDesc,
                            printer_model: printer_model,
                            ip_address: ip_address,
                            port: port
                        }));

                        setDisplayBackDrop(false);
                        setMessage(result ? 'Reparto di produzione creato con successo!' : 'Errore durante la creazione del reparto di produzione!');

                        if(result){
                            setId(null);
                            setProductionDepartmentCode('');
                            setProductionDepartmentDesc('');
                            setPrinter_model(null);
                            setIp_address('');
                            setPort('');
                            comeback();
                        }

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

        </>
    );

};

export {
    MbAnagraficaNewProductionDepartment
}