import { Button, TextField, Typography, Grid, FormControl, MenuItem, InputLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_CausalCreate } from "../mb_api/mb_api_causal";
import { MbValidatorDocType } from "../mb_validator/mb_validator_doc_type";
import Select from '@mui/material/Select';
import { MbValidatorStorage } from "../mb_validator/mb_validator_storage";

const MbAnagraficaNewCausal = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [causalCode, setCausalCode] = useState(null);
    const [causalDesc, setCausalDesc] = useState(null);
    const [docEnum, setDocEnum] = useState(null);
    const [docType, setDocType] = useState(null);
    const [docDesc, setDocDesc] = useState(null);
    const [fiscal, setFiscal] = useState(null);
    const [storageId, setStorageId] = useState(null);
    const [storageCode, setStorageCode] = useState(null);
    const [charge, setCharge] = useState(0);
    const [discharge, setDischarge] = useState(0);
    const [ordered, setOrdered] = useState(0);
    const [engaged, setEngaged] = useState(0);
    const [prefix, setPrefix] = useState(null);
    const [accounting, setAccounting] = useState(0);
    const [proposeFor, setProposeFor] = useState('customer');
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);

    // Validatori
    const [validatorDocType, setValidatorDocType] = useState(false);
    const [validatorStorage, setValidatorStorage] = useState(false);

    const eKeyDown = (e) => {
        if(window.location.hash.includes('#doc_type') || window.location.hash.includes('#storage')){
            return;
        }
        SaveAna(e);
    }

    function eHashChange(){ 
        setValidatorDocType(window.location.hash.includes('#doc_type') && !validatorDocType);
        setValidatorStorage(window.location.hash.includes('#storage') && !validatorStorage);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            setId(structure[0]);
            setCausalCode(structure[1]);
            setCausalDesc(structure[2]);
            setDocEnum(structure[5]);
            setDocType(structure[4]);
            setDocDesc(structure[3]);
            setStorageCode(structure[11]);
            setStorageId(structure[10]);
            setCharge(structure[6]);
            setDischarge(structure[7]);
            setOrdered(structure[8]);
            setEngaged(structure[9]);
            setPrefix(structure[12]);
            setAccounting(structure[13]);
            setProposeFor(structure[14]);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuova"} causale</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={causalCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCausalCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={causalDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCausalDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Tipologia documento"} style={{width: "100%"}} value={docType} disabled InputLabelProps={{ shrink: true }} InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#doc_type";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Deposito"} style={{width: "100%"}} value={storageCode} disabled InputLabelProps={{ shrink: true }} InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#storage";
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 3 : 12}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Carico</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={charge} label="Carico" onChange={(e) => {  setCharge(e.target.value)  }}>
                            <MenuItem value={-1}>-</MenuItem>
                            <MenuItem value={0}>NON MOVIMENTA</MenuItem>
                            <MenuItem value={1}>+</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 3 : 12}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Scarico</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={discharge} label="Scarico" onChange={(e) => {  setDischarge(e.target.value)  }}>
                            <MenuItem value={-1}>-</MenuItem>
                            <MenuItem value={0}>NON MOVIMENTA</MenuItem>
                            <MenuItem value={1}>+</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 3 : 12}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Ordinato</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={ordered} label="Ordinato" onChange={(e) => {  setOrdered(e.target.value)  }}>
                            <MenuItem value={-1}>-</MenuItem>
                            <MenuItem value={0}>NON MOVIMENTA</MenuItem>
                            <MenuItem value={1}>+</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 3 : 12}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Impegnato</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={engaged} label="Impegnato" onChange={(e) => {  setEngaged(e.target.value)  }}>
                            <MenuItem value={-1}>-</MenuItem>
                            <MenuItem value={0}>NON MOVIMENTA</MenuItem>
                            <MenuItem value={1}>+</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Prefisso contatore"} style={{width: "100%"}} value={prefix} disabled={structure} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setPrefix(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 3 : 6}>
                    <FormControl size="small" fullWidth>
                        <InputLabel id="lb-controparte">Controparte</InputLabel>
                        <Select labelId="lb-controparte" id="select-controparte" label="Controparte" value={proposeFor} onChange={(e, value) => {
                            setProposeFor(e.target.value);
                        }}>
                            {
                                //<MenuItem value="empty">Nessuna</MenuItem>
                            }
                            <MenuItem value="customer">Clienti</MenuItem>
                            <MenuItem value="supplier">Fornitori</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 3 : 6}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={accounting} onChange={(e, checked) => {
                            setAccounting(checked);
                        }}/>} label="Contabilizzazione" />
                    </FormGroup>
                </Grid>
            </Grid>

            <div style={{height: "100px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!causalCode || !causalDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(causalCode.length > 5 || causalDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!docType){
                        setMessage('Il tipo di documento è obbligatorio!');
                        return;                        
                    }

                    if(!storageId){
                        setMessage('Indicare deposito!');
                        return;
                    }

                    if(!prefix){
                        setMessage('Indicare prefisso!');
                        return;
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_CausalCreate(JSON.stringify({
                            id: id,
                            code: causalCode,
                            description: causalDesc,
                            doc_enum: docEnum,
                            doc_ade_type: docType,
                            doc_desc: docDesc,
                            charge: charge,
                            discharge: discharge,
                            ordered: ordered,
                            engaged: engaged,
                            prefix: prefix,
                            accounting: accounting ? 1 : 0,
                            propose_for: proposeFor
                        }), storageId);

                        setDisplayBackDrop(false);
                        if(result){
                            setId(null);
                            setCausalCode('');
                            setCausalDesc('');
                            setCharge(0);
                            setDischarge(0);
                            setDocDesc('');
                            setDocEnum('');
                            setDocType('');
                            setEngaged(0);
                            setFiscal('');
                            setOrdered(0);
                            setStorageCode('');
                            setStorageId(null);
                            setAccounting(0);
                            setPrefix('');
                            setProposeFor('empty');
                            comeback();
                        }

                        setMessage(result ? 'Casuale creata con successo' : 'Errore durante la creazione della causale!');

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

            {
                // Validatore tipo documento

                validatorDocType ? 
                    <MbValidatorDocType
                        close={() => {  
                            window.location.hash = window.location.hash.split("#doc_type").join("");
                        }}
                        choosed={(value) => {
                            setDocDesc(value[0]);
                            setDocEnum(value[2]);
                            setDocType(value[1]);
                            window.location.hash = window.location.hash.split("#doc_type").join("");
                        }}
                    />
                :
                    <></>
            }

            {

                // Validatore storage
                validatorStorage ? 
                    <MbValidatorStorage callbackFromValidator={(value) => {
                        window.location.hash = window.location.hash.split("#storage").join("");
                        setStorageId(value[0]);
                        setStorageCode(value[1]);
                    }}/>
                :
                    <></>

            }

        </>
    );

};

export {
    MbAnagraficaNewCausal
}