import { createSlice } from '@reduxjs/toolkit'

export const MbReduxVariants = createSlice({
    name: "variants",
    initialState: {
        value: []
    },
    reducers: { 
        redux_set_variants: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {redux_set_variants} = MbReduxVariants.actions;

export default MbReduxVariants.reducer;