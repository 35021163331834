import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { EnterpriseDto, PrimaryColor } from "../mb_constants";
import { MbDocHead_Dto, MbDocHead_New } from "./mb_doc_model";
import { GetJsonDoc, MbDocGetAmount, MbDocGetAmountTax, MbDocVatCastelletto, MbGetAmountLine } from "./mb_doc_utility";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MbMessage } from "../mb_components/mb_message";
import { MbApi_DocCreate } from "./mb_doc_api";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { Navigate, useNavigate } from "react-router-dom";

const MbDocSummary = ({visible}) => {

    const [firstPaymentObj, setFirstPaymentObj] = useState(null);
    const [secondPaymentObj, setSecondPaymentObj] = useState(null);
    const [docAmount, setDocAmount] = useState(0.00);
    const [docTax, setDocTax] = useState(0.00);
    const [message, setMessage] = useState(null);
    const [firstPayment, setFirstPayment] = useState(null);
    const [secondPayment, setSecondPayment] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);
    const navigate = useNavigate();

    const cleanDoc = () => {
        setDocAmount(0.00);
        setDocTax(0.00);
    };

    const openDoc = (e) => {
        const detail = JSON.parse(e.detail);
        setFirstPaymentObj(detail.payment);
        setSecondPaymentObj(detail.second_payment);
        setFirstPayment(detail.first_payment_paid ?? 0);
        setSecondPayment(detail.second_payment_paid ?? 0);
    };

    useEffect(() => {

        window.addEventListener('clean_doc', cleanDoc);

        window.addEventListener('open_doc', openDoc);

        return (() => {
            try{
                window.removeEventListener('clean_doc', cleanDoc);
            }catch(_){}
            try{
                window.removeEventListener('open_doc', openDoc);
            }catch(_){}
        });

    }, []);

    useEffect(() => {
        if(visible){
            const amount = MbDocGetAmount();
            setDocAmount(amount);
            const amountTax = MbDocGetAmountTax();
            setDocTax(amountTax);
        }
    }, [visible]);


    return (
        <>  

            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} title="Mallbox" close={() => setMessage(null)}/>


            <div style={{flexDirection: "column", gap: "10px", display: "flex"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold"}}>
                        <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px", width: "200px", textAlign: "center"}}>Riepilogo</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        { (docAmount ?? 0).toFixed(2).replace(".", ",") } €
                    </div>
                </div>
                <Divider/>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold"}}>
                        <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px", width: "200px", textAlign: "center"}}>Imposta</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        { (docTax ?? 0).toFixed(2).replace(".", ",") } €
                    </div>    
                </div>
                <Divider/>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold"}}>
                        <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px", width: "200px", textAlign: "center"}}>Totale lordo</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        { ((docTax ?? 0) + (docAmount ?? 0)).toFixed(2).replace(".", ",") } €
                    </div>    
                </div>
                <Divider/>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold"}}>
                        <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px", width: "200px", textAlign: "center"}}>Sconto su totale</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        {
                            MbDocHead_Dto.discount && !isNaN(MbDocHead_Dto.discount) ? 
                                <>
                                    {
                                        parseFloat(MbDocHead_Dto.discount).toFixed(2).replace(".", ",")
                                    } %
                                </>
                            :
                                <>0,00 %</>
                        }
                    </div>    
                </div>
                <Divider/>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold"}}>
                        <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px", width: "200px", textAlign: "center"}}>Totale documento</span>
                    </div>  
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        {
                            MbDocHead_Dto.discount && !isNaN(MbDocHead_Dto.discount) ? 
                                <>
                                    {
                                        (((docTax ?? 0) + (docAmount ?? 0)) - (((docTax ?? 0) + (docAmount ?? 0)) * parseFloat(MbDocHead_Dto.discount)) / 100).toFixed(4).replace(".", ",")  
                                    } €
                                </>
                            :
                                <>
                                    { ((docTax ?? 0) + (docAmount ?? 0)).toFixed(2).replace(".", ",") } €
                                </>
                        }
                    </div>  
               </div>
               {
                    firstPayment ? 
                        <>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold"}}>
                                    <span style={{background: "red", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px", width: "200px", textAlign: "center"}}>{firstPaymentObj ? firstPaymentObj.description : ""}</span>
                                </div>  
                                <div>{firstPayment.toFixed(2).replace(".", ",")} €</div>
                            </div>
                        </>
                    :
                        <></>
               }
                {
                    secondPaymentObj ? 
                        <>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold"}}>
                                    <span style={{background: "red", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px", width: "200px", textAlign: "center"}}>{secondPaymentObj ? secondPaymentObj.description : ""}</span>
                                </div>  
                                <div>{secondPayment.toFixed(2).replace(".", ",")} €</div>
                            </div>
                        </>
                    :
                        <></>
                }
            </div>

            <div style={{height: "100px"}}/>

            <Button variant="contained" className="save-button" onClick={async () => {            

                let msg = "";

                if(!MbDocHead_Dto.causal){
                    msg = "Causale non impostata!";
                }

                if(!MbDocHead_Dto.customer_supplier){
                    msg = "Cliente / Fornitore non impostato";
                }

                if(!MbDocHead_Dto.payment){
                    msg = "Pagamento non impostato";
                }

                if(!MbDocHead_Dto.doc_date){
                    msg = "Impostare una data";
                }

                if(MbDocHead_Dto.discount){
                    if(isNaN(MbDocHead_Dto.discount)){
                        msg = "Percentuale di sconto non valida!";
                    }else{
                        if(parseFloat(MbDocHead_Dto.discount) > 100){
                            msg = "Sconto deve essere minore o uguale a 100!";
                        }
                    }
                }

                // In caso di fattura, effettua controlli aggiuntivi
                if(MbDocHead_Dto.causal.doc_enum == 'invoice'){

                    if(!EnterpriseDto.tax_regime){
                        msg = "Impostare regime fiscale dell'azienda!";
                    }

                    if(!EnterpriseDto.office_address){
                        msg = "Impostare indirizzo sede dell'azienda";
                    }

                    if(!EnterpriseDto.office_zip_code){
                        msg = "Impostare cap sede dell'azienda";
                    }

                    if(!EnterpriseDto.office_collective){
                        msg = "Impostare comune azienda";
                    }

                    if(!EnterpriseDto.office_province || (EnterpriseDto.office_province && EnterpriseDto.office_province.length !== 2)){
                        msg = "Impostare provincia azienda con 2 caratteri";
                    }

                    if(!MbDocHead_Dto.customer_supplier.office_address){
                        msg = "Impostare indirizzo sede dell'cliente/fornitore";
                    }

                    if(!MbDocHead_Dto.customer_supplier.office_zip_code){
                        msg = "Impostare cap sede dell'cliente/fornitore";
                    }

                    if(!MbDocHead_Dto.customer_supplier.office_collective){
                        msg = "Impostare comune sede dell'cliente/fornitore";
                    }

                    if(!MbDocHead_Dto.customer_supplier.office_province || (MbDocHead_Dto.customer_supplier.office_province && MbDocHead_Dto.customer_supplier.office_province.length !== 2)){
                        msg = "Impostare provincia sede con due caratteri del cliente/fornitore";
                    }
                }

                let err = false;
                let p1 = 0.00;
                let p2 = 0.00;
                try{
                
                    p1 = parseFloat(firstPayment.toString().replace(",", "."));

                    if(MbDocHead_Dto.second_payment){
                        p2 = parseFloat(secondPayment.toString().replace(",", "."));
                    }

                    if(isNaN(p1) || (p2 && isNaN(p2))){
                        err = true;
                    }else{
                        //if(p1 + p2 !== docAmount){
                        //    err = true;
                        //}
                    }

                }catch(_){
                    err = true;
                }

                //if(err){
                //    setMessage("Controlla i dati del pagamento!");
                //    return;
                //}

                let lines = MbDocHead_Dto.lines;
                if(lines && lines.length > 0 && !msg){

                    lines = lines.filter(item => item.id);
                    if(lines.length > 0){                        
                        
                        setDisplayBackDrop(true);
                        setTimeout(async () => {
                        
                            const docObj = GetJsonDoc(p1, p2);
                            
                            // Imposta pagamento
                            MbDocHead_Dto.first_payment_paid = p1;
                            MbDocHead_Dto.second_payment_paid = p2;
                            MbDocHead_Dto.transaction_unique_code = docObj.transaction_unique_code;

                            // Serializza user data per una successiva riapertura!
                            const serializeUserData = JSON.stringify(MbDocHead_Dto);
                            docObj.user_data = serializeUserData;

                            const result = await MbApi_DocCreate(docObj);
                            setDisplayBackDrop(false);
                            if(result){
                                setMessage('Documento creato con successo!');
                                MbDocHead_New();
                                dispatchEvent(new CustomEvent('clean_doc'));
                            }else{
                                setMessage('Attenzione, errore durante la creazione del documento!');
                            }

                        }, 1000);

                        return;

                    }else{
                        msg = "Linee dettaglio non presenti!";
                    }

                }else{
                    if(!msg)
                        msg = "Linee dettaglio non presenti!";
                }

                setMessage(msg);

            }} id="btn_save">SALVA</Button>

        </>
    );

};

export {
    MbDocSummary
}