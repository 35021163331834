import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} search_for 
 * @param {*} value 
 * @param {*} id_list_price 
 * @returns 
 */
const MbApi_ProductGetByCodeOrBarcode = async (search_for, value, id_list_price) => {

    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/product/get_products_for_doc?search_for=${search_for}&value=${value}&id_list_price=${id_list_price}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;

};

/**
 * 
 * @param {*} id 
 * @returns 
 */
const MbApi_ProductGetById = async (id) => {

    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/product/get_by_id?id_product=${id}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;

};

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @returns 
 */
const MbApi_ProductList = async (page, search, display_for_composition, display_simple, display_product_not_for_composition, product_type = '', light_list = null, id_product = null) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/product/list?page=${page}&record_for_page=${RecordsForPage}${search ? `&search=${search}` : ''}${display_for_composition ? `&display_for_composition=1` : ''}${display_simple ? `&display_no_related_products=1` : ''}${display_product_not_for_composition ? `&display_product_not_for_composition=1` : ""}${product_type ? `&product_type=${product_type}` : ``}${light_list ? "&light_list=1" : ""}${id_product ? `&id_product=${id_product}` : ""}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @returns 
 */
 const MbApi_ProductListComposition = async () => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/product/get_products_for_composition/`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} id_product 
 * @returns 
 */
const MbApi_ProductGetAvailability = async (id_product) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/product/get_availability?id_product=${id_product}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} id_product 
 * @param {*} year 
 * @returns 
 */
const MbApi_ProductGetMovements = async (id_product, year = localStorage.getItem('exercise')) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/product/get_movements?id_product=${id_product}&year=${year}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} body 
 * @returns 
 */
const MbApi_ProductCreate = async (body) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/product/create`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return res.Json;
            }
        }
    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} id_product
 * @returns 
 */
const MbApi_ProductDelete = async (id_product) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/product/delete?id_product=${id_product}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

/**
 * 
 * @param {*} id_product 
 * @param {*} year 
 * @returns 
 */
const MbApi_GetProductMovements = async (id_product, year = null) => {

    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/product/get_movements?id_product=${id_product}${year ? `&year=${year}` : ""}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;

};

export {
    MbApi_ProductList,
    MbApi_ProductCreate,
    MbApi_ProductDelete,
    MbApi_ProductListComposition,
    MbApi_ProductGetByCodeOrBarcode,
    MbApi_ProductGetAvailability,
    MbApi_GetProductMovements,
    MbApi_ProductGetById,
    MbApi_ProductGetMovements
}