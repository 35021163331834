import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Select, Slider, Switch, TextField, Typography} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { b64toBlob, base64toBlob, Endpoint, getBase64Image, MediaEndpoint, PrimaryColor, RemoveHash, SaveAna } from "../mb_constants";
import { useEffect, useState } from "react";
import { MbConfirm, MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import { MbValidatorVat } from "../mb_validator/mb_validator_vat";
import { MbValidatorUm } from "../mb_validator/mb_validator_um";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MbApi_VariantList } from "../mb_api/mb_api_variant";
import { MbApi_ListPriceList } from "../mb_api/mb_api_list_price";
import { MbApi_ProductCreate, MbApi_ProductGetAvailability, MbApi_ProductListComposition } from "../mb_api/mb_api_product";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbApi_CategoryAll } from "../mb_api/mb_api_category";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { MbApi_UmList } from "../mb_api/mb_um_api";
import { MbApi_TipologyList } from "../mb_api/mb_api_tipology";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSelector } from "react-redux";
import { redux_set_variants } from "../mb_redux/mb_redux_variants";
import { useDispatch } from "react-redux";
import { redux_set_product_composition } from "../mb_redux/mb_redux_composition_product";
import { redux_set_categories } from "../mb_redux/mb_redux_categories";
import CloseIcon from '@mui/icons-material/Close';
import { MbValidatorProductionDepartment } from "../mb_validator/mb_validator_production_department";
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import { useNavigate } from 'react-router-dom';

let productModel = {
    id: null,
    code: null,
    description: null,
    image: null,
    product_type: null,
    combination: null,
    barcode: null,
    um: null,
    vat: null,
    vat_reverse: null,
    prices: null,
    ums: null,
    variants: null,
    production_department: null,
    related_products: null,
    product_category: null,
    products_categories_hierarchy: [],
    product_for_composition: 0,
    short_description: null,
    long_description: null,
    width: 0,
    length: 0,
    depth: 0,
    height: 0,
    weight: 0,
    ultimate_cost: 0.00,
    role_type: 'product',
    position: 0,
    disabled: 0
};

const CombinationObject = ({setCombines}) => {

    const [tipologies, setTipologies] = useState([]);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        
        MbApi_TipologyList(0, null, true).then((response) => {
            if(response.Result == "OK"){
                setTipologies(response.Json.tipologies);
            }
        });

    }, []);

    return (
        <>
            {
                tipologies && tipologies.length > 0 ? 
                    <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {
                            tipologies.map((item, index) => 
                                <TreeItem nodeId={`type_${item.id}`} label={item.description}>
                                    {
                                        item.attributes.map((attr, _index) => 
                                            <TreeItem 
                                                nodeId={`attr${attr.id}`} label={
                                                    <FormGroup>
                                                        <FormControlLabel 
                                                            control={<Checkbox onChange={() => {

                                                                const _checked = [...checked];
                                                                const index = _checked.findIndex(_item => _item.id == attr.id);

                                                                if(index > -1){
                                                                    _checked.splice(index, 1);
                                                                }else{
                                                                    _checked.push({
                                                                        id: attr.id,
                                                                        tipology: item.description,
                                                                        attribute: attr.attribute
                                                                    });
                                                                }
                                                                setChecked(_checked);

                                                            }} checked={checked.filter(_item => _item.id == attr.id).length > 0}/>}
                                                            label={attr.attribute}
                                                        ></FormControlLabel>
                                                    </FormGroup>
                                                }
                                            />    

                                        )
                                    }
                                </TreeItem>
                            )
                        }
                    </TreeView>
                :
                    <></>
            }
            <Button variant="contained" id="btn_new_mbtable" sx={{marginTop: "20px", width: "100%"}} onClick={() => {

                const output = [];

                if(checked.length > 0){
                    
                    checked.forEach((item) => {
                        let founded = null;
                        for(let o = 0; o < output.length; o++){
                            const filtered = output[o].filter(_item => _item.tipology == item.tipology);
                            if(filtered.length > 0){
                                output[o].push(item);
                                founded = true;
                                break;
                            }
                        }
                        if(!founded){
                            output.push([item]);
                        }
                    });

                    const composedOutput = [];
                    output.forEach((item) => {
                        let value = [];
                        item.forEach((_item) => {
                            value.push(_item.tipology.toLowerCase() + ":" + _item.attribute.toLowerCase());
                        })
                        composedOutput.push(value);
                    });
    
                    if(composedOutput.length > 0){
                        setCombines(composedOutput);
                    }

                }

            }}>Genera</Button> 
        </>
    );

};

const TreeCategory = ({fullCategories, idParent, choosed, selected}) => {

    return (
        <>
            {
                fullCategories.filter(item => item.category.id == idParent).map((item, index) => 
                    <TreeItem nodeId={`${item.code}_${idParent}`} label={
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={choosed.includes(item.id)} onChange={() => {
                                selected(item.id);
                            }}/>} label={item.description} labelPlacement="start"></FormControlLabel>
                        </FormGroup>
                    } onClick={(e) => {
                    }}>
                        <TreeCategory fullCategories={fullCategories} idParent={item.id} choosed={choosed} selected={selected}></TreeCategory>
                    </TreeItem>
                )
            }
        </>
    );

};

let indexToRemoveFromProductComposition = -1;
let indexToRemoveCombination = -1;

const MbProductLine = ({modelCopy, openValidator, variants, prices, categories, ums, productsComposition, getReferenceInMemoryOfCombines, index, structUpdate, joinedProducts, productType}) => {
    
    const [render, setRender] = useState(false);
    const [removeImageQuest, setRemoveImageQuest] = useState(false);
    const [removePrdComp, setRemovePrdComp] = useState(false);
    const [removeComb, setRemoveComb] = useState(false);
    const [model, setModel] = useState(modelCopy);
    const [choosedCategories, setChoosedCategories] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [combinationDialog, setCombinationDialog] = useState(false);
    const [combinations, setCombinations] = useState([]);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [productCombinations, setProductCombinations] = useState([]);
    const [isProductForCombinations, setIsProductForCombinations] = useState(false);
    const [disabled, setDisabled] = useState(0);
    const [tempRoleType, setTempRoleType] = useState('product');

    // Utility per mostrare elementi o meno..
    const [displayCoefficient, setDisplayCoefficient] = useState(true);
    const [displayVariants, setDisplayVariants] = useState(true);
    const [displayCombination, setDisplayCombination] = useState(true);
    const [displayIngrediente, setDisplayIngrediente] = useState(true);
    const [displayComposition, setDisplayComposition] = useState(true);
    

    useEffect(() => {
        if(structUpdate) return;
        model.ums = [];
        setRender(!render);
    }, [model.um]);

    useEffect(() => {
        if(productsComposition && productsComposition.length > 0){
            let _filtered = [...productsComposition];
            if(structUpdate){
                _filtered = _filtered.filter(item => item.id !== structUpdate.id);
            }
            setFilteredProducts([..._filtered]);
        }
    }, [productsComposition]);

    useEffect(() => {

        if(prices && prices.length > 0 && (!model.prices || (model.prices && model.prices.length == 0)) ){
            const copy = [];
            prices.forEach((item) => 
                copy.push(Object.assign({}, item))
            );
            model.prices = copy;
        }else{
            if(prices && prices.length > 0 && model.prices && model.prices.length > 0){
                prices.forEach((item) => {
                    const isPresent = model.prices.filter(obj => obj.id == item.id).length > 0;
                    if(!isPresent){
                        model.prices.push({price: null, discount: null, id: item.id, code: item.code, description: item.description});
                    }
                });
            }
        }
        setRender(!render);

    }, [prices]);

    useEffect(() => {

        function eHashChange(){
            setCombinationDialog(window.location.hash.includes('#combination') && !combinationDialog);
        }

        window.addEventListener('hashchange', eHashChange); 

        if(structUpdate){

            model.code = structUpdate.code;
            model.description = structUpdate.description;
            model.barcode = structUpdate.barcode;
            model.image = structUpdate.image;
            model.prouct_type = structUpdate.product_type;
            model.short_description = structUpdate.short_description;
            model.long_description = structUpdate.long_description;
            model.width = structUpdate.width;
            model.height = structUpdate.height;
            model.weight = structUpdate.weight;
            model.depth = structUpdate.depth;
            model.ultimate_cost = structUpdate.ultimate_cost;
            model.role_type = structUpdate.role_type;
            model.position = structUpdate.position ?? 0;
            model.disabled = structUpdate.disabled ?? 0;

            model.um = {
                id: structUpdate.um.id,
                code: structUpdate.um.code
            };
            model.vat = {
                id: structUpdate.vat.id,
                code: structUpdate.vat.code,
                value: structUpdate.vat.value
            };
            
            if(structUpdate.vat_reverse){
                model.vat_reverse = {
                    id: structUpdate.vat_reverse.id,
                    code: structUpdate.vat_reverse.code
                };
            }

            if(structUpdate.production_department){
                model.production_department = {
                    id: structUpdate.production_department.id,
                    code: structUpdate.production_department.code
                };
            }

            model.prices = [];
            structUpdate.prices.forEach((item) => {
                
                // Applica tasse
                let value = item.taxable;
                if(item.with_tax_included == 1){
                    const tax = (value * structUpdate.vat.value) / 100;
                    value += tax;
                }

                if(value){
                    value = parseFloat(value.toFixed(2));
                }

                model.prices.push({
                    price: value,
                    discount: item.discount,
                    id: item.price_list.id,
                    code: item.price_list.code,
                    description: item.price_list.description,
                    with_tax_included: item.with_tax_included
                });

            });

            model.products_categories_hierarchy = [];
            const _choosedCats = [];
            structUpdate.products_categories_hierarchy.forEach((item) => {
                model.products_categories_hierarchy.push({
                    category: {
                        id: item.category.id
                    }
                });
                _choosedCats.push(item.category.id);
            });

            model.variants = [];
            structUpdate.variants.forEach((item) => {
                model.variants.push({
                    id: item.variant.id,
                    code: item.variant.code,
                    description: item.variant.description,
                    override_price: item.override_price
                });
            }); 

            model.ums = [];
            structUpdate.ums.forEach((item) => {
                model.ums.push({
                    barcode: item.barcode,
                    description: item.description,
                    quantity: item.quantity,
                    um: item.um
                });
            });

            model.related_products = [];
            structUpdate.related_products.forEach((item) => {
                model.related_products.push({
                    description: item.child_product.description,
                    quantity: item.quantity,
                    override_price: item.override_price,
                    child_product: {
                        id: item.child_product.id
                    },
                    parent_product: {
                        id: structUpdate.id
                    }
                });
            });

            if(structUpdate.product_for_composition){
                setIsProductForCombinations(structUpdate.product_for_composition == 1 ? true : false);
            }
            setDisabled(structUpdate.disabled);

            setTempRoleType(model.role_type);

            const display = model.role_type == 'product';
            setDisplayCoefficient(display);
            setDisplayCombination(display);
            setDisplayIngrediente(display);
            setDisplayVariants(model.role_type == 'product' || model.role_type == 'menu');
            setDisplayComposition(model.role_type == 'product' || model.role_type == 'menu');

            setTimeout(() => {
                
                setChoosedCategories(_choosedCats);
                setRender(!render);

            }, 500);
                
        }else{
            setTempRoleType(productType ? productType : "product");
            const display = productType == 'product' || !productType;
            model.role_type = productType ?? 'product';
            setDisplayCoefficient(display);
            setDisplayCombination(display);
            setDisplayIngrediente(display);
            setDisplayVariants(model.role_type == 'product' || model.role_type == 'menu');
            setDisplayComposition( productType !== 'department' );
        }

        return (() => {
            window.removeEventListener('hashchange', eHashChange);
        });

    }, []);

    return (
        <>

            <MbBackdrop display={displayBackdrop}/>

            {
                // Dialog combinazioni
            }
            <MbMessage open={combinationDialog} close={() => window.location.hash = window.location.hash.split("#combination").join("")} title={"Combinazioni"} isFullscreen={false}>
                <div style={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "10px", paddingRight: "10px", width: "300px"}}>
                    <CombinationObject setCombines={async (combines) => {

                        window.location.hash = window.location.hash.split("#combination").join("");
                        setDisplayBackdrop(true);

                        setTimeout(async () => {
                            
                            try{
                                const data = JSON.stringify(
                                    {
                                        tipologies: combines
                                    }
                                );

                                const response = await fetch(
                                    `${Endpoint}/api/mb/v1/tipology/generate_combinations`,
                                    {
                                        method: "POST",
                                        headers: {
                                            'Content-Type': "application/json",
                                            'jwt': `Bearer ${localStorage.getItem('jwt')}`
                                        },
                                        body: data
                                    }
                                );

                                setDisplayBackdrop(false);
                                if(response.status == 201){
                                    const res = await response.json();
                                    if(res.Result == "OK"){
                                        const combinations = res.Json;
                                        const prdCombines = [];
                                        combinations.forEach((item) => {
                                            const copy = Object.assign({}, model);
                                            copy.code = "";
                                            copy.image = null;
                                            copy.prices = [];
                                            copy.barcode = null;
                                            model.prices.forEach((item) => copy.prices.push(Object.assign({}, item)));
                                            let composedDesc = "";
                                            if(item.includes('@')){
                                                item.split("@").forEach((desc) => composedDesc += desc.split(":")[1] + "-");
                                            }else{
                                                composedDesc = item.split(":")[1] + "-";
                                            }
                                            copy.description = copy.description + " " + composedDesc.substring(0, composedDesc.length - 1);
                                            copy.combination = item;
                                            prdCombines.push(copy);
                                        });
                                        setProductCombinations(prdCombines);
                                        getReferenceInMemoryOfCombines(prdCombines);
                                    }
                                }

                            }catch(_){
                                setDisplayBackdrop(false);
                            }

                        }, 1000);

                    }}/>
                </div>
            </MbMessage>

            {
                // Richiesta cancellazione combinazione
            }
            <MbConfirm open={removeComb} message="Sicuro di voler rimuovere la combinazione?" confirm={() => {
                let combins = [...productCombinations];
                combins.splice(indexToRemoveCombination, 1);
                setProductCombinations(combins);
                indexToRemoveCombination = -1;
                setRemoveComb(false);
                setRender(!render);
                getReferenceInMemoryOfCombines(combins);
            }} close={() => {
                setRemoveComb(false);
                indexToRemoveCombination = -1;
            }}/>

            {
                // Richiesta cancellazione immagine..
            }
            <MbConfirm open={removeImageQuest} message="Sicuro di voler rimuovere l'immagine?" confirm={() => {
                model.image = null;
                setRemoveImageQuest(false);
                setRender(!render);
            }} close={() => setRemoveImageQuest(false)}/>

            {
                // Richiesta cancellazione chip
            }
            <MbConfirm open={removePrdComp} message="Sicuro di voler rimuovere il prodotto dalla composizione?" confirm={() => {
                model.related_products.splice(indexToRemoveFromProductComposition, 1);
                setRemovePrdComp(false);
                indexToRemoveFromProductComposition = -1;
                setRender(!render);
            }} close={() => {
                setRemovePrdComp(false);
                indexToRemoveFromProductComposition = -1;
            }}/>

            <div style={{width: "100%", padding: window.innerWidth >= 1000 ? "20px" : "0px", border: window.innerWidth >= 1000 ? "1px solid rgb(222, 222, 222)" : "0px", borderRadius: "5px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: window.innerWidth >= 1000 ? "space-between" : "", gap: "15px"}}>
                
                <div style={{width: window.innerWidth >= 1000 ? "200px" : "100%", height: "260px"}}>
                    <div style={{border: "0.1px solid #E7E7E7", width: window.innerWidth >= 1000 ? "200px" : "100%", height: "200px", backgroundImage: `url(${model.image ? model.image : (MediaEndpoint + "/placeholder.png")})`, backgroundSize: "cover"}}></div>
                    <Button variant="contained" style={{width: window.innerWidth >= 1000 ? "200px" : "100%", height: "50px", background: model.image ? "red": PrimaryColor, color: "white", marginTop: "10px"}} onClick={() => {

                        if(model.image){
                            setRemoveImageQuest(true);
                            return;
                        }

                        document.querySelector('#file_chooser_logo_' + index).click();
                    }} id="remove_or_add_image_product">
                        {
                            model.image ? 
                                "RIMUOVI"
                            :
                                "IMMAGINE"
                        }
                    </Button>
                    <input type={"file"} id={`file_chooser_logo_${index}`} style={{visibility: "hidden"}} onClick={(e) => e.target.value = null} onChange={async () => {
                        
                        const file = document.querySelector('#file_chooser_logo_' + index);
                        const encoded = await getBase64Image(file.files[0]);
                        model.image = encoded;
                        setRender(!render);

                    }}/>
                    {
                        !structUpdate || window.innerWidth < 1000 ? 
                            <></>
                        :
                            <>
                                {
                                    joinedProducts && joinedProducts.filter(item => item.id !== structUpdate.id).length > 0 ? 
                                        <div style={{overflowY: "auto", height: "100%"}}>
                                            <span>Combinazioni</span>
                                            {
                                                joinedProducts.filter(item => item.id !== structUpdate.id).map((item, index) => 
                                                    <div style={{padding: "5px", width: "100%"}}>
                                                        <Chip label={item.description} sx={{borderRadius: "0px", width: "100%", cursor: "pointer"}}/>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    :
                                        <></>
                                }
                            </>
                    }
                </div> 
                
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :   
                        <div style={{height: "30px"}}></div>
                }

                <div style={{width: "100%", display: "flex", gap: "15px", flexDirection: "column"}}>
                    
                    {
                        // codice e descrizione
                    }
                    <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                        <TextField size="small" variant="outlined" label={"Codice"} style={{width: "100%"}} disabled={structUpdate} value={model.code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                            model.code = e.target.value;
                            setRender(!render);
                        }}/>
                        {
                            window.innerWidth >= 1000 ? 
                                <></>
                            :
                                <div style={{height: "20px"}}/>
                        }
                        <TextField size="small" variant="outlined" label={"Descrizione"} style={{width: "100%"}} value={model.description} InputLabelProps={{ shrink: true }} onChange={(e) => {
                            model.description = e.target.value;
                            setRender(!render);
                        }}/>
                    </div>

                    {
                        // barcode
                    }
                    <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                        <TextField size="small" variant="outlined" label={"Codice a barre (opzionale)"} style={{width: "100%"}} value={model.barcode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                            model.barcode = e.target.value;
                            setRender(!render);
                        }}/>
                    </div>

                    {
                        // unità di misura e aliquota
                    }
                    <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                        <TextField size="small" variant="outlined" label={"Aliquota"} style={{width: "100%"}} value={model.vat ? model.vat.code : null} InputLabelProps={{ shrink: true }} disabled InputProps={{
                            endAdornment: <SearchIcon/>
                        }} onClick={() => {
                            openValidator('vat', model, render, setRender);
                        }}/>
                        {
                            window.innerWidth >= 1000 ? 
                                <></>
                            :
                                <div style={{height: "20px"}}/>
                        }
                        <TextField size="small" variant="outlined" label={"Unità di misura"} style={{width: "100%"}} value={model.um ? model.um.code : null} InputLabelProps={{ shrink: true }} disabled InputProps={{
                            endAdornment: <SearchIcon/>
                        }} onClick={() => {
                            openValidator('um', model, render, setRender);
                        }}/>
                    </div>
                    
                    {
                        // Aliquota reverse e reparto di produzione
                    }
                    {/*<div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                        
                        <TextField size="small" variant="outlined" label={"Aliquota reverse charge"} style={{width: "100%"}} value={model.vat_reverse ? model.vat_reverse.code : ''} InputLabelProps={{ shrink: true }} disabled InputProps={{
                            endAdornment: model.vat_reverse ? <CloseIcon onClick={() => {
                                if(model.vat_reverse){
                                    model.vat_reverse = null;
                                    setRender(!render);
                                    return;
                                }
                            }}/> : <SearchIcon/>
                        }} onClick={() => {
                            openValidator('vat_reverse', model, render, setRender);
                        }}/>

                        {
                            window.innerWidth >= 1000 ? 
                                <></>
                            :
                                <div style={{height: "20px"}}/>
                        }

                        <TextField size="small" variant="outlined" label={"Reparto di produzione"} style={{width: "100%"}} value={model.production_department ? model.production_department.code : ''} InputLabelProps={{ shrink: true }} disabled InputProps={{
                            endAdornment: model.production_department ? <CloseIcon onClick={() => {
                                if(model.production_department){
                                    model.production_department = null;
                                    setRender(!render);
                                    return;
                                }
                            }}/> : <SearchIcon/>
                        }} onClick={() => {
                            openValidator('department_production', model, render, setRender);
                        }}/>

                    </div>*/}

                    {
                        // Costo ultimo e ruolo
                    }
                    <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>

                        <TextField size="small" variant="outlined" disabled label={"Costo ultimo"} style={{width: "100%"}} value={model.ultimate_cost} InputLabelProps={{ shrink: true }} onChange={(e) => {
                            model.ultimate_cost = e.target.value;
                            setRender(!render);
                        }}></TextField>

                        {
                            window.innerWidth >= 1000 ? 
                                <></>
                            :
                                <div style={{height: "20px"}}/>
                        }

                        <FormControl fullWidth size="small" disabled={structUpdate}>            
                            <InputLabel id="demo-simple-select-label" shrink >Ruolo</InputLabel>
                            <Select
                                disabled={productType == "menu" || productType == 'department'}
                                notched 
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tempRoleType}
                                label="Ruolo"
                                onChange={(e) => {
                                    
                                    model.role_type = e.target.value;
                                    setTempRoleType(e.target.value);
                                    setRender(!render);

                                    const display = model.role_type == 'product';
                                    setDisplayCoefficient(display);
                                    setDisplayCombination(display);
                                    setDisplayIngrediente(display);
                                    setDisplayVariants(model.role_type == 'product' || model.role_type == 'menu');
                                    setDisplayComposition(model.role_type == 'product' || model.role_type == 'menu');

                                }}
                            >
                                <MenuItem value="product">Prodotto</MenuItem>
                                <MenuItem value="department">Reparto</MenuItem>
                                <MenuItem value="menu">Menù</MenuItem>
                            </Select>
                        </FormControl>

                    </div>

                    {/*<TextField variant="outlined" size="small" label="Posizione" value={model.position ?? ""} InputLabelProps={{shrink: true}} onChange={(e) => {
                        model.position = e.target.value;
                        setRender(!render);
                    }}/>*/}

                    <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE", display: "none"}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Anagrafica</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {
                                // Descrizione breve
                            }
                            <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                                <TextField size="small" variant="outlined" label={"Descrizione breve"} style={{width: "100%"}} value={model.short_description} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    model.short_description = e.target.value;
                                    setRender(!render);
                                }} multiline maxRows={4} rows={4}/>
                            </div>

                            <div style={{height: "20px"}}/>

                            {
                                // Descrizione lunga
                            }
                            <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                                <TextField size="small" variant="outlined" label={"Descrizione lunga"} style={{width: "100%"}} value={model.long_description} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    model.long_description = e.target.value;
                                    setRender(!render);
                                }} multiline maxRows={4} rows={4}/>
                            </div>

                            <div style={{height: "20px"}}/>

                            {
                                // Altezza peso
                            }
                            <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                                <TextField size="small" variant="outlined" label={"Altezza"} style={{width: "100%"}} value={model.height} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    model.height = e.target.value;
                                    setRender(!render);
                                }}/>
                                {
                                    window.innerWidth >= 1000 ? 
                                        <></>
                                    :
                                        <div style={{height: "20px"}}/>
                                }
                                <TextField size="small" variant="outlined" label={"Peso"} style={{width: "100%"}} value={model.weight} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    model.weight = e.target.value;
                                    setRender(!render);
                                }}/>
                            </div>

                            <div style={{height: "20px"}}/>

                            {
                                // Larghezza profondità
                            }
                            <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", gap: window.innerWidth >= 1000 ? "15px" : ""}}>
                                <TextField size="small" variant="outlined" label={"Larghezza"} style={{width: "100%"}} value={model.width} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    model.width = e.target.value;
                                    setRender(!render);
                                }}/>
                                {
                                    window.innerWidth >= 1000 ? 
                                        <></>
                                    :
                                        <div style={{height: "20px"}}/>
                                }
                                <TextField size="small" variant="outlined" label={"Profondità"} style={{width: "100%"}} value={model.depth} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    model.depth = e.target.value;
                                    setRender(!render);
                                }}/>
                            </div>

                        </AccordionDetails>
                    </Accordion>

                    {
                        // Prezzi
                    }
                    <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE"}} expanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Prezzi</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                {
                                    model.prices && model.prices.length > 0 ?
                                        model.prices.map((item, index) => 
                                            <>  
                                                <Typography variant="caption" sx={{color: PrimaryColor, fontWeight: "bold"}}>{item.description}</Typography>
                                                <div style={{display: window.innerWidth >= 1000 ? "flex" : "", width: "100%", justifyContent: "space-between", gap: "12px"}}>
                                                    
                                                    <TextField size="small" variant="outlined" label={"Prezzo"} style={{width: "100%"}} value={item.price} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                                        item.price = e.target.value;
                                                        setRender(!render);
                                                    }}/>
                                                    {
                                                        window.innerWidth >= 1000 ? 
                                                            <></>
                                                        :
                                                            <div style={{height: "20px"}}/>
                                                    }
                                                    <div style={{width: "200px"}}>
                                                        <FormControlLabel label="Prezzo ivato" control={
                                                            <Switch checked={item.with_tax_included == 1 ? true : false} onChange={(e, checked) => {
                                                                item.with_tax_included = checked ? 1 : 0;
                                                                setRender(!render);
                                                            }} defaultChecked={item.with_tax_included == 1 ? true : false}/>
                                                        }></FormControlLabel>
                                                    </div>
                                                    {/*
                                                    <TextField size="small" variant="outlined" label={"Sconto"} style={{width: "100%"}} value={item.discount} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                                        item.discount = e.target.value;
                                                        setRender(!render);
                                                    }}/>
                                                    */}

                                                </div>
                                                <div style={{marginTop: "5px"}}/>
                                            </>
                                        )
                                    :
                                        <></>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    {
                        // Coefficienti
                    }
                    <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE", display: displayCoefficient ? "" : "none"}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Coefficienti di conversione</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                {
                                    model.um ? 
                                        ums && ums.length > 0 ? 
                                            ums.filter(item => item.id !== model.um.id).map((item, index) => {

                                                let filtered = [];
                                                if(model.ums && model.ums.length > 0){
                                                    filtered = model.ums.filter(_item => _item.um && _item.um.id == item.id);
                                                }
                                                let checked = filtered.length > 0;

                                                return (
                                                    <div style={{display: window.innerWidth >= 1000 ? "flex" : "", width: "100%", justifyContent: "space-between"}}>
                                                        <div style={{width: "100px"}}>
                                                            <FormGroup>
                                                                <FormControlLabel control={
                                                                    <Checkbox checked={checked}/>
                                                                } label={item.description} onChange={(e, checked) => {
                                                                    
                                                                    if(!model.ums){
                                                                        model.ums = [];
                                                                    }

                                                                    const index = model.ums.findIndex(_item => _item.um.id == item.id)
                                                                    if(index > -1){
                                                                        model.ums.splice(index, 1);
                                                                    }else{
                                                                        model.ums.push({
                                                                            um: {id: item.id}
                                                                        });
                                                                    }

                                                                    setRender(!render);

                                                                }}></FormControlLabel>  
                                                            </FormGroup>
                                                        </div>
                                                        <TextField size="small" variant="outlined" value={filtered && filtered.length > 0 ? filtered[0].description : ""} disabled={!checked} placeholder="Descrizione" onChange={(e) => {
                                                            filtered[0].description = e.target.value;
                                                            setRender(!render);
                                                        }} sx={{width: window.innerWidth >= 1000 ? "250px" : "100%"}}/>
                                                        {
                                                            window.innerWidth >= 1000 ? 
                                                                <></>
                                                            :
                                                                <div style={{height: "20px"}}/>
                                                        }
                                                        <TextField size="small" variant="outlined" value={filtered && filtered.length > 0 ? filtered[0].quantity : ""} disabled={!checked} placeholder="Quantità" onChange={(e) => {
                                                            filtered[0].quantity = e.target.value;
                                                            setRender(!render);
                                                        }} sx={{width: window.innerWidth >= 1000 ? "250px" : "100%"}}/>
                                                        {
                                                            window.innerWidth >= 1000 ? 
                                                                <></>
                                                            :
                                                                <div style={{height: "20px"}}/>
                                                        }
                                                        <TextField size="small" variant="outlined" value={filtered && filtered.length > 0 ? filtered[0].barcode : ""} disabled={!checked} placeholder="Barcode" onChange={(e) => {
                                                            filtered[0].barcode = e.target.value;
                                                            setRender(!render);
                                                        }} sx={{width: window.innerWidth >= 1000 ? "250px" : "100%"}}/>

                                                    </div>
                                                );
                                            })
                                        :
                                            <></>
                                    :
                                        <></>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    {
                        // Varianti
                    }
                    <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE", display: displayVariants ? "" : "none"}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Varianti</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display: window.innerWidth >= 1000 ? "flex" : "", flexWrap: "wrap", gap: "15px"}}>
                                {
                                    variants && variants.length > 0 ? 
                                        variants.map((item, index) => {
                                            
                                            let filtered = [];
                                            if(model.variants){
                                                filtered = model.variants.filter(v => v.id == item.id);
                                            }

                                            return (
                                                <>
                                                    <div style={{width: window.innerWidth >= 1000 ? "20%" : "", display: "flex", flexDirection: "column"}}>
                                                        <FormGroup>
                                                            <FormControlLabel control={
                                                                <Checkbox checked={model.variants ? model.variants.filter(v => v.id == item.id).length > 0 : false}/>
                                                            } label={item.description} onChange={(e, checked) => {
                                                                
                                                                if(!model.variants){
                                                                    model.variants = [];
                                                                }

                                                                if(checked){
                                                                    model.variants.push(Object.assign({}, item));
                                                                }else{
                                                                    const index = model.variants.findIndex(v => v.id == item.id);
                                                                    model.variants.splice(index, 1);
                                                                }

                                                                setRender(!render);
                                                                
                                                            }}/>
                                                            <TextField size="small" variant="outlined" label={"Sovrapprezzo"} onChange={(e) => {
                                                                if(filtered.length > 0){
                                                                    filtered[0].override_price = parseFloat(e.target.value);
                                                                }
                                                            }} style={{width: window.innerWidth >= 1000 ? "" : "100%"}} 
                                                                disabled={filtered.length == 0} value={filtered.length > 0 ? filtered[0].override_price : ""}
                                                                InputLabelProps={{shrink: true}}
                                                            ></TextField>
                                                        </FormGroup>
                                                        {
                                                            window.innerWidth >= 1000 ? 
                                                                <></>
                                                            :
                                                                <div style={{height: "20px"}}/>
                                                        }
                                                    </div>
                                                </>
                                            );
                                        })
                                    :
                                        <></>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    { 
                        // Prodotto composto
                    }   
                    <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE", display: displayComposition ? "" : "none"}} disabled={isProductForCombinations}> 
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Composizione</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                <TextField size="small" variant="outlined" onChange={(e) => {
                                    if(productsComposition && productsComposition.length > 0){
                                        
                                        const filtered = (
                                            productsComposition.filter(item => 
                                                item.description.toLowerCase().includes(e.target.value.toLowerCase())
                                            )
                                        );

                                        setFilteredProducts(filtered);

                                    }
                                }} placeholder="Ricerca.."></TextField>
                                {
                                    model.related_products && model.related_products.length > 0 ?
                                        <div style={{display: "flex", gap: "10px"}}>
                                            {
                                                model.related_products.map((item, index) => 
                                                    {
                                                        return (
                                                            <Chip label={item.quantity + "x" + " " + item.description} onDelete={() => {
                                                                
                                                                    const index = model.related_products.findIndex(_item => _item.child_product.id == item.child_product.id);
                                                                    if(index > -1){
                                                                        indexToRemoveFromProductComposition = index;
                                                                        setRemovePrdComp(true);
                                                                    }
                                                                    //if(index > -1)
                                                                    //    model.related_products.splice(index, 1);
                                                                    //setRender(!render);

                                                            }} size="small" variant="outlined" style={{maxWidth: "200px", minWidth: "100px"}}/>
                                                        );
                                                    }
                                                )
                                            }
                                        </div>
                                    :
                                        <></>
                                }
                                <div style={{display: "flex", flexDirection: "column", gap: "15px", marginTop: "20px"}}>
                                    {
                                        filteredProducts && filteredProducts.length > 0 ? 
                                            filteredProducts.map((item, index) => {
                                                
                                                let display = true;
                                                if(model.related_products && model.related_products.length > 0){
                                                    display = model.related_products.filter(_item => _item.child_product.id == item.id).length == 0;
                                                }

                                                return (
                                                    !display ? 
                                                        <></>
                                                    :
                                                        <div style={{display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between"}}>
                                                            <div style={{width: "200px", textAlign: "left"}}>{item.description}</div>
                                                            <div>
                                                                <TextField variant="outlined" size="small" placeholder="Quantità" id={`field_${item.id}`} onChange={(e) => {
                                                                    const field = document.querySelector(`#field_${item.id}`);
                                                                    field.value = e.target.value;
                                                                }} onKeyDown={(e) => {

                                                                }}  sx={{width: window.innerWidth >= 1000 ? "250px" : "100%"}}/>
                                                            </div>
                                                            {/*<div>
                                                                <TextField variant="outlined" size="small" placeholder="Prezzo" id={`field_price_${item.id}`} onChange={(e) => {
                                                                    const field = document.querySelector(`#field_price_${item.id}`);
                                                                    field.value = e.target.value;
                                                                }} onKeyDown={(e) => {

                                                                }}  sx={{width: window.innerWidth >= 1000 ? "250px" : "100%"}}/>
                                                            </div>*/}
                                                            {
                                                                window.innerWidth >= 1000 ? 
                                                                    <></>
                                                                :
                                                                    <div style={{height: "10px"}}/>
                                                            }
                                                            <Button focusRipple={false} variant="contained" id="btn_new_mbtable" className={`btn_add_rel_${item.id}`} onClick={() => {
                                                                
                                                                if(!model.related_products){
                                                                    model.related_products = [];
                                                                }

                                                                const field = document.querySelector(`#field_${item.id}`);
                                                                let value = null;
                                                                if(field){
                                                                    value = field.value;
                                                                }

                                                                const field_price = document.querySelector(`#field_price_${item.id}`);
                                                                let value_price = null;
                                                                if(field_price){
                                                                    value_price = field_price.value;
                                                                }
                                                                
                                                                if(value){

                                                                    model.related_products.push({
                                                                        description: item.description,
                                                                        quantity: value,
                                                                        override_price: 0.00,
                                                                        child_product: {
                                                                            id: item.id
                                                                        },
                                                                        parent_product: {
                                                                            id: null
                                                                        }  
                                                                    });
                                                                    setRender(!render);

                                                                }

                                                            }} sx={{width: "200px"}}>AGGIUNGI</Button>
                                                        </div>
                                                );
                                            })
                                        :
                                            <></>
                                    }
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    {
                        // Categorie
                    }
                    <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE"}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Categorie</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                {
                                    categories && categories.length > 0 ? 
                                        <TreeCategory fullCategories={categories} idParent={null} choosed={choosedCategories} selected={(id) => {
                                            const temp = [...choosedCategories];
                                            if(!temp.includes(id)){
                                                temp.push(id);
                                            }else{
                                                const index = temp.findIndex(item => item == id);
                                                temp.splice(index, 1);
                                            }
                                            
                                            model.products_categories_hierarchy = [];
                                            temp.forEach((item) => model.products_categories_hierarchy.push(
                                                {
                                                    category: {
                                                        id: item
                                                    }
                                                }
                                            ));

                                            setChoosedCategories(temp);
                                        }}/>
                                    :
                                        <></>
                                }
                            </TreeView>
                        </AccordionDetails>
                    </Accordion>

                    {
                        // Combinazioni
                    }
                    {
                        //displayCombination ? "" : "none"
                    }
                    {
                        !structUpdate? 
                            <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE", display: !displayCombination ? "none" : ""}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>Combinazioni</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                        {
                                            productCombinations && productCombinations.length > 0 ? 
                                                productCombinations.map((item, index) => 
                                                    <>
                                                        <div style={{display: "flex", gap: "10px"}}>
                                                            <DeleteOutlineIcon sx={{color: "red", cursor: "pointer"}} onClick={() => {
                                                                indexToRemoveCombination = index;
                                                                setRemoveComb(true);
                                                            }}/>
                                                            {
                                                                item.combination.split("@").map((_item, _index) => 
                                                                    <Chip label={_item.split(":")[1]} variant="outlined"></Chip>
                                                                )
                                                            }
                                                        </div>

                                                        <div style={{height: "10px"}}></div>

                                                        <div style={{display: window.innerWidth >= 1000 ? "flex" : "", width: "100%", justifyContent: "space-between", gap: "12px"}}>
                                                            <TextField size="small" variant="outlined" label={"Codice"} style={{width: "100%"}} value={item.code} onChange={(e) => {
                                                                item.code = e.target.value;
                                                                setRender(!render);
                                                            }}></TextField>
                                                            {
                                                                window.innerWidth >= 1000 ? 
                                                                    <></>
                                                                :
                                                                    <div style={{height: "20px"}}/>
                                                            }
                                                            <TextField size="small" variant="outlined" label={"Descrizione"} style={{width: "100%"}} value={item.description} onChange={(e) => {
                                                                item.description = e.target.value;
                                                                setRender(!render);
                                                            }}></TextField>
                                                        </div>

                                                        <div style={{height: "10px"}}></div>

                                                        <div style={{display: window.innerWidth >= 1000 ? "flex" : "", width: "100%", justifyContent: "space-between", gap: "12px"}}>
                                                            <TextField size="small" variant="outlined" label={"Barcode"} style={{width: "100%"}} value={item.barcode} onChange={(e) => {
                                                                item.barcode = e.target.value;
                                                                setRender(!render);
                                                            }}></TextField>
                                                        </div>

                                                        <div style={{height: "10px"}}></div>
                                                        
                                                        {
                                                            item.prices.map((item, index) => 
                                                                {
                                                                    return (
                                                                        <>
                                                                            <Typography variant="caption" sx={{color: PrimaryColor, fontWeight: "bold"}}>{item.description}</Typography>

                                                                            <div style={{display: window.innerWidth >= 1000 ? "flex" : "", width: "100%", justifyContent: "space-between", gap: "12px"}}>
                                                                                
                                                                                <TextField size="small" variant="outlined" label={"Imponibile"} style={{width: "100%"}} value={item.price ? item.price : ""} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                                                                    item.price = e.target.value;
                                                                                    setRender(!render);
                                                                                }}/>
                                                                                {
                                                                                    window.innerWidth >= 1000 ? 
                                                                                        <></>
                                                                                    :
                                                                                        <div style={{height: "20px"}}></div>
                                                                                }
                                                                                <div style={{width: "200px"}}>
                                                                                    <FormControlLabel label="Prezzo ivato" control={
                                                                                        <Switch checked={item.with_tax_included == 1 ? true : false} onChange={(e, checked) => {
                                                                                            item.with_tax_included = checked ? 1 : 0;
                                                                                            setRender(!render);
                                                                                        }} defaultChecked={item.with_tax_included == 1 ? true : false}/>
                                                                                    }></FormControlLabel>
                                                                                </div>
                                                                                {/*<TextField size="small" variant="outlined" label={"Sconto"} style={{width: "100%"}} value={item.discount ? item.discount : ""} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                                                                    item.discount = e.target.value;
                                                                                    setRender(!render);
                                                                                }}/>*/}
                            
                                                                            </div>
                                                                            <div style={{marginTop: "5px"}}/>
                                                                        </>
                                                                    );
                                                                }
                                                            )
                                                        }
                                                        
                                                        <div style={{height: "10px"}}></div>
                                                        <Divider/>
                                                        <div style={{height: "10px"}}></div>
                                                    </>
                                                )
                                            :
                                                <></>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        :
                            <></>
                    }

                    {
                        // Ingrediente / Prodotto per menù
                    }
                    <FormGroup style={{display: displayIngrediente ? "" : "none"}}>
                        <FormControlLabel control={<Checkbox checked={isProductForCombinations} onChange={(e, checked) => {
                            setIsProductForCombinations(checked);
                            model.product_for_composition = checked ? 1 : 0;
                        }}></Checkbox>} label="Ingrediente / Prodotto per menù"/>
                    </FormGroup>

                    {
                        // Disabilitato
                    }
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={disabled} onChange={(e, checked) => {
                            setDisabled(checked ? 1 : 0);
                            model.disabled = checked ? 1 : 0;
                        }}></Checkbox>} label="Disabilitato"/>
                    </FormGroup>

                    {
                        // Marketplace
                    }
                    {/*<FormGroup style={{display: displayIngrediente ? "" : "none"}}>
                        <FormControlLabel control={<Checkbox checked={false} onChange={(e, checked) => {
                            //setIsProductForCombinations(checked);
                            //model.product_for_composition = checked ? 1 : 0;
                        }}></Checkbox>} label="Mostra nei marketplace"/>
                    </FormGroup>*/}

                </div>

            </div>
        </>
    );

};


// Validatori linea prodotto
let _model                      = null;
let _render                     = null;
let _setRender                  = null;

const MbAnagraficaNewProduct = ({structure, comeback, productType}) => {

    const [lines, setLines] = useState([ Object.assign({}, productModel) ]);
    
    // Validatori
    const [validatorVat, setValidatorVat] = useState(false);
    const [validatorUm, setValidatorUm] = useState(false);
    const [validatorVatReverse, setValidatorVatReverse] = useState(false);
    const [validatorDepartmentProduction, setValidatorDepartmentProduction] = useState(false);

    const [variants, setVariants] = useState([]);
    const [prices, setPrices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [ums, setUms] = useState([]);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [message, setMessage] = useState(null);
    const [productsComposition, setProductsComposition] = useState([]);
    const [combineReferenceInMemory, setCombineReferenceInMemory] = useState(null);
    const [joinedProducts, setJoinedProducts] = useState([]);

    // Redux
    const dispatch                      = useDispatch();
    const reduxVariants                 = useSelector((state) => state.variants.value);
    const reduxProductsForComposition   = useSelector((state) => state.product_composition.value);
    const reduxCategories               = [];//useSelector((state) => state.categories.value);

    const navigate = useNavigate();

    const eKeyDown = (e) => {
        
        if(window.location.hash.includes('#vat') || window.location.hash.includes('#um') || window.location.hash.includes('#vat_reverse') || window.location.hash.includes('#department_production')) return;
        
        const btnAddOrRemoveImage = document.querySelector('#remove_or_add_image_product');
        if(document.activeElement == btnAddOrRemoveImage){
            return;
        }

        SaveAna(e);
    }

    function eHashChange(){
        setValidatorVat(window.location.hash.includes('#vat') && !validatorVat);
        setValidatorUm(window.location.hash.includes('#um') && !validatorUm);
        setValidatorVatReverse(window.location.hash.includes('#vat_reverse') && !validatorVatReverse);
        setValidatorDepartmentProduction(window.location.hash.includes('#department_production') && !validatorDepartmentProduction);
    }

    useEffect(() => {

        window.addEventListener('hashchange', eHashChange); 
        window.addEventListener('keydown', eKeyDown);

        // Ottieni tutte le varianti
        if(reduxVariants.length == 0){
            MbApi_VariantList(0, null, true).then((response) => {
                if(response.Result == "OK"){
                    setVariants(response.Json.variants);
                    dispatch(redux_set_variants(response.Json.variants));
                }
            });
        }else{
            setVariants(reduxVariants);
        }

        // Ottieni tutti i listini
        MbApi_ListPriceList(0, null, true).then((response) => {
            if(response.Result == "OK"){
                setPrices(response.Json.list_prices);
            }
        });

        // Ottieni tutte le unità di misura
        MbApi_UmList(0, null, true).then((response) => {
            if(response.Result == "OK"){
                setUms(response.Json.ums);
            }
        });

        // Ottieni tutte le categorie
        if(reduxCategories.length == 0){
            MbApi_CategoryAll().then((response) => {
                if(response.Result == "OK"){
                    setCategories(response.Json.categories);
                    dispatch(redux_set_categories(response.Json.categories));
                }
            });
        }else{
            setCategories(reduxCategories);
        }

        // Ottieni prodotti utili alla composizione
        if(reduxProductsForComposition.length == 0){
            MbApi_ProductListComposition().then((prds) => {
                if(prds && prds.length > 0){
                    setProductsComposition(prds);
                    dispatch(redux_set_product_composition(prds));
                }
            });
        }else{
            setProductsComposition(reduxProductsForComposition);
        }

        if(structure){
            if(structure.product_category && structure.product_category.reference){
                
                fetch(
                    `${Endpoint}/api/mb/v1/product/list_combinations?reference_category=${structure.product_category.reference}`,
                    {
                        method: "GET",
                        headers: {
                            'jwt': `Bearer ${localStorage.getItem('jwt')}`
                        }
                    }
                ).then(async (response) => {
                    if(response){
                        if(response.status == 200){
                            const res = await response.json();
                            if(res.Result == "OK"){
                                setJoinedProducts(res.Json);
                            }
                        }
                    }
                });

            }
        }

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
        });

    }, []);

    useEffect(() => {
        if(!lines){
            setLines([ Object.assign({}, productModel) ]);
        }
    }, [lines]);

    return (
        <>  
            
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }}/>

            <div style={{display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: window.innerWidth >= 1000 ? "space-between" : ""}}>
                <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} prodotto</Typography>
                {
                    !structure ? 
                        <div style={{marginTop: window.innerWidth >= 1000 ? "" : "10px"}}>
                            <Button variant="contained" id="btn_new_mbtable" onClick={() => {
                                if(lines[0].code && lines[0].description){
                                    window.location.hash += "#combination";
                                }else{
                                    setMessage('Per creare combinazioni, indicare codice e descrizione');
                                }
                            }}>GENERA COMBINAZIONI</Button>
                        </div>
                    :
                        <div style={{marginTop: window.innerWidth >= 1000 ? "" : "10px"}}>
                            <Button variant="contained" id="btn_new_mbtable" onClick={() => {
                                navigate(`/product_stats/${structure.id}`);
                            }}><TimelineRoundedIcon/>&nbsp;&nbsp;STATISTICHE</Button>
                        </div>
                }
            </div>
            <div style={{height: "20px"}}/>

            <div style={{gap: "30px", display: "flex", flexDirection: "column"}}>
                {
                    lines && lines.length > 0 ? 
                        lines.map((item, index) => 
                            <MbProductLine productType={productType} modelCopy={item} openValidator={(type, model, render, setRender) => {
                                
                                _model      = model;
                                _render     = render;
                                _setRender  = setRender;

                                window.location.hash += "#" + type;

                            }} 
                                variants={[...variants]} 
                                prices={[...prices]} 
                                index={index} 
                                key={`line_${index}`} 
                                categories={[...categories]} 
                                ums={[...ums]} 
                                productsComposition={[...productsComposition]}
                                getReferenceInMemoryOfCombines={(combines) => {
                                    setCombineReferenceInMemory(combines);
                                }}
                                joinedProducts={joinedProducts}
                                structUpdate={structure}
                            />
                        )
                    :
                        <></>
                }
            </div>

            {
                
                // Validatore aliquota
                validatorVat ? 
                    <MbValidatorVat
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#vat").join("");
                            const obj = value[value.length - 1];
                            _model.vat = {
                                id: obj.id,
                                code: obj.code,
                                value: obj.value
                            };
                            _setRender(!_render);
                        }}
                    />
                :
                    <></>

            }

            {
                
                // Validatore aliquota reverse
                validatorVatReverse ? 
                    <MbValidatorVat
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#vat_reverse").join("");
                            const obj = value[value.length - 1];
                            _model.vat_reverse = {
                                id: obj.id,
                                code: obj.code,
                                value: obj.value
                            };
                            _setRender(!_render);
                        }}
                    />
                :
                    <></>

            }

            {
                
                // Validatore unità di misura
                validatorUm ? 
                    <MbValidatorUm
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#um").join("");
                            _model.um = {
                                id: value[0],
                                code: value[1],
                                description: value[2]
                            };
                            _setRender(!_render);
                        }}
                    />
                :
                    <></>

            }

            {
                
                // Validatore reparto di produzione
                validatorDepartmentProduction ? 
                    <MbValidatorProductionDepartment
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#department_production").join("");
                            _model.production_department = {
                                id: value[0],
                                code: value[1]
                            };
                        }}
                    ></MbValidatorProductionDepartment>
                :
                    <></>

            }

            <div style={{height: "100px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {
                    
                    setDisplayBackdrop(true);

                    const formattedLines = [];
                    let eMsg = [];

                    let barcodes = [];
                    let index = 1;
                    let duplicatedBarcodes = 0;
                    lines.forEach((item) => {

                        if(!item.position && item.position !== 0){
                            item.position = 0;
                        }else{
                            if(isNaN(item.position)){
                                item.position = 0;
                            }else{
                                item.position = parseInt(item.position);
                            }
                        }

                        let product_type = (!item.related_products || (item.related_products.length == 0)) ? "simple" : "complex";
                        let prices = [];
                        let variants = [];
                        const ums = [];

                        if(item.prices){
                            item.prices.forEach((prc) => {
                                
                                if(prc.discount){
                                    if(isNaN(prc.discount)){
                                        prc.discount = null;
                                    } else{
                                        prc.discount = parseFloat(prc.discount);
                                    }
                                }

                                if(prc.price){
                                    if(isNaN(prc.price)){
                                        prc.price = null;
                                    }else{
                                        prc.price = parseFloat(prc.price);
                                    }
                                }

                                if(prc.price !== 0 && prc.price == ''){
                                    prc.price = null;
                                }

                                if(prc.discount !== 0 && prc.discount == ''){
                                    prc.discount = null;
                                }

                                if(prc.price !== 0 && prc.price == undefined){
                                    prc.price = null;
                                }

                                if(prc.discount !== 0 && prc.discount == undefined){
                                    prc.discount = null;
                                }

                                // Se il prezzo è ivato, ottieni nuovo imponibile
                                let taxable = prc.price;
                                if(prc.with_tax_included == 1){
                                    let tax = (taxable * item.vat.value) / (100 + item.vat.value);
                                    taxable = taxable - tax;
                                }

                                prices.push(
                                    {
                                        id: null,
                                        taxable: taxable,
                                        discount: prc.discount,
                                        with_tax_included: prc.with_tax_included ?? 0,
                                        price_list: {
                                            id: prc.id
                                        }
                                    }
                                )
                            });
                        }

                        if(item.variants){
                            item.variants.forEach((vart) => 
                                variants.push({
                                    id: null,
                                    override_price: vart.override_price,
                                    variant: {
                                        id: vart.id
                                    }
                                })
                            )
                        }

                        if(item.ums && item.ums.length > 0){
                            item.ums.forEach((um) => {
                                
                                if(!um.quantity){
                                    eMsg.push(`Indicare quantità nei coefficienti di conversione`);
                                }else{
                                    if(isNaN(um.quantity)){
                                        eMsg.push(`La quantità dei coefficienti di conversione deve essere un numero`);
                                    }else{
                                        um.quantity = parseFloat(um.quantity);
                                    }
                                }

                                if(!um.description){
                                    um.description = item.description;
                                }

                                ums.push(um);

                            });
                        }

                        if(!item.code){
                            eMsg.push(`Il codice deve essere compilato`);
                        }

                        if(!item.description){
                            eMsg.push(`Compilare la descrizione`);
                        }

                        if(!item.um || !item.vat){
                            eMsg.push(`Compilare unità di misura primaria e aliquota!`);
                        }

                        if(!item.products_categories_hierarchy || item.products_categories_hierarchy.length == 0 && (item.role_type == 'product' || item.role_type == '' || item.role_type == 'menu')){
                            eMsg.push(`Compilare almeno una categoria`);
                        }

                        item.short_description = item.short_description == '' ? null : item.short_description;
                        item.long_description = item.long_description == '' ? null : item.long_description;

                        if(!item.width || (item.width && isNaN(item.width))){
                            item.width = 0;
                        }

                        if(!item.height || (item.height && isNaN(item.height))){
                            item.height = 0;
                        }

                        if(!item.depth || (item.depth && isNaN(item.depth))){
                            item.depth = 0;
                        }

                        if(!item.weight || (item.weight && isNaN(item.weight))){
                            item.weight = 0;
                        }

                        barcodes.push(item.barcode);
                        if(ums && ums.length > 0){
                            ums.forEach((item) => {
                                if(item.barcode){
                                    if(!barcodes.includes(item.barcode)){
                                        barcodes.push(item.barcode);
                                    }else{
                                        duplicatedBarcodes++;
                                    }
                                }
                            });
                        }

                        formattedLines.push(
                            {
                                code: item.code,
                                description: item.description,
                                image: item.image,
                                product_type: product_type,
                                combination: item.combination,
                                barcode: item.barcode == '' ? null : item.barcode,
                                prices: prices,
                                ums: ums,
                                um: item.um,
                                vat: item.vat,
                                vat_reverse: item.vat_reverse,
                                production_department: item.production_department,
                                variants: variants,
                                related_products: item.related_products,
                                product_category: {},
                                products_categories_hierarchy: item.products_categories_hierarchy,
                                product_for_composition: item.product_for_composition,
                                short_description: item.short_description,
                                long_description: item.long_description,
                                weight: item.weight,
                                depth: item.depth,
                                height: item.height,
                                width: item.width,
                                ultimate_cost: item.ultimate_cost,
                                role_type: item.role_type == '' || item.role_type == null ? 'product' : item.role_type,
                                position: item.position ?? 0,
                                disabled: item.disabled
                            }
                        );

                        if(structure){
                            formattedLines[0].id = structure.id;
                        }
                        
                        index++;
                    });

                    // Lavora sui combinati
                    let copyOfCombines = [];
                    if(combineReferenceInMemory && combineReferenceInMemory.length > 0){
                        combineReferenceInMemory.forEach((item) => {
                            
                            item.product_type                   = formattedLines[0].product_type;
                            item.ums                            = [];//Object.assign([], formattedLines[0].ums);
                            item.um                             = Object.assign({}, formattedLines[0].um);
                            item.vat                            = Object.assign({}, formattedLines[0].vat);
                            item.vat_reverse                    = Object.assign({}, formattedLines[0].vat_reverse);
                            item.production_department          = Object.assign({}, formattedLines[0].production_department);
                            item.variants                       = Object.assign([], formattedLines[0].variants);
                            item.related_products               = Object.assign([], formattedLines[0].related_products);
                            item.products_categories_hierarchy  = Object.assign([], formattedLines[0].products_categories_hierarchy);
                            item.product_for_composition        = formattedLines[0].product_for_composition;
                            item.role_type                      = formattedLines[0].role_type;

                            if(item.barcode){
                                if(!barcodes.includes(item.barcode)){
                                    barcodes.push(item.barcode);
                                }else{
                                    duplicatedBarcodes++;
                                }
                            }

                            if(item.barcode == ''){
                                item.barcode = null;
                            }

                            copyOfCombines.push(Object.assign({}, item));

                            let newPrices = [];
                            item.prices.forEach((prc) => {
                                
                                if(prc.discount){
                                    if(isNaN(prc.discount)){
                                        prc.discount = null;
                                    } else{
                                        prc.discount = parseFloat(prc.discount);
                                    }
                                }

                                if(prc.price){
                                    if(isNaN(prc.price)){
                                        prc.price = null;
                                    }else{
                                        prc.price = parseFloat(prc.price);
                                    }
                                }

                                if(prc.price !== 0 && prc.price == ''){
                                    prc.price = null;
                                }

                                if(prc.discount !== 0 && prc.discount == ''){
                                    prc.discount = null;
                                }

                                if(prc.price !== 0 && prc.price == undefined){
                                    prc.price = null;
                                }

                                if(prc.discount !== 0 && prc.discount == undefined){
                                    prc.discount = null;
                                }

                                // Se il prezzo è ivato, ottieni nuovo imponibile
                                let taxable = prc.price;
                                if(prc.with_tax_included == 1){
                                    let tax = (taxable * item.vat.value) / (100 + item.vat.value);
                                    taxable = taxable - tax;
                                }

                                newPrices.push(
                                    {
                                        id: null,
                                        taxable: parseFloat( parseFloat(taxable.toString()).toFixed(2) ),
                                        discount: prc.discount,
                                        with_tax_included: prc.with_tax_included ?? 0,
                                        price_list: {
                                            id: prc.id
                                        }
                                    }
                                );

                            });

                            //item.prices = [...newPrices];
                            copyOfCombines[copyOfCombines.length - 1].prices = [...newPrices];
                            

                        });
                    }

                    if(duplicatedBarcodes > 0){
                        eMsg.push("Ci sono " + duplicatedBarcodes + " barcodes duplicati");
                    }

                    if(eMsg && eMsg.length > 0){
                        let message = "";
                        eMsg.forEach((item) => message += item + ". ");
                        setDisplayBackdrop(false);
                        setMessage(message);
                        return;
                    }

                    // Controlla se ne ha duplicato qualcuno sul backend!
                    if(!duplicatedBarcodes){

                        const id_product = structure ? structure.id : null;
                        const duplications = [];

                        for await (let b of barcodes){

                            const response = await fetch(
                                `${Endpoint}/api/mb/v1/product/check_barcodes?barcode=${b}${id_product ? `&id_product=${id_product}` : ``}`,
                                {
                                    method: "GET",
                                    headers: {
                                        'jwt': `Bearer ${localStorage.getItem('jwt')}`
                                    }
                                }
                            );

                            if(response){
                                if(response.status == 200){
                                    const res = await response.json();
                                    if(res.Result == "OK"){
                                        if(res.Json.length > 0){
                                            duplications.push(
                                                {
                                                    barcode: b,
                                                    products: [...res.Json]
                                                }
                                            );
                                        }
                                    }
                                }
                            }

                        }

                        if(duplications && duplications.length > 0){
                            
                            setDisplayBackdrop(false);

                            let msg = "";
                            duplications.forEach((item) => {
                                msg += `Il barcode ${item.barcode} è abbinato ai seguenti prodotti: `;
                                item.products.forEach((prd) => msg += prd + ", ");
                                msg = msg.substring(0, msg.length - 2) + ".";
                            });

                            setMessage(msg);

                            return;
                        }

                    }

                    // Controlla duplicazione codici
                    if(!structure){
                        let codes = [formattedLines[0].code];

                        if(copyOfCombines && copyOfCombines.length > 0){
                            
                            let duplicatedCodes = 0;
                            let emptyCodes = 0;

                            copyOfCombines.forEach((item) => {
                                if(item.code){
                                    if(codes.includes(item.code)){
                                        duplicatedCodes++;
                                    }else{
                                        codes.push(item.code);
                                    }
                                }else{
                                    emptyCodes++;
                                }
                            });

                            if(duplicatedCodes > 0){
                                setDisplayBackdrop(false);
                                setMessage(`Ci sono ${duplicatedCodes} codici duplicati!`);
                                return;
                            }

                            if(emptyCodes > 0){
                                setDisplayBackdrop(false);
                                setMessage(`Ci sono ${emptyCodes} codici vuoti!`);
                                return;
                            }

                        }

                        const encoded_codes = JSON.stringify({codes: codes});

                        // Controlla codici duplicati su backend
                        const responseCode = await fetch(
                            `${Endpoint}/api/mb/v1/product/check_duplicated_codes`,
                            {
                                method: "POST",
                                headers: {
                                    'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                                    'Content-Type': "application/json"
                                },
                                body: encoded_codes
                            }
                        );

                        if(responseCode){
                            if(responseCode.status == 201){
                                const jsonRes = await responseCode.json();
                                if(jsonRes.Result == "OK"){
                                    const duplicated_from_backend = jsonRes.Json.duplicated;
                                    if(duplicated_from_backend > 0){
                                        setDisplayBackdrop(false);
                                        setMessage(`Ci sono ${duplicated_from_backend} codici assegnati ad altri prodotti!`);
                                        return;
                                    }
                                }
                            }
                        }
                    }

                    setTimeout(async () => {

                        const lines = [formattedLines[0], ...copyOfCombines];

                        let saved = 0;
                        let index = 0;
                        let product_category_id = null;
                        const prdsToDispatch = [];
                        for await (let line of lines){
                            
                            let copyOfImage = null;
                            if(index == 0){
                                if(line.image){
                                    if(!line.image.startsWith('http')){
                                        copyOfImage = line.image;
                                        line.image = null; 
                                    }else{
                                        line.image = line.image.split(Endpoint).join("");
                                    }
                                }
                            }

                            if(product_category_id){
                                line.product_category = {
                                    id: product_category_id
                                }
                            }
                            const json = JSON.stringify(line);
                            const result = await MbApi_ProductCreate(json);
                            if(result){
                                if(line.product_for_composition == 1){
                                    if(reduxProductsForComposition && reduxProductsForComposition.length > 0){
                                        prdsToDispatch.push(result);
                                    }
                                }
                                saved++;
                                if(index == 0){ 

                                    // Appendi eventuale immagine..
                                    if(copyOfImage){
                                        
                                        const responseImage = await fetch(copyOfImage);
                                        const blob = await responseImage.blob();
                                        const fd = new FormData();
                                        const file = new File([blob], "image");
                                        fd.append('image', file);
                                        
                                        const responseUploadImage = await fetch(
                                            `${Endpoint}/api/mb/v1/product/upload_image?id_product=${result.id.toString()}`,
                                            {
                                                method: "POST",
                                                headers: {
                                                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                                                },
                                                body: fd
                                            }
                                        );

                                        if(responseUploadImage){
                                            if(responseUploadImage.status == 201){
                                                const resultUpload = await responseUploadImage.json();
                                                if(resultUpload.Result == "OK"){
                                                    console.log("Upload avvenuto con successo!");
                                                }
                                            }
                                        }

                                    }

                                    // Fa si che appartengano allo stesso gruppo!
                                    if(!structure){
                                        product_category_id = result.product_category.id;
                                    }

                                }
                            }else{
                                if(index == 0){
                                    break;
                                }
                            }
                            index++;
                        }

                        if(prdsToDispatch && prdsToDispatch.length > 0){
                            const prds = [...reduxProductsForComposition, ...prdsToDispatch];
                            dispatch(redux_set_product_composition(prds));
                        }

                        setDisplayBackdrop(false);

                        if(saved > 0){
                            
                            productModel = {
                                id: null,
                                code: null,
                                description: null,
                                image: null,
                                product_type: null,
                                combination: null,
                                barcode: null,
                                um: null,
                                vat: null,
                                vat_reverse: null,
                                production_department: null,
                                prices: null,
                                ums: null,
                                variants: null,
                                related_products: null,
                                product_category: null,
                                products_categories_hierarchy: []
                            };
                            setLines(null);
                            setCombineReferenceInMemory(null);
                            comeback();
                        }

                        setMessage(`Salvati ${saved} di ${lines.length} prodotti`);

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

        </>
    );

};  

export {
    MbAnagraficaNewProduct
}