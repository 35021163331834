import { useEffect } from "react";
import { MbAnagraficaProduct } from "../mb_anagrafiche/mb_tables/mb_anagrafica_product";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorProduct = ({callbackFromValidator, displayOnlyForComposition, displayOnlySimpleProduct, displayProductNotForComposition, searchDataValidator}) => {
    
    useEffect(() => {

    }, []);

    return (
        <>
            <MbMessage title="Prodotto" helper="Selezionare un prodotto" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaProduct isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }} 
                    searchDataValidator={searchDataValidator}
                    displayOnlyForComposition={'0'} 
                    displayOnlySimpleProduct={displayOnlySimpleProduct} 
                    displayProductNotForComposition={displayProductNotForComposition}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorProduct
}