import { Button, Checkbox, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PrimaryColor, RemoveHash, SaveAna } from "../mb_constants";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import { IndeterminateCheckBoxOutlined } from "@material-ui/icons";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbConfirm, MbMessage } from "../mb_components/mb_message";
import { MbValidatorModalitaPagamento } from "../mb_validator/mb_validator_modalita_pagamento";
import { MbApi_PaymentCreate, MbApi_PaymentExpirationCreate, MbApi_PaymentExpirationDeleteAll } from "../mb_api/mb_api_payment";
import { BlockPicker, GithubPicker, SliderPicker } from "react-color";

const MbRowExpiration = ({index, data, remove, addNewRow}) => {
    
    const [id, setId] = useState(null);
    const [description, setDescription] = useState(null);
    const [day, setDay] = useState(null);
    const [percentage, setPercentage] = useState(null);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        setDescription(data.description);
        setDay(data.day);
        setPercentage(data.percentage);
    }, [data]);

    return (
        <>  
            <MbConfirm open={confirm} message="Sicuro di voler cancellare il record?" confirm={() => {
                setConfirm(false);
                remove(index);
            }} close={() => setConfirm(false)}/>
            <span style={{fontSize: "13px", color: "red", cursor: "pointer", fontWeight: "bold"}} onClick={() => {
                setConfirm(true);
            }}>Rimuovi</span>
            <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={window.innerWidth >= 1000 ? 4 : 12}>
                        <TextField size="small" variant="outlined" label={"Descrizione"} style={{width: "100%"}} value={description} InputLabelProps={{ shrink: true }} onChange={(e) => {
                            setDescription(e.target.value);
                            data.description = e.target.value;
                        }}/>
                    </Grid>
                    <Grid item xs={window.innerWidth >= 1000 ? 4 : 12}>
                        <TextField size="small" variant="outlined" label={"Giorni"} style={{width: "100%"}} value={day} InputLabelProps={{ shrink: true }} onChange={(e) => {
                            setDay(e.target.value);
                            data.day = e.target.value;
                        }}/>
                    </Grid>
                    <Grid item xs={window.innerWidth >= 1000 ? 4 : 12}>
                        <TextField size="small" variant="outlined" label={"Percentuale"} style={{width: "100%"}} value={percentage} InputLabelProps={{ shrink: true }} onChange={(e) => {
                            setPercentage(e.target.value);
                            data.percentage = e.target.value;
                        }}/>
                    </Grid>
                </Grid>
            </div>
            <div style={{height: "20px"}}/>
        </>
    );

};

const MbAnagraficaNewPayment = ({comeback, structure}) => {
    
    const [id, setId]                                       = useState(null);
    const [code, setCode]                                   = useState(null);
    const [description, setDescription]                     = useState(null);
    const [paymentType, setPaymentType]                     = useState(null);
    const [useInApp, setUseInApp]                           = useState(false);
    const [dayStartExpiration, setDayStartExpiration]       = useState(null);
    const [paymentExpiration, setPaymentExpiration]         = useState([]);
    const [firstExpireEndOfMonth, setFirstExpireEndOfMonth] = useState(false);
    const [message, setMessage]                             = useState(null);
    const [displayBackdrop, setDisplayBackDrop]             = useState(false);
    const [color, setColor]                                 = useState(null);
    const [conditionPayment, setConditionPayment]           = useState("TP02");
    const [isCollected, setIsCollected]                     = useState(true);
    const [printNotFiscal, setPrintNotFiscal]               = useState(false);
    const [increaseRevenues, setIncreaseRevenues]           = useState(true);
    const [isTicket, setIsTicket]                           = useState(false);
    const [paymentEnum, setPaymentEnum]                     = useState("CONTANTI");
    const [position, setPosition]                           = useState("-1");

    // Validatori
    const [validatorModalita, setValidatorModalita] = useState(false);

    function eHashChange(){
        setValidatorModalita(window.location.hash.includes('#modalita') && !validatorModalita);
    }

    function eKeyDown(e){
        if(e.key == 'Enter'){
            if(window.location.hash.includes('#modalita')){
                return;
            }
            SaveAna(e);
        }
    }

    useEffect(() => {
        
        if(structure){
            setId(structure[0]);
            setCode(structure[1]);
            setDescription(structure[2]);
            setPaymentType(structure[3]);
            setUseInApp(structure[5] == 0 ? false : true);
            setDayStartExpiration(structure[4]);
            setPaymentExpiration(structure[6]);
            setFirstExpireEndOfMonth(structure[7] == 0 ? false : true);
            setColor(structure[structure.length - 1].color_in_app);
            setConditionPayment(structure[structure.length - 1].payment_condition);
            setIsCollected(structure[structure.length - 1].collected_payment == 1 ? true : false);
            setPrintNotFiscal(structure[structure.length - 1].print_not_fiscal == 1 ? true : false);
            setPrintNotFiscal(structure[structure.length - 1].print_not_fiscal == 1 ? true : false);
            setIncreaseRevenues(structure[structure.length - 1].increase_revenues == 1 ? true : false);
            setIsTicket(structure[structure.length - 1].is_ticket == 1 ? true : false)
            setPaymentEnum(structure[structure.length - 1].payment_enum_type ?? "CONTANTI");
            setPosition(structure[structure.length - 1].position.toString());
        }

        window.addEventListener('hashchange', eHashChange);
        window.addEventListener('keydown', eKeyDown);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
        }); 

    }, []);

    return (
        <>

            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />
            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} pagamento</Typography> 
            
            <div style={{height: "20px"}}/>
            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice"} style={{width: "100%"}} value={code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione"} style={{width: "100%"}} value={description} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDescription(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField size="small" variant="outlined" label={"Tipo di pagamento"} style={{width: "100%"}} value={paymentType} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: <SearchIcon sx={{cursor: "pointer"}}/>
                    }} onClick={() => {
                        window.location.hash += '#modalita';
                    }}/>
                </Grid>
                {/*<Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Giorni prima scadenza"} style={{width: "100%"}} value={dayStartExpiration} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDayStartExpiration(e.target.value);
                    }}/>
                </Grid>*/}
            </Grid>

            <div style={{height: "20px"}}/>


            <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "center", gap: "15px"}}>
                <TextField size="small" variant="outlined" label={"Colore in app"} style={{width: "100%"}} value={color} InputLabelProps={{ shrink: true }} onChange={(e) => {
                    setColor(e.target.value);
                }} type="color"/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }
                <FormControl size="small" style={{width: "100%"}}>
                    <InputLabel id="demo-simple-select-type-payment">Tipo pagamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-type-payment"
                        id="demo-simple-select"
                        value={conditionPayment}
                        label="Tipo pagamento"
                        onChange={(e) => {
                            setConditionPayment(e.target.value);
                        }}
                    >
                        <MenuItem value={"TP01"}>A rate</MenuItem>
                        <MenuItem value={"TP02"}>Completo</MenuItem>
                        <MenuItem value={"TP03"}>Anticipo</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div style={{height: "20px"}}/>

            <FormControl size="small" style={{width: "100%"}}>
                <InputLabel id="demo-simple-select-type-payment">Tipologia pagamento (per calcolo statistico)</InputLabel>
                <Select
                    labelId="demo-simple-select-type-payment"
                    id="demo-simple-select"
                    value={paymentEnum}
                    label="Tipologia pagamento (per calcolo statistico)"
                    onChange={(e) => {
                        setPaymentEnum(e.target.value);
                    }}
                >
                    <MenuItem value={"CONTANTI"}>CONTANTI</MenuItem>
                    <MenuItem value={"CARTA"}>CARTA</MenuItem>
                    <MenuItem value={"BRACCIALETTO"}>BRACCIALETTO</MenuItem>
                    <MenuItem value={"VOUCHER"}>VOUCHER</MenuItem>
                    <MenuItem value={"OMAGGIO"}>OMAGGIO</MenuItem>
                    <MenuItem value={"GIA_RISCOSSO"}>GIA RISCOSSO</MenuItem>
                    <MenuItem value={"NON_RISCOSSO_DIPENDENTI"}>NON RISCOSSO DIPENDENTI</MenuItem>
                </Select>
            </FormControl>

            <div style={{height: "20px"}}/>

            <TextField size="small" variant="outlined" label={"Posizione"} style={{width: "100%"}} value={position} InputLabelProps={{ shrink: true }} onChange={(e) => {
                setPosition(e.target.value);
            }}/>

            <div style={{height: "20px"}}/>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={isCollected} onChange={(e, checked) => {
                    setIsCollected(checked);
                }}/>} label="Pagamento riscosso" />
            </FormGroup>

            <div style={{height: "20px"}}/>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={isTicket} onChange={(e, checked) => {
                    setIsTicket(checked);
                }}/>} label="E` un ticket" />
            </FormGroup>

            <div style={{height: "20px"}}/>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={increaseRevenues} onChange={(e, checked) => {
                    setIncreaseRevenues(checked);
                }}/>} label="Incrementa ricavi" />
            </FormGroup>

            <div style={{height: "20px"}}/>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={printNotFiscal} onChange={(e, checked) => {
                    setPrintNotFiscal(checked);
                }}/>} label="Stampa su non fiscale" />
            </FormGroup>

            <div style={{height: "20px"}}/>

            {/*<div style={{display: "flex", justifyContent: "space-between"}}>
                
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={useInApp} onChange={(e, checked) => {
                        setUseInApp(checked);
                    }}/>} label="Usa pagamento in app" />
                </FormGroup>

                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={firstExpireEndOfMonth} onChange={(e, checked) => {
                        setFirstExpireEndOfMonth(checked);
                    }}/>} label="Prima scadenza fine mese" />
                </FormGroup>

            </div>

            <div style={{height: "10px"}}/>

            <Divider/>

            <div style={{height: "20px"}}/>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Typography variant="h6">Scadenze</Typography> 
                <Button variant="contained" className="add-new-row-button" id="btn_new_mbtable" onClick={() => {
                    const exp = [...paymentExpiration];
                    exp.push({id: null, description: null, day: null, percentage: null});
                    setPaymentExpiration(exp);
                }} style={{marginTop: window.innerWidth >= 1000 ? "20px" : "0px"}}>AGGIUNGI</Button>
            </div>

            <div style={{height: "20px"}}/>
            {
                paymentExpiration.map((item, index) => {
                    return (
                        <MbRowExpiration data={item} key={`exp_${index}`} index={index}
                            remove={(_index) => {
                                const exp = [...paymentExpiration];
                                exp.splice(_index, 1);
                                setPaymentExpiration(exp);
                            }}
                        />
                    );
                })
            }*/}

            <div style={{marginTop: "50px"}}></div>
            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" id="btn_save" onClick={async () => {

                    let eMsg = '';

                    if(paymentExpiration.length > 0){

                        let amount = 0.00;
                        let day = paymentExpiration[0].day;
                        for(let x = 0; x < paymentExpiration.length; x++){
                            if(!paymentExpiration[x].description || !paymentExpiration[x].day || !paymentExpiration[x].percentage){
                                eMsg = "I campi delle scadenze, se compilate, sono obbligatori!"; break;
                            }
                            if(isNaN(paymentExpiration[x].day) || isNaN(paymentExpiration[x].percentage)){
                                eMsg = "Giorno e percentuale sono campi numerici delle scadenze!"; break;
                            }
                            amount += parseFloat( paymentExpiration[x].percentage );
                            if(x > 0){
                                let _day = parseInt(paymentExpiration[x].day);
                                if(_day <= day){
                                    eMsg = 'Le scadenze devono essere indicate in senso crescente!';
                                    break;
                                }else{
                                    day = _day;
                                }
                            }else{
                                day = parseInt(day);
                            }
                        }
                        
                        if(!eMsg){
                            if(amount !== 100.00){
                                eMsg = 'Le scadenze non coprono il 100%';
                            }
                        }

                        if(eMsg){
                            setMessage(eMsg);
                            return;
                        }

                    }

                    if(!code || !description){
                        setMessage('Codice e descrizione sono campi obbligatori!');
                        return;
                    }

                    //if(code.length > 5 || description.length > 200){
                    //    setMessage('Attenzione, il codice ha lunghezza massima 5 caratteri, mentre descrizione 200!');
                    //    return;
                    //}

                    /*if((dayStartExpiration && isNaN(dayStartExpiration))){
                        setMessage('Attenzione, giorni prima scadenza deve essere compilato e deve essere numerico!');
                        return;
                    }*/

                    setDisplayBackDrop(true);

                    setTimeout(async () => {
                        // Salva prima la testa del pagamento e successivamente le scadenze, se presenti!
                        const resultPayment = await MbApi_PaymentCreate(
                            JSON.stringify({
                                id                      : id,
                                code                    : code,
                                description             : description,
                                payment_type            : paymentType,
                                day_start_expiration    : 0,//dayStartExpiration ? dayStartExpiration : 0,
                                use_in_app              : useInApp ? 1 : 0,
                                start_end_of_month      : 0,//firstExpireEndOfMonth ? 1 : 0,
                                color_in_app            : color ?? PrimaryColor,
                                payment_condition       : conditionPayment,
                                collected_payment       : isCollected ? 1 : 0,
                                print_not_fiscal        : printNotFiscal ? 1 : 0,
                                increase_revenues       : increaseRevenues ? 1 : 0,
                                is_ticket               : isTicket ? 1 : 0,
                                payment_enum_type       : paymentEnum,
                                position                : position && !isNaN(position) ? parseInt(position) : -1
                            })
                        );

                        if(resultPayment){
                            
                            const idPayment = resultPayment.id;
                            //const _paymentExp = [...paymentExpiration];
                            const _paymentExp = [];

                            let index = 0;
                            function recursionPayment(callback, error){
                                if(_paymentExp && _paymentExp.length > 0){
                                    MbApi_PaymentExpirationCreate(idPayment, JSON.stringify({
                                        description: _paymentExp[index].description,
                                        day: _paymentExp[index].day,
                                        percentage: _paymentExp[index].percentage
                                    })).then((response) => {
                                        index++;
                                        if(index == _paymentExp.length){
                                            callback();
                                        }else{
                                            recursionPayment(callback);
                                        }
                                    }).catch(() => {
                                        error();
                                    });
                                }else{
                                    callback();
                                }
                            }

                            const resultDelete = id ? await MbApi_PaymentExpirationDeleteAll(id) : true;
                            if(resultDelete){
                                recursionPayment(
                                    () => {

                                        // Pulisci per un nuovo inserimento..
                                        setId(null);
                                        setPaymentExpiration([]);
                                        setCode('');
                                        setDescription('');
                                        setPaymentType('');
                                        setUseInApp(false);
                                        setDayStartExpiration('');
                                        setFirstExpireEndOfMonth(false);
                                        setConditionPayment('TP01');
                                        setIsCollected(false);
                                        setPrintNotFiscal(false);
                                        setIsTicket(false);
                                        setIncreaseRevenues(false);
                                        setPosition("-1");
    
                                        setDisplayBackDrop(false);
                                        setMessage('Pagamento creato con successo!');
                                        comeback(); 
                                    },
                                    () => {
                                        setDisplayBackDrop(false);
                                        setMessage('Errore durante la creazione delle scadenze!');
                                    }
                                );
                            }else{
                                setDisplayBackDrop(false);
                                setMessage('Errore durante la creazione delle scadenze!');                                
                            }

                        }else{
                            setDisplayBackDrop(false);
                            setMessage('Errore durante la creazione del pagamento!');
                        }

                    }, 1000);

                }}>SALVA</Button>
            </div>

            {
                // Validatore modalità pagamento
                validatorModalita ? 
                    <MbValidatorModalitaPagamento
                        close={() => {  
                            window.location.hash = window.location.hash.split("#modalita").join("");
                        }}
                        choosed={(value) => {
                            setPaymentType(value[0]);
                            window.location.hash = window.location.hash.split("#modalita").join("");
                        }}
                    />
                :
                    <></>
            }

        </>
    );
};

export {
    MbAnagraficaNewPayment
}