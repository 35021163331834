import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @returns 
 */
const MbApi_ProductionDepartmentList = async (page, search) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/production_department/list?page=${page}&record_for_page=${RecordsForPage}${search ? `&search=${search}` : ''}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} code 
 * @returns 
 */
const MbApi_ProductionDepartmentGetByCode = async (code) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/production_department/get_by_code?code=${code}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} body 
 * @returns 
 */
const MbApi_ProductionDepartmentCreate = async (body) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/production_department/create`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} id_production_department
 * @returns 
 */
const MbApi_ProductionDepartmentDelete = async (id_production_department) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/production_department/delete?id_production_department=${id_production_department}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

export {
    MbApi_ProductionDepartmentList,
    MbApi_ProductionDepartmentCreate,
    MbApi_ProductionDepartmentDelete,
    MbApi_ProductionDepartmentGetByCode
}