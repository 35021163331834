import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MbApi_DeviceDelete, MbApi_DeviceList } from "../../mb_api/mb_api_device";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Permission, RecordsForPage, usePermission } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import { MbAnagraficaNewDevice } from "../mb_anagrafica_new_device";

const MbAnagraficaDevice = ({isValidator, callbackFromValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(0);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const navigate                              = useNavigate();
    const permission                            = usePermission();

    function fetchData(){
        MbApi_DeviceList(page, search).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.devices.map((item, index) => 
                        items.push([item.id, item.code, item.description, item.serial_number, item.device_setting ? item.device_setting.code : "", item])
                    )
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        
        MbSaveColumnsForTable("Dispositivi", ["ID", "Codice", "Descrizione", "Numero seriale", "Parametri"]);

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewDevice 
                        structure={structUpdate}
                        comeback={() => {
                            setPage(-1);
                            setStructUpdate(null);
                            //navigate(-1);
                        }}
                    />
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"Dispositivi"}
                        columns={
                            MbProposeColumns(
                                'Dispositivi',
                                [
					                {"name": "ID"}, {"name": "Codice"}, {"name": "Descrizione"}, {"name": "Numero seriale"}, {"name": "Parametri"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={permission.p_device_delete == 1}
                        displayNew={permission.p_device_create == 1}
                        displayRead
                        displayUpdate={permission.p_device_create == 1}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={(data) => {
                            setStructUpdate(data);
                            window.location.hash = '#new';
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_DeviceDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(value) => {
                            if(isValidator){
                                callbackFromValidator(value);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_DISPOSITIVI"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaDevice
}