import { useEffect, useState } from "react";
import { MbApi_PaymentDelete, MbApi_PaymentList } from "../../mb_api/mb_api_payment";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Permission, RecordsForPage, usePermission } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { MbAnagraficaNewPayment } from "../mb_anagrafica_new_payment";
//import { SignalCellularNoSimOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const MbAnagraficaPayment = ({isValidator, callbackFromValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(-1);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const navigate                              = useNavigate();
    const permission                            = usePermission();

    function fetchData(){
        MbApi_PaymentList(page, search).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.payments.map((item, index) => 
                        items.push([
                            item.id, 
                            item.code, 
                            item.description, 
                            item.payment_type, 
                            item.payment_condition,
                            item.day_start_expiration,
                            item.start_end_of_month == 1 ? <CheckIcon sx={{color: "green"}}/> : <CloseIcon sx={{color: "red"}}/>,
                            <div onClick={() => {
                                
                            }}>
                                {
                                    item.expirations.map((exp) => <p>{exp.description} - {parseFloat (exp.percentage ).toFixed(2).replace(".", ",")}%</p>)
                                }
                            </div>,
                            item.use_in_app == 1 ? <CheckIcon sx={{color: "green"}}/> : <CloseIcon sx={{color: "red"}}/>,

                            // Non visibili a video perchè non sono parte delle colonne
                            item.use_in_app, 
                            item.expirations,
                            item.start_end_of_month,
                            item
                        ])
                    )
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        
        MbSaveColumnsForTable("pagamenti", [
            "ID", 
            "Codice", 
            "Descrizione", 
            "Modalità pagamento", 
            "Condizioni pagamento"
            //"Giorni prima scadenza", 
            //"Prima scadenza fine mese", 
            //"Scadenze", 
            //"Usa in app"
        ]);

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewPayment comeback={() => {
                        setPage(-1);
                        setStructUpdate(null);
                        //navigate(-1);
                    }} structure={structUpdate}/>
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"pagamenti"}
                        columns={
                            MbProposeColumns(
                                'pagamenti',
                                [
					                {"name": "ID"}, 
                                    {"name": "Codice"}, 
                                    {"name": "Descrizione"}, 
                                    {"name": "Modalità pagamento"}, 
                                    {"name": "Condizioni pagamento"}
                                    //{"name": "Giorni prima scadenza"}, 
                                    //{"name": "Prima scadenza fine mese"}, 
                                    //{"name": "Scadenze"}, 
                                    //{"name": "Usa in app"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={permission.p_payment_delete == 1}
                        displayNew={permission.p_payment_create == 1}
                        displayRead
                        displayUpdate={permission.p_payment_create == 1}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={(data) => {
                            const temp = [...data];
                            temp.splice(5, 1);
                            temp.splice(5, 1);
                            temp.splice(5, 1);
                            setStructUpdate(temp);
                            window.location.hash = '#new';
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_PaymentDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(value) => {
                            if(isValidator){
                                callbackFromValidator(value);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_PAGAMENTI"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaPayment
}