import { Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, TextField } from "@mui/material";
import { MbWhere } from "../mb_components/mb_where";
import { MbLayout } from "./mb_layout";
import { useEffect, useState } from "react";
import { Endpoint, PrimaryColor } from "../mb_constants";
import { MbMessage } from "../mb_components/mb_message";

const MbPromoV2 = () => {

    const [id, setId]                       = useState(null);
    const [promoType, setPromoType]         = useState(0);
    const [products, setProducts]           = useState([]);
    const [search, setSearch]               = useState(null);
    const [ccs, setCcs]                     = useState([]);
    const [validFrom, setValidFrom]         = useState(null);
    const [validTo, setValidTo]             = useState(null);
    const [code, setCode]                   = useState(null);
    const [description, setDescription]     = useState(null);
    const [newValue, setNewValue]           = useState(null);
    const [minQta, setMinQta]               = useState(null);
    const [discount, setDiscount]           = useState(null);
    const [timestamp, setTimestamp]         = useState(new Date().getTime());
    const [categories, setCategories]       = useState([]);
    const [promos, setPromos]               = useState([]);
    const [cart, setCart]                   = useState([]);
    const [repeatOnce, setRepeatOnce]       = useState(false);
    const [manualPromo, setManualPromo]     = useState(false);
    const [analyzing, setAnalyzing]         = useState(false);

    const cleanAll = () => {
      
        setId(null);
        setPromoType(0);
        setCode('');
        setDescription('');
        setMinQta('');
        setNewValue('');
        setDiscount('');
        setValidFrom('');
        setValidTo('');
        setSearch('');
        setRepeatOnce(false);
        setManualPromo(false);

        products.forEach(p => {
            p.checked = false;
            p.new_price = 0.00;
            p.quantity = 0;
        });

        ccs.forEach(cc => {
            cc.checked = false;
        });

        categories.forEach(c => {
            c.apply = false;
        });

        setTimestamp(new Date().getTime());

    };

    useEffect(() => {

        (async () => {

            const responsePrds = 
                await fetch(
                    `${Endpoint}/api/mb/v1/product/list?page=0&record_for_page=99999&product_type=product&light_list=1`,
                    {
                        headers: {
                            'jwt': localStorage.getItem('jwt')
                        }
                    }
                );

            if(responsePrds.status == 200){
                const products_ = await responsePrds.json();
                if(products_.Result == "OK"){
                    
                    let categories_ = [];
                    products_.Json.products.forEach((p) => {
                        
                        p.new_price = 0.00;
                        p.quantity = 0;
                        p.checked = false;

                        if(p.products_categories_hierarchy && p.products_categories_hierarchy.length > 0){
                            let cat;
                            if((cat = p.products_categories_hierarchy[0].category)){
                                p.rapid_category_id = cat.id;
                                if(categories_.findIndex(c => c.id == cat.id) == -1){
                                    cat.apply = false;
                                    categories_.push(cat);
                                }
                            }
                        }

                    });
                    setCategories(categories_);
                    setProducts(products_.Json.products);

                }
            }

            const responseCcs =
                await fetch(
                    `${Endpoint}/api/mb/v1/cost_center/list?page=0&record_for_page=99999`,
                    {
                        method: "GET",
                        headers: {
                            'jwt': localStorage.getItem('jwt')
                        }
                    }
                );

            if(responseCcs.status == 200){
                const ccs_ = await responseCcs.json();
                if(ccs_.Result == "OK"){
                    ccs_.Json.cost_centers.forEach(c => c.checked = false);
                    setCcs(ccs_.Json.cost_centers);
                }
            }
            
        })();

        const pop = () => {
            setTimestamp(new Date().getTime());
        };

        window.addEventListener('popstate', pop);

        return (() => window.removeEventListener('popstate', pop));
    }, []);

    return (
        <>
        
            <MbLayout openedVoices={['tabelle', 'promo']}>     
                    
                <MbWhere data={["Tabelle", "Promo"]}/>
                <div style={{height: "20px"}}/>

                {
                    window.location.hash == "#study_promo" ? 
                        <>

                            <p style={{fontWeight: "bold", color: "red"}}>** L'analisi prende in considerazione tutte le promo registrate, senza considerare centri di costo e periodi di validità. Tali controlli saranno gestiti nell'applicativo.</p>
                            <div style={{fontSize: "30px", fontWeight: "bold"}}>Prodotti</div>
                            <div style={{display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "15px"}}>
                                {
                                    products.map((product, index) => 
                                        <Button variant="contained" key={`product_study_${index}`} onClick={() => {
                                            cart.push(
                                                {
                                                    id: product.id,
                                                    description: product.description,
                                                    code: product.code,
                                                    price: "0.00"
                                                }
                                            );
                                            setTimestamp(new Date().getTime());
                                        }}>{product.description}</Button>
                                    )
                                }
                            </div>
                            
                            <Divider style={{marginTop: "15px"}}/>

                            <div style={{fontSize: "30px", fontWeight: "bold", marginTop: "15px"}}>Carrello</div>

                            <div style={{padding: "20px", display: "flex", flexDirection: "column", gap: "15px"}}>
                                {
                                    cart.map((item, index) => 
                                        <div key={`cart_line_${index}`} style={{display: "flex", gap: "15px", alignItems: "center"}}>
                                            <div style={{width: "300px", fontSize: "18px"}}><span style={{fontWeight: "bold"}}>[{item.code}]</span>&nbsp;&nbsp;{item.description}</div>
                                            <div>
                                                <TextField label="Prezzo" InputLabelProps={{shrink: true}} onChange={(e) => {
                                                    item.price = e.target.value;
                                                    setTimestamp(new Date().getTime());
                                                }} value={item.price}></TextField>
                                            </div>
                                            <div>
                                                <Button variant="contained" style={{background: "red"}} onClick={() => {
                                                    cart.splice(index, 1);
                                                    setTimestamp(new Date().getTime());
                                                }}>Rimuovi</Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                            {
                                cart.length > 0 ? 
                                    <Divider/>
                                :
                                    <></>
                            }
                            
                            {
                                cart.length > 0 ? 
                                    <Button variant="contained" style={{marginTop: "15px"}} onClick={async () => {
                                        
                                        document.querySelector("#html_promo").innerHTML = "";

                                        if(cart.findIndex(c => !c.price || (c.price && isNaN(c.price.replace(",", ".")))) > -1){
                                            alert("Ci sono errori nel carrello. Controlla i prezzi indicati")
                                            return;
                                        }

                                        if(cart.findIndex(c => parseFloat( c.price.toString() ) == 0 ) > -1){
                                            alert("I prodotti a 0 non sono contemplati. Inserire valori");
                                            return;
                                        }

                                        setAnalyzing(true);

                                        try{

                                            // Ottieni tutte le promo
                                            const response =
                                                await fetch(
                                                    `${Endpoint}/api/mb/v1/promo/listv2`,
                                                    {
                                                        method: "GET",
                                                        headers: {
                                                            'jwt': localStorage.getItem('jwt')
                                                        }
                                                    }
                                                );

                                            if(response.status == 200){
                                                const json = await response.json();
                                                if(json.Result == "OK"){
                                                    
                                                    let promos_ = [];
                                                    json.Json.Records.forEach(r => {
                                                        const decoded = JSON.parse(r.payload);
                                                        decoded.id = r.id;
                                                        promos_.push(decoded);
                                                    });
                                                    
                                                    // Avvia analisi
                                                    const response =
                                                        await fetch(
                                                            `https://promo.cmh.it/api/service/study`,
                                                            {
                                                                method: "POST",
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify(
                                                                    {
                                                                        products: cart,
                                                                        promos: promos_
                                                                    }
                                                                )
                                                            }
                                                        );

                                                    if(response.status == 200){
                                                        const json = await response.json();
                                                        if(json.success){
                                                            
                                                            document.querySelector("#html_promo").innerHTML = json.html;

                                                            // Ottieni le promo vincenti
                                                            document.querySelector("#html_promo").innerHTML += "<h1>Risultato finale</h1>";
                                                            document.querySelector("#html_promo").innerHTML += json.html.split("promo-round").join("fpr promo-round").split("<h1>Immagine applicabilità</h1>").join("");

                                                            const items = document.getElementsByClassName('fpr');
                                                            for(let i = 0; i < items.length; i++){
                                                                const className = items[i].className.split("fpr promo-round-").join("").trim();
                                                                if(className){
                                                                    if(json.winnerPromo.findIndex(w => w.trim() == className) == -1){
                                                                        const obj = document.getElementsByClassName(`fpr promo-round-${className}`);
                                                                        if(obj && obj.length > 0){
                                                                            for(let o = 0; o < obj.length; o++){
                                                                                obj[o].style.background = "#353535";
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            document.querySelector("#html_promo").innerHTML += `<h1>Sconto finale</h1><h3 style='color: red'>-${ (json.discountValue ?? 0.00).toString() } €</h3>`;

                                                        }
                                                    }

                                                }
                                            }

                                        }catch(e){
                                            console.log(e);
                                        }finally{
                                            setAnalyzing(false);
                                        }

                                    }}>Produci analisi</Button>
                                :
                                    <></>
                            }

                            {
                                analyzing ? 
                                    <div>
                                        Analisi in corso...<CircularProgress/>
                                    </div>
                                :
                                    <></>
                            }

                            <div id="html_promo"></div>

                        </>
                    :
                        <></>
                }

                <div style={{display: window.location.hash != "#study_promo" ? "" : "none"}}>

                    <MbMessage open={promos.length > 0} close={() => setPromos([])} title={"Promozioni"}>
                        <div style={{padding: "20px", display: "flex", flexDirection: "column", gap: "15px", marginTop: "20px"}}>
                            {
                                promos.map((promo, index) => 
                                    <div style={{display: "flex", justifyContent: "space-between"}} key={`promo_${promo.id}`}>
                                        <div style={{width: "350px"}}>{JSON.parse(promo.payload).description}</div>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <Button variant="contained" onClick={() => {
                                                
                                                cleanAll();
                                                setId(promo.id);

                                                const payload = JSON.parse(promo.payload);

                                                setPromoType(payload.type);
                                                setCode(payload.code);
                                                setDescription(payload.description);
                                                if(payload.newValue && payload.newValue > 0){
                                                    setNewValue(payload.newValue.toString());
                                                }else{
                                                    setDiscount(payload.percentageDiscountVal.toString());
                                                }
                                                if(payload.minQuantity){
                                                    setMinQta(payload.minQuantity.toString());
                                                }
                                                setValidFrom(payload.startFrom);
                                                setValidTo(payload.endTo);
                                                setRepeatOnce(payload.repeatOnce);

                                                if(payload.manualPromo){
                                                    setManualPromo(true);
                                                }
                                                
                                                products.forEach(p => {
                                                    p.checked = false;
                                                    p.new_price = 0.00;
                                                    p.quantity = 0;
                                                });
                    
                                                ccs.forEach(cc => {
                                                    cc.checked = false;
                                                });
                    
                                                categories.forEach(c => {
                                                    c.apply = false;
                                                });

                                                payload.center_costs.forEach(c => {
                                                    const index = ccs.findIndex(cc => cc.id == c);
                                                    if(index > -1){
                                                        ccs[index].checked = true;
                                                    }
                                                });

                                                if(payload.type == 0){
                                                    payload.valProductsIds.forEach(v => {
                                                        const index = products.findIndex(i => i.id == v);
                                                        if(index > -1){
                                                            products[index].checked = true;
                                                        }
                                                    });
                                                }else{
                                                    payload.setProducts.forEach(v => {
                                                        const index = products.findIndex(i => i.id == v.id);
                                                        if(index > -1){
                                                            products[index].checked = true;
                                                            products[index].new_price = v.newPrice.toString();
                                                            products[index].quantity = v.quantity.toString();
                                                        }
                                                    });
                                                }

                                                setTimestamp(new Date().getTime());

                                                setPromos([]);

                                            }}>Modifica</Button>
                                            <Button style={{background: "red", marginLeft: "10px"}} variant="contained" onClick={async () => {

                                                const responseQst = window.confirm("Sei sicuro di voler cancellare la promo?");
                                                if(responseQst){
                                                    try{
                                                        const response = 
                                                            await fetch(
                                                                `${Endpoint}/api/mb/v1/promo/deletev2?id=${promo.id}`,
                                                                {
                                                                    method: "DELETE",
                                                                    headers: {
                                                                        'jwt': localStorage.getItem('jwt')
                                                                    }
                                                                }
                                                            );
                                                        if(response.status == 200){
                                                            const json = await response.json();
                                                            if(json.Result == "OK"){
                                                                setPromos([]);
                                                                return;
                                                            }
                                                        }
                                                    }catch(_){}
                                                    alert("Errore cancellazione promo");
                                                }

                                            }}>Elimina</Button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </MbMessage>

                    <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                        
                        <div style={{display: "flex", gap: "15px", cursor: "pointer", justifyContent: "space-between"}}>
                            <div style={{display: "flex", gap: "15px"}}>
                                <div style={{color: promoType == 0 ? "#000000" : "#00000040", fontSize: "35px", fontWeight: "bold"}} onClick={() => setPromoType(0)}>Promo BASKET</div>
                                <div style={{fontSize: "35px"}}>/</div>
                                <div style={{color: promoType == 1 ? "#000000" : "#00000040", fontSize: "35px", fontWeight: "bold"}} onClick={() => setPromoType(1)}>Promo FIXED QUANTITY</div>
                            </div>
                            <div style={{display: "flex", gap: "10px"}}>
                                <Button variant="contained" onClick={() => {
                                    cleanAll();
                                }}>Nuova promo</Button>
                                <Button variant="contained" onClick={async () => {

                                    const response =
                                        await fetch(
                                            `${Endpoint}/api/mb/v1/promo/listv2`,
                                            {
                                                method: "GET",
                                                headers: {
                                                    'jwt': localStorage.getItem('jwt')
                                                }
                                            }
                                        );

                                    if(response.status == 200){
                                        const json = await response.json();
                                        if(json.Result == "OK"){
                                            if(json.Json.Records.length == 0){
                                                alert("Non hai promo");
                                                return;
                                            }
                                            setPromos(json.Json.Records);
                                        }
                                    }

                                }}>Tutte le mie promo</Button>
                                <Button style={{background: "red"}} variant="contained" onClick={() => {
                                    window.location.hash = "#study_promo";
                                    setCart([]);
                                    setTimestamp(new Date().getTime());
                                }}>Studio analisi promo</Button>
                            </div>
                        </div>
                        <TextField label="Codice*" InputLabelProps={{ shrink: true }} value={code} onChange={(e) => setCode(e.target.value)} style={{marginTop: "20px"}}></TextField>
                        <TextField label="Descrizione*" InputLabelProps={{ shrink: true }} value={description} onChange={(e) => setDescription(e.target.value)}></TextField>
                        {
                            promoType == 0 ? 
                                <TextField label="Nuovo valore*" InputLabelProps={{ shrink: true }} style={{display: discount ? "none" : ""}} value={newValue} onChange={(e) => setNewValue(e.target.value)}></TextField>   
                            :
                                <></>
                        }
                        {
                            promoType == 0 ? 
                                <TextField label="Quantità minima per scatto promo*" InputLabelProps={{ shrink: true }} value={minQta} onChange={(e) => setMinQta(e.target.value)}></TextField>
                            :
                                <></>
                        }
                        {
                            promoType == 0 ? 
                                <TextField label="Sconto percentuale*" InputLabelProps={{ shrink: true }} style={{display: newValue ? "none" : ""}} value={discount} onChange={(e) => setDiscount(e.target.value)}></TextField>
                            :
                                <></>
                        }
                        <FormControlLabel label="Ripeti una volta" control={<Checkbox checked={repeatOnce} onChange={(e) => {
                            setRepeatOnce(e.target.checked);
                        }}></Checkbox>}></FormControlLabel>

                        <FormControlLabel label="Applicazione manuale" control={<Checkbox checked={manualPromo} onChange={(e) => {
                            setManualPromo(e.target.checked);
                        }}></Checkbox>}></FormControlLabel>

                        <div style={{display: "flex", gap: "20px", marginTop: "10px"}}>

                            <div style={{width: "100%"}}>
                                <div>Data inizio validità</div>
                                <TextField type="date" InputLabelProps={{ shrink: true }} style={{width: "100%"}} value={validFrom} onChange={(e) => setValidFrom(e.target.value)}></TextField>
                            </div>

                            <div style={{width: "100%"}}>
                                <div>Data fine validità</div>
                                <TextField type="date" InputLabelProps={{ shrink: true }} style={{width: "100%"}} value={validTo} onChange={(e) => setValidTo(e.target.value)}></TextField>
                            </div>

                        </div>

                        <Divider style={{marginTop: "10px"}}/>

                        {
                            // Centri di costo
                        }

                        <div style={{fontWeight: "bold", fontSize: "30px"}}>Centro di costo</div>

                        <div style={{display: "flex", gap: "10px"}}>

                            <Button variant="contained" style={{width: "200px"}} onClick={() => {
                                ccs.forEach(c => c.checked = true);
                                setTimestamp(new Date().getTime());
                            }}>Seleziona tutto</Button>

                            <Button variant="contained" style={{width: "200px", background: "red"}} onClick={() => {
                                ccs.forEach(c => c.checked = false);
                                setTimestamp(new Date().getTime());
                            }}>Deseleziona tutto</Button>

                        </div>

                        <div style={{display: "flex", gap: "18px", flexDirection: "column", marginTop: "10px"}}>
                            {
                                (ccs ?? []).map((item, index) => 
                                    <FormControlLabel key={`cc_${item.id}`} label={item.description} control={<Checkbox checked={item.checked} onChange={(e) => {
                                        item.checked = e.target.checked;
                                        setTimestamp(new Date().getTime());
                                    }}></Checkbox>}></FormControlLabel>
                                )
                            }
                        </div>

                        <Divider style={{marginTop: "10px"}}/>

                        {
                            // Articoli
                        }
                        <div style={{fontWeight: "bold", fontSize: "30px"}}>Prodotti</div>
                        <TextField label="Ricerca..." value={search} onChange={(e) => setSearch(e.target.value)}></TextField>
                        
                        <div style={{padding: "15px", border: "1px solid #00000040", display: "flex", flexDirection: "column", gap: "15px"}}>
                            <div style={{fontSize: "18px", fontWeight: "bold"}}>Filtri globali</div>
                            <div style={{display: "flex", gap: "10px"}}>
                                <Button variant="contained" onClick={() => {
                                    products.forEach(p => p.checked = true);
                                    setTimestamp(new Date().getTime());
                                }}>Seleziona tutti</Button>
                                <Button variant="contained" style={{background: "red"}} onClick={() => {
                                    products.forEach(p => p.checked = false);
                                    setTimestamp(new Date().getTime());
                                }}>Deseleziona tutti</Button>
                            </div>
                            <div style={{fontSize: "18px", fontWeight: "bold"}}>Filtri per categoria</div>
                            <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
                                {
                                    categories.map((category, index) => 
                                        <Button variant="contained" key={`CATEGORY_${category.id}`} onClick={() => {
                                            category.apply = !category.apply;
                                            products.filter(i => i.rapid_category_id == category.id).forEach(p => p.checked = category.apply);
                                            setTimestamp(new Date().getTime());
                                        }} style={{background: category.apply ? "red" : PrimaryColor}}>{category.description}</Button>
                                    )
                                }
                            </div>
                        </div>

                        <div style={{display: "flex", gap: "18px", flexDirection: "column", marginTop: "10px"}}>
                            {
                                products
                                .filter( p => ( search ? ( p.description.toLowerCase().trim().includes(search.trim().toLowerCase()) ) : (1 == 1) ) )
                                .map((product, index) => 
                                    <div style={{display: "flex", gap: "15px", alignItems: "center"}} key={`product_${product.id}`}>
                                        <Checkbox checked={product.checked} onChange={(e) => {
                                            
                                            product.checked = e.target.checked;
                                            product.new_price = 0.00;
                                            product.quantity = 0;
                                            setTimestamp(new Date().getTime());

                                        }}/>
                                        <div style={{width: "300px", fontSize: "20px"}}>{product.description}</div>
                                        {
                                            promoType == 1 && product.checked ? 
                                                <div style={{display: "flex", gap: "10px"}}>
                                                    <TextField placeholder="Quantità" InputLabelProps={{ shrink: true }} label="Quantità" value={product.quantity} onChange={(e) => {
                                                        product.quantity = e.target.value;
                                                        setTimestamp(new Date().getTime());
                                                    }}></TextField>
                                                    <TextField placeholder="Nuovo prezzo" InputLabelProps={{ shrink: true }} label="Nuovo prezzo" value={product.new_price} onChange={(e) => {
                                                        product.new_price = e.target.value;
                                                        setTimestamp(new Date().getTime());
                                                    }}></TextField>
                                                </div>
                                            :
                                                <></>
                                        }
                                    </div>
                                )
                            }
                        </div>

                    </div>

                    {
                        // Salvataggio
                    }
                    <Button variant="contained" className="save-button" onClick={async () => {

                        // Fai tutti i controlli

                        if(!code){
                            alert("Compilare codice");
                            return;
                        }

                        if(!description){
                            alert("Compilare descrizione");
                            return;
                        }

                        if(!validFrom){
                            alert("Compilare data inizio validità");
                            return;
                        }

                        if(!validTo){
                            alert("Compilare data fine validità");
                            return;
                        }

                        if(new Date(validFrom) > new Date(validTo)){
                            alert("La data di termine validità non può essere precedente a quella di inizio validità");
                            return;
                        }

                        if(promoType == 0){
                            if(!newValue && !discount){
                                alert("Indicare nuovo valore o sconto");
                                return;
                            }
                            if(!minQta){
                                alert("Indicare quantità minima per scatto promo");
                                return;
                            }
                            if(newValue && isNaN(newValue.replace(",", "."))){
                                alert("Il campo nuovo valore deve essere numerico");
                                return;
                            }
                            if(discount && isNaN(discount.replace(",", "."))){
                                alert("Il campo di sconto percentuale deve essere numerico");
                                return;
                            }
                            if(minQta && isNaN(minQta.replace(",", "."))){
                                alert("Il campo quantità minima deve essere numerico");
                                return;
                            }
                        }

                        if(products.findIndex(p => p.checked) == -1){
                            alert("Indicare almeno un prodotto che partecipa alla promo");
                            return;
                        }

                        if(promoType == 1){
                            let inErr = false;
                            products.filter(i => i.checked).forEach(p => {
                                if(!p.new_price || !p.quantity){
                                    inErr = true;
                                }
                                if(p.new_price && isNaN(p.new_price.replace(",", "."))){
                                    inErr = true;
                                }
                                if(p.quantity && isNaN(p.quantity.replace(",", "."))){
                                    inErr = true;
                                }
                            });
                            if(inErr){
                                alert("Compilare correttamente i prodotti in termini di prezzo e quantità");
                                return;
                            }
                        }

                        if(ccs.findIndex(i => i.checked) == -1){
                            alert("Compilare almeno un centro di costo");
                            return;
                        }

                        const valProductsIds = [];
                        const setProducts = [];

                        if(promoType == 0){
                            products.filter(p => p.checked).forEach(p => valProductsIds.push(p.id));
                        }else{
                            products.filter(p => p.checked).forEach(p => {
                                setProducts.push(
                                    {
                                        id          : p.id,
                                        quantity    : parseFloat( p.quantity.replace(",", ".") ),
                                        newPrice    : parseFloat( p.new_price.replace(",", ".") )
                                    }
                                );    
                            });
                        }

                        let ccs_ = [];
                        ccs.filter(c => c.checked).forEach(c => ccs_.push(c.id));

                        // Crea json promo
                        const composedPromoJson = {
                            "code"                  : code,
                            "description"           : description,
                            "type"                  : promoType,
                            "newValue"              : newValue ? parseFloat( newValue.replace(",", ".") ) : 0.00,
                            "minQuantity"           : minQta ? parseInt( minQta.replace(",", ".") ) : 0,
                            "percentageDiscountVal" : discount ? parseFloat( discount.replace(",", ".") ) : 0.00,
                            "valProductsIds"        : valProductsIds,
                            "setProducts"           : setProducts,
                            "startFrom"             : validFrom,
                            "endTo"                 : validTo,
                            "center_costs"          : ccs_,
                            "repeatOnce"            : repeatOnce,
                            "manualPromo"           : manualPromo
                        };

                        // Crea promo
                        try{

                            const response = 
                                await fetch(
                                    `${Endpoint}/api/mb/v1/promo/createv2`,
                                    {
                                        method: "POST",
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'jwt': localStorage.getItem('jwt')
                                        },
                                        body: JSON.stringify(
                                            {
                                                id: id,
                                                payload: JSON.stringify(composedPromoJson)
                                            }
                                        )
                                    }
                                );

                            if(response.status == 201){
                                const json = await response.json();
                                if(json.Result == "OK"){
                                    alert("Promo creata con successo");
                                    cleanAll();
                                    return;
                                }
                            }

                        }catch(_){}

                        alert("Errore durante la creazione della promo");

                    }} id="btn_save">SALVA</Button>

                </div>

            </MbLayout>

            

        </>
    );
};

export default MbPromoV2;