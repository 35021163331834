import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MbLogin } from './mb_pages/mb_login';
import './global_style.css';
import store from './mb_redux/mb_redux_store';
import { Provider } from 'react-redux';
import { PrimaryColor, setWhiteLabel } from './mb_constants';
import { MbSkeleton } from './mb_components/mb_skeleton';
import MbDoc from './mb_doc/mb_doc';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MbPromoV2 from './mb_pages/mb_promov2';

const MbDashboard             = React.lazy(() => import('./mb_pages/mb_dashboard'));
const MbMyEnterprise          = React.lazy(() => import('./mb_pages/mb_myenterprise'));
const MbVat                   = React.lazy(() => import('./mb_pages/mb_vat'));
const MbMyEnterpriseSede      = React.lazy(() => import('./mb_pages/mb_enterprise_sede'));
const MbPayment               = React.lazy(() => import('./mb_pages/mb_payment'));
const MbListPrice             = React.lazy(() => import('./mb_pages/mb_list_price'));
const MbUm                    = React.lazy(() => import('./mb_pages/mb_um'));
const MbCategory              = React.lazy(() => import('./mb_pages/mb_category'));
const MbStorage               = React.lazy(() => import('./mb_pages/mb_storage'));
const MbCausal                = React.lazy(() => import('./mb_pages/mb_causal'));
const MbAgent                 = React.lazy(() => import('./mb_pages/mb_agent'));
const MbVector                = React.lazy(() => import('./mb_pages/mb_vector'));
const MbCustomer              = React.lazy(() => import('./mb_pages/mb_customer'));
const MbSupplier              = React.lazy(() => import('./mb_pages/mb_supplier'));
const MbDestinations          = React.lazy(() => import('./mb_pages/mb_destinations'));
const MbSettings              = React.lazy(() => import('./mb_pages/mb_settings'));
const MbVariant               = React.lazy(() => import('./mb_pages/mb_variant'));
const MbAttribute             = React.lazy(() => import('./mb_pages/mb_attribute'));
const MbTipology              = React.lazy(() => import('./mb_pages/mb_tipology'));
const MbProduct               = React.lazy(() => import('./mb_pages/mb_product'));
const MbRestRoom              = React.lazy(() => import('./mb_pages/mb_rest_room'));
const MbRestTable             = React.lazy(() => import('./mb_pages/mb_rest_table'));
const MbProductionDepartment  = React.lazy(() => import('./mb_pages/mb_production_department'));
const MbOperator              = React.lazy(() => import('./mb_pages/mb_operator'));
//const MbDoc                   = React.lazy(() => import('./mb_doc/mb_doc'));
const MbDocList               = React.lazy(() => import('./mb_pages/mb_doc_list'));
const MbDevice                = React.lazy(() => import('./mb_pages/mb_device'));
const MbProd_Stats            = React.lazy(() => import('./mb_stats/mb_product_stats'));
const MbDeviceSetting         = React.lazy(() => import('./mb_pages/mb_device_setting'));
const MbTreeEnterprise        = React.lazy(() => import('./mb_pages/mb_tree_enterprise'));
const MbRenewSubscription     = React.lazy(() => import('./mb_pages/mb_renew_subscription'));
const MbWalletTransactions    = React.lazy(() => import('./mb_pages/mb_wallet_transactions'));
const MbProfile               = React.lazy(() => import('./mb_pages/mb_profile'));
const MbSubUser               = React.lazy(() => import('./mb_pages/mb_subuser'));
const MbBeBRoom               = React.lazy(() => import('./mb_pages/mb_beb_room'));
const MbBebRoomPrenotation    = React.lazy(() => import('./mb_pages/mb_prenotation_beb_room'));
const MbPrenotationTable      = React.lazy(() => import('./mb_pages/mb_prenotation_table'));
const MbSlotService           = React.lazy(() => import('./mb_pages/mb_slot_service'));
const MbSelfOrderApp          = React.lazy(() => import('./mb_pages/mb_self_order_app'));
const MbMarket                = React.lazy(() => import('./mb_pages/mb_market'));
const MbAllMarkets            = React.lazy(() => import('./mb_pages/mb_all_markets'));
const MbCloudPrinter          = React.lazy(() => import('./mb_pages/mb_cloud_printer'));
const MbEasyAppear            = React.lazy(() => import('./mb_pages/mb_easyappear'));
const MbCostCenter            = React.lazy(() => import('./mb_pages/mb_cost_center'));
const MbDiscountRules         = React.lazy(() => import('./mb_pages/mb_discount_rules'));
const MbOrderProducts         = React.lazy(() => import('./mb_pages/mb_order_product'));
const MbOrderCategories       = React.lazy(() => import('./mb_pages/mb_order_category'));
const MbPaymentSummary        = React.lazy(() => import('./mb_pages/mb_payment_summary'));
const MbSuperVisorKeys        = React.lazy(() => import('./mb_pages/mb_supervisor_keys'));
const MbProfileV2             = React.lazy(() => import('./mb_pages/mb_profilev2'));
const MbSubUsersV2            = React.lazy(() => import('./mb_pages/mb_subusersv2'));
const MbCommandLauncher       = React.lazy(() => import('./mb_pages/mb_command_launcher'));
const MbPromo                 = React.lazy(() => import('./mb_pages/mb_promo'));

const root = ReactDOM.createRoot(document.getElementById('root'));

window.addEventListener('resize', () => {
  dispatchEvent(new CustomEvent('resize_event', {}));
});

setWhiteLabel();
//ws.init_socket();

setTimeout(() => {

  root.render(
    <ThemeProvider theme={  createTheme({
      palette: {
        primary: {
          main: PrimaryColor
        }
      }
    })}>
      <Provider store={store}>
        <BrowserRouter basename={window.location.pathname}>
          <Routes>
            <Route path='/' element={<MbLogin/>}></Route>
            <Route path='/dashboard' element={<Suspense fallback={<MbSkeleton module_name={"Dashboard"}/>}><MbDashboard/></Suspense>}></Route>
            <Route path='/my_enterprise' element={<Suspense fallback={<MbSkeleton module_name={"La mia azienda"}/>}><MbMyEnterprise/></Suspense>}></Route>
            <Route path='/office_enterprise' element={<Suspense fallback={<MbSkeleton module_name={"Sedi azienda"}/>}><MbMyEnterpriseSede/></Suspense>}></Route>
            <Route path='/vat' element={<Suspense fallback={<MbSkeleton module_name={"Aliquote"}/>}><MbVat/></Suspense>}></Route>
            <Route path='/payment' element={<Suspense fallback={<MbSkeleton module_name={"Pagamenti"}/>}> <MbPayment/> </Suspense>}></Route>
            <Route path='/list_price' element={<Suspense fallback={<MbSkeleton module_name={"Listini"}/>}> <MbListPrice/> </Suspense>}></Route>
            <Route path='/um' element={<Suspense fallback={<MbSkeleton module_name={"Unità di misura"}/>}> <MbUm/> </Suspense>}></Route>
            <Route path='/category/:id_parent_category' element={<Suspense fallback={<MbSkeleton module_name={"Categorie"}/>}> <MbCategory/> </Suspense>}></Route>
            <Route path='/storage' element={<Suspense fallback={<MbSkeleton module_name={"Depositi"}/>}> <MbStorage/> </Suspense>}></Route>
            <Route path='/causal' element={<Suspense fallback={<MbSkeleton module_name={"Causali"}/>}> <MbCausal/> </Suspense>}></Route>
            <Route path='/agent' element={<Suspense fallback={<MbSkeleton module_name={"Agenti"}/>}> <MbAgent/> </Suspense>}></Route>
            <Route path='/vector' element={<Suspense fallback={<MbSkeleton module_name={"Vettori"}/>}> <MbVector/> </Suspense>}></Route>
            <Route path='/customer' element={<Suspense fallback={<MbSkeleton module_name={"Clienti"}/>}> <MbCustomer/> </Suspense>}></Route>
            <Route path='/supplier' element={<Suspense fallback={<MbSkeleton module_name={"Fornitori"}/>}> <MbSupplier/> </Suspense>}></Route>
            <Route path='/destinations/:id_customer_supplier' element={<Suspense fallback={<MbSkeleton module_name={"Destinazioni"}/>}> <MbDestinations/> </Suspense>}></Route>
            <Route path='/setting' element={<Suspense fallback={<MbSkeleton module_name={"Impostazioni"}/>}> <MbSettings/> </Suspense>}></Route>
            <Route path='/variant' element={<Suspense fallback={<MbSkeleton module_name={"Varianti"}/>}> <MbVariant/> </Suspense>}></Route>
            <Route path='/attribute' element={<Suspense fallback={<MbSkeleton module_name={"Attributi"}/>}> <MbAttribute/> </Suspense>}></Route>
            <Route path='/tipology' element={<Suspense fallback={<MbSkeleton module_name={"Tipologia"}/>}> <MbTipology/> </Suspense>}></Route>
            <Route path='/product' element={<Suspense fallback={<MbSkeleton module_name={"Prodotti"}/>}> <MbProduct/> </Suspense>}></Route>
            <Route path='/department' element={<Suspense fallback={<MbSkeleton module_name={"Prodotti"}/>}> <MbProduct/> </Suspense>}></Route>
            <Route path='/menu' element={<Suspense fallback={<MbSkeleton module_name={"Prodotti"}/>}> <MbProduct/> </Suspense>}></Route>
            <Route path='/rest_room' element={<Suspense fallback={<MbSkeleton module_name={"Sale"}/>}> <MbRestRoom/> </Suspense>}></Route>
            <Route path='/rest_table' element={<Suspense fallback={<MbSkeleton module_name={"Tavoli"}/>}> <MbRestTable/> </Suspense>}></Route>
            <Route path='/department_production' element={<Suspense fallback={<MbSkeleton module_name={"Reparti di produzione"}/>}> <MbProductionDepartment/> </Suspense>}></Route>
            <Route path='/operator' element={<Suspense fallback={<MbSkeleton module_name={"Operatori"}/>}> <MbOperator/> </Suspense>}></Route>
            <Route path='/new_doc' element={<Suspense fallback={<MbSkeleton module_name={"Nuovo documento"}/>}> <MbDoc/> </Suspense>}></Route>
            <Route path='/doc_list/:doc_type' element={<Suspense fallback={<MbSkeleton module_name={"Documento"}/>}> <MbDocList/> </Suspense>}></Route>
            <Route path='/device' element={<Suspense fallback={<MbSkeleton module_name={"Dispositivo"}/>}> <MbDevice/> </Suspense>}></Route>
            <Route path='/product_stats/:id_product' element={<Suspense fallback={<MbSkeleton module_name={"Statistiche prodotto"}/>}> <MbProd_Stats/> </Suspense>}></Route>
            <Route path='/device_setting' element={<Suspense fallback={<MbSkeleton module_name={"Impostazioni dispositivo"}/>}> <MbDeviceSetting/> </Suspense>}></Route>
            <Route path='/tree_enterprise' element={<Suspense fallback={<MbSkeleton module_name={"Aziende"}/>}> <MbTreeEnterprise/> </Suspense>}></Route>
            <Route path='/renew_subscription' element={<Suspense fallback={<MbSkeleton module_name={"Rinnovo sottoscrizione"}/>}> <MbRenewSubscription/> </Suspense>}></Route>
            <Route path='/wallet_transactions' element={<Suspense fallback={<MbSkeleton module_name={"Transazioni"}/>}><MbWalletTransactions/></Suspense>}></Route>
            <Route path='/profile' element={<Suspense fallback={<MbSkeleton module_name={"Profili"}/>}><MbProfile/></Suspense>}></Route>
            <Route path='/subuser' element={<Suspense fallback={<MbSkeleton module_name={"Sub utenti"}/>}><MbSubUser/></Suspense>}></Route>
            <Route path='/beb_room' element={<Suspense fallback={<MbSkeleton module_name={"B&B"}/>}><MbBeBRoom/></Suspense>}></Route>
            <Route path='/beb_room_prenotation' element={<Suspense fallback={<MbSkeleton module_name={"Planning B&B"}/>}><MbBebRoomPrenotation/></Suspense>}></Route>
            <Route path='/rest_table_prenotation' element={<Suspense fallback={<MbSkeleton module_name={"Planning Tavoli"}/>}><MbPrenotationTable/></Suspense>}></Route>
            <Route path='/slot_event' element={<Suspense fallback={<MbSkeleton module_name={"Eventi"}/>}><MbSlotService/></Suspense>}></Route>
            <Route path='/app_self_order' element={<Suspense fallback={<MbSkeleton module_name={"App ordini"}/>}><MbSelfOrderApp/></Suspense>}></Route>
            <Route path='/market' element={<Suspense fallback={<MbSkeleton module_name={"Market"}/>}><MbMarket/></Suspense>}></Route>
            <Route path='/all_markets' element={<Suspense fallback={<MbSkeleton module_name={"Tutti i markets"}/>}><MbAllMarkets/></Suspense>}></Route>
            <Route path='/cloud_printer' element={<Suspense fallback={<MbSkeleton module_name={"Stampante in cloud"}/>}><MbCloudPrinter/></Suspense>}></Route>
            <Route path='/easyappear' element={<Suspense fallback={<MbSkeleton module_name={"EasyAppear"}/>}><MbEasyAppear/></Suspense>}></Route>
            <Route path='/cost_center' element={<Suspense fallback={<MbSkeleton module_name={"Centro di costo"}/>}><MbCostCenter/></Suspense>}></Route>
            <Route path='/discount_rules' element={<Suspense fallback={<MbSkeleton module_name={"Regole di sconto"}/>}><MbDiscountRules/></Suspense>}></Route>
            <Route path='/order_products' element={<Suspense fallback={<MbSkeleton module_name={"Ordinamento prodotti"}/>}><MbOrderProducts/></Suspense>}></Route>
            <Route path='/order_categories' element={<Suspense fallback={<MbSkeleton module_name={"Ordinamento categorie"}/>}><MbOrderCategories/></Suspense>}></Route>
            <Route path='/summary_payments' element={<Suspense fallback={<MbSkeleton module_name={"Riepilogo pagamenti"}/>}><MbPaymentSummary/></Suspense>}></Route>
            <Route path='/supervisor_keys' element={<Suspense fallback={<MbSkeleton module_name={"Chiavi supervisore"}/>}><MbSuperVisorKeys/></Suspense>}></Route>
            <Route path='/profiles_v2' element={<Suspense fallback={<MbSkeleton module_name={"Profile"}/>}><MbProfileV2/></Suspense>}></Route>
            <Route path='/subusers_v2' element={<Suspense fallback={<MbSkeleton module_name={"Profile"}/>}><MbSubUsersV2/></Suspense>}></Route>
            <Route path='/cloud_command' element={<Suspense fallback={<MbSkeleton module_name={"Comandi cloud"}/>}><MbCommandLauncher/></Suspense>}></Route>
            <Route path='/promo' element={<Suspense fallback={<MbSkeleton module_name={"Promo"}/>}><MbPromoV2/></Suspense>}></Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
  
}, 1000);

//reportWebVitals();
