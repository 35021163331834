import { useEffect, useState } from "react";
import { MbDocContainer, MbDocGeneralField, MbDocTitle } from "./mb_doc_components";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import { MbValidatorCausal } from "../mb_validator/mb_validator_causal";
import { Endpoint, PrimaryColor, RemoveHash } from "../mb_constants";
import { MbValidatorOperator } from "../mb_validator/mb_validator_operator";
import { MbValidatorPayment } from "../mb_validator/mb_validator_payment";
import { MbValidatorCustomerSupplier } from "../mb_validator/mb_validator_customer_supplier";
import { MbApi_CausalGetByCode } from "../mb_api/mb_api_causal";
import { MbApi_OperatorGetByCode } from "../mb_api/mb_api_operator";
import { MbApi_PaymentGetByCode } from "../mb_api/mb_api_payment";
import { MbApi_CustomerSupplierGetByCode } from "../mb_api/mb_api_customer_supplier";
import { MbValidatorDestinations } from "../mb_validator/mb_validator_destinations";
import { MbApi_DestinationsGetByCode } from "../mb_api/mb_api_destinations";
import { MbValidatorAgent } from "../mb_validator/mb_validator_agent";
import { MbApi_AgentGetByCode } from "../mb_api/mb_api_agent";
import { MbValidatorVector } from "../mb_validator/mb_validator_vector";
import { MbApi_VectorGetByCode } from "../mb_api/mb_api_vector";
import { MbValidatorRestTable } from "../mb_validator/mb_validator_rest_table";
import { MbApi_RestTableGetByCode } from "../mb_api/mb_api_rest_table";
import { MbValidatorProductionDepartment } from "../mb_validator/mb_validator_production_department";
import { MbApi_ProductionDepartmentGetByCode } from "../mb_api/mb_api_production_department";
import { MbDocHead_Dto, MbDocHead_New } from "./mb_doc_model";
import { MbValidatorDevice } from "../mb_validator/mb_validator_device";
import { MbApi_DeviceGetByCode } from "../mb_api/mb_api_device";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { Button } from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import { MbConfirm, MbMessage } from "../mb_components/mb_message";

const MbDocHead = () => {

    const [displayBackdrop, setDisplayBackdrop]         = useState(null);
    const [causalObj, setCausalObj]                     = useState(null);
    const [operatorObj, setOperatorObj]                 = useState(null);
    const [paymentObj, setPaymentObj]                   = useState(null);
    const [_paymentObj, _setPaymentObj]                 = useState(null);
    const [customerSupplierObj, setCustomerSupplierObj] = useState(null);
    const [destinationObj, setDestinationObj]           = useState(null);
    const [agentObj, setAgentObj]                       = useState(null);
    const [vectorObj, setVectorObj]                     = useState(null);
    const [tableObj, setTableObj]                       = useState(null);
    const [depPrdObj, setDepPrdObj]                     = useState(null);
    const [deviceObj, setDeviceObj]                     = useState(null);
    const [isOpenedDoc, setIsOpenedDoc]                 = useState(false);
    const [idDoc, setIdDoc]                             = useState(null);
    const [docNum, setDocNum]                           = useState(null);
    const [trxUniqueCode, setTrxUniqueCode]             = useState(null);

    const cleanDoc = () => {
        
        MbDocHead_New();
        setIsOpenedDoc(false);
        setCausalObj(null);
        setOperatorObj(null);
        setPaymentObj(null);
        _setPaymentObj(null);
        setCustomerSupplierObj(null);
        setDestinationObj(null);
        setAgentObj(null);
        setVectorObj(null);
        setTableObj(null);
        setDepPrdObj(null);
        setDeviceObj(null);

        const date = new Date();
        const today = date.toISOString('en-EN').split("T")[0];
        MbDocHead_Dto.doc_date = today;

    };

    const openDoc = (e) => {
        
        const doc = JSON.parse(e.detail);
        setDisplayBackdrop(true);

        if(doc.causal)
            setCausalObj(doc.causal);

        setTimeout(() => {

            if(doc.operator)
                setOperatorObj(doc.operator);

            if(doc.payment)
                setPaymentObj(doc.payment);

            if(doc.second_payment)
                _setPaymentObj(doc.second_payment);

            if(doc.customer_supplier)
                setCustomerSupplierObj(doc.customer_supplier);

            if(doc.destination)
                setDestinationObj(doc.destination);

            if(doc.agent)
                setAgentObj(doc.agent);

            if(doc.vector)
                setVectorObj(doc.vector);

            if(doc.table)
                setTableObj(doc.table);
            
            if(doc.department_production)
                setDepPrdObj(doc.department_production);
            
            if(doc.device)
                setDeviceObj(doc.device);

            if(doc.id)
                setIdDoc(doc.id);

            if(doc.doc_number)
                setDocNum(doc.doc_number);

            if(doc.transaction_unique_code){
                setTrxUniqueCode(doc.transaction_unique_code);
            }

            if(doc.doc_date){
                MbDocHead_Dto.doc_date = doc.doc_date;
            }

            MbDocHead_Dto.receipt_n_close = doc.receipt_n_close ?? "";
            MbDocHead_Dto.receipt_n_doc = doc.receipt_n_doc ?? "";
            MbDocHead_Dto.ade_progressive = doc.ade_progressive ?? "";
            MbDocHead_Dto.ade_id_transaction = doc.ade_id_transaction ?? "";
            MbDocHead_Dto.discount = doc.discount ?? "";
            MbDocHead_Dto.transaction_unique_code = doc.transaction_unique_code ?? "";

            setTimeout(() => {
                setDisplayBackdrop(false);
                setIsOpenedDoc(true);
            }, 1000);

        }, 500);

    };

    useEffect(() => {

        MbDocHead_Dto.causal                = causalObj;
        MbDocHead_Dto.operator              = operatorObj;
        MbDocHead_Dto.payment               = paymentObj;
        MbDocHead_Dto.second_payment        = _paymentObj;
        MbDocHead_Dto.customer_supplier     = customerSupplierObj;
        MbDocHead_Dto.destination           = destinationObj;
        MbDocHead_Dto.agent                 = agentObj;
        MbDocHead_Dto.vector                = vectorObj;
        MbDocHead_Dto.table                 = tableObj;
        MbDocHead_Dto.department_production = depPrdObj;
        MbDocHead_Dto.device                = deviceObj;

        dispatchEvent(new CustomEvent('update_doc_deadlines'));

    }, [causalObj, operatorObj, paymentObj, customerSupplierObj, destinationObj, agentObj, tableObj, depPrdObj, deviceObj, _paymentObj]);

    // Validatore
    const [validatorCausal, setValidatorCausal]                     = useState(false);
    const [validatorOperator, setValidatorOperator]                 = useState(false);
    const [validatorPayment, setValidatorPayment]                   = useState(false);
    const [_validatorPayment, _setValidatorPayment]                 = useState(false);
    const [validatorCustomerSupplier, setValidatorCustomerSupplier] = useState(false);
    const [validatorDestinations, setValidatorDestinations]         = useState(false);
    const [validatorAgent, setValidatorAgent]                       = useState(false);
    const [validatorVector, setValidatorVector]                     = useState(false);
    const [validatorTable, setValidatorTable]                       = useState(false);
    const [validatorDepPrd, setValidatorDepPrd]                     = useState(false);
    const [validatorDevice, setValidatorDevice]                     = useState(false);

    function eHashChange(){ 
        setValidatorCausal(window.location.hash.includes('#causal') && !validatorCausal);
        setValidatorOperator(window.location.hash.includes('#operator') && !validatorOperator);
        setValidatorPayment(window.location.hash == '#payment' && !validatorPayment);
        _setValidatorPayment(window.location.hash == '#2payment' && !_validatorPayment);
        setValidatorCustomerSupplier(window.location.hash.includes('#customer_supplier') && !validatorCustomerSupplier);
        setValidatorDestinations(window.location.hash.includes('#destinations') && !validatorDestinations);
        setValidatorAgent(window.location.hash.includes('#agent') && !validatorAgent);
        setValidatorVector(window.location.hash.includes('#vector') && !validatorVector);
        setValidatorTable(window.location.hash.includes('#rest_table') && !validatorTable);
        setValidatorDepPrd(window.location.hash.includes('#department_production') && !validatorDepPrd);
        setValidatorDevice(window.location.hash.includes('#device') && !validatorDevice);
    }

    useEffect(() => {

        window.addEventListener('open_doc', openDoc);

        window.addEventListener('hashchange', eHashChange);

        window.addEventListener('clean_doc', cleanDoc);

        // Imposta focus su causale
        document.querySelector('#input_causal_code').focus();

        return (() => {

            try{
                window.removeEventListener('hashchange', eHashChange);
                window.removeEventListener('clean_doc', cleanDoc);
                window.removeEventListener('open_doc', openDoc);
            }catch(_){}

        });

    }, []);

    return (
        <>

            <MbBackdrop display={displayBackdrop}/>

            {   
                window.innerWidth >= 1000 ? 
                    <></>
                :
                    <div style={{height: "20px"}}/>
            }  

            <MbDocContainer>
                <MbDocGeneral 
                    idOperator={operatorObj ? operatorObj.id : null} operatorCode={operatorObj ? operatorObj.code : null} operatorDescription={operatorObj ? operatorObj.description : null}
                    idCausal={causalObj ? causalObj.id : null} causalCode={causalObj ? causalObj.code : null} causalDescription={causalObj ? causalObj.description : null}
                    idPayment={paymentObj ? paymentObj.id : null} paymentCode={paymentObj ? paymentObj.code : null} paymentDescription={paymentObj ? paymentObj.description : null}
                    idPayment2={_paymentObj ? _paymentObj.id : null} paymentCode2={_paymentObj ? _paymentObj.code : null} paymentDescription2={_paymentObj ? _paymentObj.description : null}
                    idDevice={deviceObj ? deviceObj.id : null} deviceCode={deviceObj ? deviceObj.code : null} deviceDescription={deviceObj ? deviceObj.description : null}
                    isOpenedDoc={isOpenedDoc} docId={idDoc} docNumber={docNum} transactionUniqueCode={trxUniqueCode}
                    setCausalObj={(obj) => {
                        if(!obj){
                            setCustomerSupplierObj(null);
                        }
                        setCausalObj(obj);
                    }}
                    setOperatorObj={(obj) => {
                        setOperatorObj(obj);
                    }}
                    setDocData={(value) => {
                        dispatchEvent(new CustomEvent('update_doc_deadlines'));
                    }}
                    setPaymentObj={(value) => {
                        setPaymentObj(value);
                    }}
                    setDeviceObj={(value) => {
                        setDeviceObj(value);
                    }}
                    setPaymentObj2={(value) => {
                        _setPaymentObj(value);
                    }}
                />
                {/*
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                */}
                <MbDocCustomerSupplier 
                    causalObj={causalObj} 
                    id={customerSupplierObj ? customerSupplierObj.id : null} code={customerSupplierObj ? customerSupplierObj.code : null} description={customerSupplierObj ? customerSupplierObj.description : null}
                    business_name={customerSupplierObj ? customerSupplierObj.business_name : null} fiscal_code={customerSupplierObj ? customerSupplierObj.fiscal_code : null}
                    lottery_code={customerSupplierObj ? customerSupplierObj.lottery_code : null} sdi_code={customerSupplierObj ? customerSupplierObj.sdi : null} 
                    vat_number={customerSupplierObj ? customerSupplierObj.vat_number : null} pec={customerSupplierObj ? customerSupplierObj.pec : null}
                    isOpenedDoc={isOpenedDoc}
                    setCustomerObj={(value, clean_lines) => { 
                        if(!clean_lines || (clean_lines && clean_lines !== 'not_clean_lines')){
                            dispatchEvent(new CustomEvent('clean_lines_doc'));
                        }
                        setVectorObj(null);
                        setAgentObj(null);
                        setDestinationObj(null);
                        if(value){

                            if(value.vector){
                                setVectorObj(value.vector);
                            }
                            if(value.payment){
                                setPaymentObj(value.payment);
                            }
                            if(value.agent){
                                setAgentObj(value.agent);
                            }
                            if(value.destinations && value.destinations.length > 0){
                                setDestinationObj(value.destinations[0]);
                            }else{
                                setDestinationObj(null);
                            }

                        }
                        setCustomerSupplierObj(value);
                    }}  
                />
            </MbDocContainer>

            {/*<div style={{height: "20px"}}/>*/}

            {/*<MbDocContainer>
                <MbDocCustomerSupplierDestination 
                    id={destinationObj ? destinationObj.id : null} code={destinationObj ? destinationObj.code : null} description={destinationObj ? destinationObj.description : null}
                    address={destinationObj ? destinationObj.address : null} collective={destinationObj ? destinationObj.collective : null} country_code={destinationObj ? destinationObj.country_code : null}
                    house_number={destinationObj ? destinationObj.house_number : null} province={destinationObj ? destinationObj.province : null} zip_code={destinationObj ? destinationObj.zip_code : null}
                    objCustomerSupplier={customerSupplierObj}
                    isOpenedDoc={isOpenedDoc}
                    setCustomerSupplierDestinations={(value) => {
                        setDestinationObj(value);
                    }}    
                />*/}
                {/*
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                */}  
                {/*<MbDocVector
                    id={vectorObj ? vectorObj.id : null} business_name={vectorObj ? vectorObj.business_name : null} code={vectorObj ? vectorObj.code : null}
                    description={vectorObj ? vectorObj.description : null} country_code={vectorObj ? vectorObj.country_code : null} fiscal_code={vectorObj ? vectorObj.fiscal_code : null}
                    vat_number={vectorObj ? vectorObj.vat_number : null}
                    setVectorObj={(value) => {
                        setVectorObj(value);
                    }}
                    objCustomer={customerSupplierObj}
                />*/}
            {/*</MbDocContainer>*/}

            <div style={{height: "20px"}}/>

            {/*<MbDocContainer>
                <MbDocAgent
                    id={agentObj ? agentObj.id : null} code={agentObj ? agentObj.code : null} description={agentObj ? agentObj.description : null}
                    commission={agentObj ? agentObj.commission : null} vat_number={agentObj ? agentObj.vat_number : null} fiscal_code={agentObj ? agentObj.fiscal_code : null}
                    name={agentObj ? agentObj.name : null}
                    setAgentObj={(value) => {
                        setAgentObj(value);
                    }}
                />
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocTable
                    tableId={tableObj ? tableObj.id : null}  table_code={tableObj ? tableObj.code : null} table_description={tableObj ? tableObj.description : null}
                    prodId={depPrdObj ? depPrdObj.id : null} prod_code={depPrdObj ? depPrdObj.code : null} prod_description={depPrdObj ? depPrdObj.description : null}
                    setTableObj={(value) => {
                        setTableObj(value);
                    }}
                    setPrdDepObj={(value) => {
                        setDepPrdObj(value);
                    }}
                />
            </MbDocContainer>*/}

            {
                // Validatore causale
                
                validatorCausal ? 
                    <MbValidatorCausal
                        callbackFromValidator={(value) => {
                            setCausalObj(value[value.length - 1]);
                            RemoveHash();
                            setTimeout(() => {
                                document.querySelector('#input_customer_supplier_code').focus();
                            }, 100);
                        }}
                    />
                :
                    <></>
            }

            {
                // Validatore operatore
                
                validatorOperator ? 
                    <MbValidatorOperator
                        callbackFromValidator={(value) => {
                            setOperatorObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>
            }

            {
                
                // Validatore pagamento 

                validatorPayment ? 
                    <MbValidatorPayment
                        callbackFromValidator={(value) => {
                            setPaymentObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>

            }

            {
                
                // Validatore pagamento 2

                _validatorPayment ? 
                    <MbValidatorPayment
                        callbackFromValidator={(value) => {
                            _setPaymentObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>

            }

            {
                
                // Validatore device 

                validatorDevice ? 
                    <MbValidatorDevice
                        callbackFromValidator={(value) => {
                            setDeviceObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>

            }

            {
                
                // Validatore cliente / fornitore 
                
                validatorCustomerSupplier ? 
                    <MbValidatorCustomerSupplier
                        entity_type={causalObj.propose_for}
                        callbackFromValidator={(value) => {
                            const obj = value[value.length - 1];
                            setCustomerSupplierObj(obj);
                            if(obj.payment){
                                setPaymentObj(obj.payment);
                            }
                            if(obj.vector){
                                setVectorObj(obj.vector);
                            }
                            if(obj.agent){
                                setAgentObj(obj.agent);
                            }
                            if(obj.destinations && obj.destinations.length > 0){
                                setDestinationObj(obj.destinations[0]);
                            }else{
                                setDestinationObj(null);
                            }
                            RemoveHash();
                            setTimeout(() => {
                                document.querySelector('#input_payment_code').focus();
                            }, 100);
                        }}
                    />
                :
                    <></>

            }

            {

                // Validatore destinazioni

                validatorDestinations ? 
                    <MbValidatorDestinations
                        id_customer_supplier={customerSupplierObj.id}
                        callbackFromValidator={(value) => {
                            setDestinationObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>

            }

            {
                
                // Validatore agente

                validatorAgent ? 
                    <MbValidatorAgent
                        callbackFromValidator={(value) => {
                            setAgentObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>
                        
            }

            {

                // Validatore vettore

                validatorVector ? 
                    <MbValidatorVector
                        callbackFromValidator={(value) => {
                            setVectorObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>

            }

            {

                // Validatore tavolo

                validatorTable ? 
                    <MbValidatorRestTable
                        callbackFromValidator={(value) => {
                            setTableObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>

            }

            {


                // Validatore reparto produzione

                validatorDepPrd ? 
                    <MbValidatorProductionDepartment
                        callbackFromValidator={(value) => {
                            setDepPrdObj(value[value.length - 1]);
                            RemoveHash();
                        }}
                    />
                :
                    <></>

            }

        </>
    );
};

// **************************************** //
// *************** GENERALE *************** //
// **************************************** //
const MbDocGeneral = ({dataDoc, noteDoc, idOperator, operatorCode, operatorDescription, idCausal, causalCode, causalDescription, idPayment, paymentCode, paymentDescription, idPayment2, paymentCode2, paymentDescription2, idDevice, deviceCode, deviceDescription, setCausalObj, setOperatorObj, setPaymentObj, setPaymentObj2, setDocData, setDocNote, setDeviceObj, isOpenedDoc, docId, docNumber, transactionUniqueCode}) => {

    const [_dataDoc, setDataDoc]                        = useState(dataDoc);
    const [_noteDoc, setNoteDoc]                        = useState(noteDoc);
    const [_idOperator, setIdOperator]                  = useState(idOperator);
    const [_operatorCode, setOperatorCode]              = useState(operatorCode);
    const [_operatorDesc, setOperatorDesc]              = useState(operatorDescription);
    const [_causalId, setCausalId]                      = useState(idCausal);
    const [_causalCode, setCausalCode]                  = useState(causalCode);
    const [_causalDesc, setCausalDesc]                  = useState(causalDescription);
    const [_paymentId, setPaymentId]                    = useState(idPayment);
    const [_paymentCode, setPaymentCode]                = useState(paymentCode);
    const [_paymentDesc, setPaymentDesc]                = useState(paymentDescription);
    const [__paymentId, _setPaymentId]                  = useState(idPayment2);
    const [__paymentCode, _setPaymentCode]              = useState(paymentCode2);
    const [__paymentDesc, _setPaymentDesc]              = useState(paymentDescription2);
    const [_deviceId, setDeviceId]                      = useState(idDevice);
    const [_deviceCode, setDeviceCode]                  = useState(deviceCode);
    const [_deviceDescription, setDeviceDescription]    = useState(deviceDescription);
    const [render, setRender]                           = useState(false);

    useEffect(() => {
        const date = new Date();
        const today = date.toISOString('en-EN').split("T")[0];
        setDataDoc(today);
        MbDocHead_Dto.doc_date = today;
    }, []);

    useEffect(() => {
        setCausalId(idCausal);
        setCausalCode(causalCode);
        setCausalDesc(causalDescription);
    }, [idCausal]);

    useEffect(() => {
        setIdOperator(idOperator);
        setOperatorCode(operatorCode);
        setOperatorDesc(operatorDescription);
    }, [idOperator]);

    useEffect(() => {
        setPaymentId(idPayment);
        setPaymentCode(paymentCode);
        setPaymentDesc(paymentDescription);
    }, [idPayment]);

    useEffect(() => {
        _setPaymentId(idPayment2);
        _setPaymentCode(paymentCode2);
        _setPaymentDesc(paymentDescription2);
    }, [idPayment2]);

    useEffect(() => {
        setDeviceId(idDevice);
        setDeviceCode(deviceCode);
        setDeviceDescription(deviceDescription);
    }, [idDevice]);

    return (
        <div className="general_card document">
            
            <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px"}}>Dettaglio documento</span>
            {/*<MbDocTitle title={"Generale" + (transactionUniqueCode ? (" [" + transactionUniqueCode + "]") : "")}></MbDocTitle>*/}

            <MbDocContainer style={{marginTop: "20px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between", gap: "20px"}}>
                <MbDocGeneralField isOpenedDoc={isOpenedDoc} id="input_causal_code" label="Codice causale" type="text" value={_causalCode} change={(val) => setCausalCode(val)} search searchClick={(event) => {
                    
                    if(event == 'delete'){
                        setCausalObj(null);
                        return;
                    }

                    window.location.hash += "#causal";
                }} submitEvent={async () => {
                    if(_causalCode){
                        const data = await MbApi_CausalGetByCode(_causalCode);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setCausalId(data.Json.id);
                                setCausalCode(data.Json.code);
                                setCausalDesc(data.Json.description);
                                if(setCausalObj){
                                    setCausalObj(data.Json);
                                    setTimeout(() => {
                                        document.querySelector('#input_customer_supplier_code').focus();
                                    }, 100);
                                }
                            }
                        }
                    }
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }   
                <MbDocGeneralField label="Descrizione causale" type="text" value={_causalDesc} change={(val) => setCausalDesc(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between", gap: "20px"}}>
                <MbDocGeneralField label="N.Doc" value={docNumber} type="text" disabled/>
                {
                    window.innerWidth >= 1000 ?
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }
                <MbDocGeneralField label="Data documento" type="date" value={_dataDoc} change={(val) => {
                    MbDocHead_Dto.doc_date = val;   
                    setDataDoc(val);
                    setDocData(val);
                }}/>
                {/*<MbDocGeneralField label="ID Doc" value={docId} type="text" disabled/>*/}
                {
                    window.innerWidth >= 1000 ?
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }
                <MbDocGeneralField label="% di sconto sul totale" value={MbDocHead_Dto.discount} type="text" change={(val) => MbDocHead_Dto.discount = val }/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between", gap: "20px"}}>
                <MbDocGeneralField label="Codice operatore" isOpenedDoc={isOpenedDoc} type="text" value={_operatorCode} change={(val) => setOperatorCode(val)} search searchClick={(event) => {
                    
                    if(event == 'delete'){
                        setOperatorObj(null);
                        return;
                    }

                    window.location.hash += "#operator";
                }} submitEvent={async () => {
                    if(_operatorCode){
                        const data = await MbApi_OperatorGetByCode(_operatorCode);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setIdOperator(data.Json.id);
                                setOperatorCode(data.Json.code);
                                setOperatorDesc(data.Json.description);
                                if(setOperatorObj){
                                    setOperatorObj(data.Json);
                                }
                            }
                        }
                    }
                }} />
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }   
                <MbDocGeneralField label="Descrizione operatore" type="text" value={_operatorDesc} change={(val) => setOperatorDesc(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between", gap: "20px"}}>
                <MbDocGeneralField isOpenedDoc={isOpenedDoc} id="input_payment_code" label="Codice pagamento" type="text" value={_paymentCode} change={(val) => setPaymentCode(val)} search searchClick={(event) => {
                    if(event == 'delete'){
                        setPaymentObj(null);
                        return;
                    }
                    window.location.hash += "#payment";
                }} submitEvent={async () => {
                    if(_paymentCode){
                        const data = await MbApi_PaymentGetByCode(_paymentCode);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setPaymentObj(data.Json);
                            }
                        }
                    }
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }   
                <MbDocGeneralField label="Descrizione pagamento" type="text" value={_paymentDesc} change={(val) => setPaymentDesc(val)} disabled/>
            </MbDocContainer>

            {/*<MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField isOpenedDoc={isOpenedDoc} label="Codice secondo pagamento" type="text" value={__paymentCode} change={(val) => _setPaymentCode(val)} search searchClick={(event) => {
                    if(event == 'delete'){
                        setPaymentObj2(null);
                        return;
                    }
                    window.location.hash += "#2payment";
                }} submitEvent={async () => {
                    if(__paymentCode){
                        const data = await MbApi_PaymentGetByCode(__paymentCode);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setPaymentObj2(data.Json);
                            }
                        }
                    }
                }} style={{width: window.innerWidth >= 1000 ? "150px" : "100%"}}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }   
                <MbDocGeneralField label="Descrizione secondo pagamento" type="text" value={__paymentDesc} change={(val) => _setPaymentDesc(val)} disabled/>
            </MbDocContainer>*/}

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Nota documento" type="text" value={_noteDoc} change={(val) => {
                    setNoteDoc(val);
                    MbDocHead_Dto.note = val;
                }}/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between", gap: "20px"}}>
                <MbDocGeneralField isOpenedDoc={isOpenedDoc} label="Codice disp." type="text" value={_deviceCode} change={(val) => setDeviceCode(val)} search searchClick={(event) => {
                    if(event == 'delete'){
                        setDeviceObj(null);
                        return;
                    }
                    window.location.hash += "#device";
                }} submitEvent={async () => {
                    if(_deviceCode){
                        const data = await MbApi_DeviceGetByCode(_deviceCode);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setDeviceObj(data.Json);
                            }
                        }
                    }
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }   
                <MbDocGeneralField label="Descrizione dispositivo" type="text" value={_deviceDescription} change={(val) => setPaymentDesc(val)} disabled/>
            </MbDocContainer>

            {/*<MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="N. Chiusura fiscale" type="text" value={MbDocHead_Dto.receipt_n_close} change={(val) => {
                    MbDocHead_Dto.receipt_n_close = val;
                    setRender(!render);
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }   
                <MbDocGeneralField label="N. Documento fiscale" type="text" value={MbDocHead_Dto.receipt_n_doc} change={(val) => {
                    MbDocHead_Dto.receipt_n_doc = val;
                    setRender(!render);
                }}/>
            </MbDocContainer>*/}

            {/*<MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Progressivo documento" type="text" value={MbDocHead_Dto.ade_progressive} change={(val) => {
                    MbDocHead_Dto.ade_progressive = val;
                    setRender(!render);
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }   
                <MbDocGeneralField label="ID transazione" type="text" value={MbDocHead_Dto.ade_id_transaction} change={(val) => {
                    MbDocHead_Dto.ade_id_transaction = val;
                    setRender(!render);
                }}/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <Button variant="contained" style={{width: "100%", background: PrimaryColor}} onClick={() => {
                    window.open(`https://ivaservizi.agenziaentrate.gov.it/ser/api/documenti/v1/doc/documenti/${MbDocHead_Dto.ade_id_transaction}/stampa/?v=8238e00&regalo=false`, '_blank');
                }}><DownloadIcon/>&nbsp;&nbsp;Download PDF</Button>
                <Button variant="contained" style={{width: "100%", background: PrimaryColor}} onClick={() => {
                    window.open(`https://ivaservizi.agenziaentrate.gov.it/ser/api/documenti/v1/doc/documenti/${MbDocHead_Dto.ade_id_transaction}/stampa/?v=8238e00&regalo=true`, '_blank');
                }}><RedeemIcon/>&nbsp;&nbsp;Download Scontrino regalo</Button>
            </MbDocContainer>*/}

        </div>
    );

};

// *************************************************** //
// *************** CLIENTE / FORNITORE *************** //
// *************************************************** //
const MbDocCustomerSupplier = ({id, code, description, business_name, fiscal_code, vat_number, sdi_code, pec, lottery_code, causalObj, setCustomerObj, isOpenedDoc}) => {

    const [_causalObj, setCausalObj]                = useState(null);
    const [_id, setId]                              = useState(id);
    const [_code, setCode]                          = useState(code);
    const [_description, setDescription]            = useState(description);
    const [_business_name, setBusinessName]         = useState(business_name);
    const [_fiscal_code, setFiscalCode]             = useState(fiscal_code);
    const [_vat_number, setVatNumber]               = useState(vat_number);
    const [_lottery_code, setLotteryCode]           = useState(lottery_code);
    const [_sdi, setSdiCode]                        = useState(sdi_code);
    const [_pec, setPec]                            = useState(pec);
    const [displayLoader, setDisplayLoader]         = useState(false);
    const [displayMsgUpdate, setDisplayMsgUpdate]   = useState(false);

    const refreshCustomerData = (e) => {
        setCustomerObj(e.detail, 'not_clean_lines');
        setId(e.detail.id);
        setCode(e.detail.code);
        setDescription(e.detail.description);
        setBusinessName(e.detail.business_name);
        setFiscalCode(e.detail.fiscal_code);
        setVatNumber(e.detail.vat_number);
        setSdiCode(e.detail.sdi);
        setPec(e.detail.pec);
        setLotteryCode(e.detail.lottery_code);
    };

    useEffect(() => {
        window.addEventListener('refresh_customer_data', refreshCustomerData);
        return (() => {
            window.removeEventListener('refresh_customer_data', refreshCustomerData);
        });
    }, []);

    useEffect(() => {
        if(causalObj){
            if(setCustomerObj){
                setCustomerObj(null);
                setId(null);
                setCode('');
                setDescription('');
                setBusinessName('');
                setFiscalCode('');
                setVatNumber('');
                setSdiCode('');
                setPec('');
                setLotteryCode('');
            }
            setCausalObj(causalObj);
        }
    }, [causalObj]);

    useEffect(() => {
        setCode(code);
        setDescription(description);
        setBusinessName(business_name);
        setFiscalCode(fiscal_code);
        setVatNumber(vat_number);
        setSdiCode(sdi_code);
        setPec(pec);
        setLotteryCode(lottery_code);
    }, [id]);

    return (
        <>
            <MbBackdrop display={displayLoader}/>
            <MbConfirm 
                message="Sicuro di voler aggiornare l'anagrafica del cliente?!" 
                open={displayMsgUpdate} 
                confirm={async () => {

                    setDisplayMsgUpdate(false);
                    setDisplayLoader(true);
                    try{
                        const data = await MbApi_CustomerSupplierGetByCode(_code, causalObj.propose_for);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                //setCustomerObj(data.Json);
                                setTimeout(() => {
                                    dispatchEvent(new CustomEvent('refresh_customer_data', {detail: data.Json}));
                                    setDisplayLoader(false);
                                }, 1000);
                                return;
                            }
                        }
                    }catch(_){}
                    setDisplayLoader(false);

                }}
                close={() =>{
                    setDisplayMsgUpdate(false);
                }}
            />
            <div className="general_card document">
                
                <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                    <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px"}}> Cliente / fornitore documento </span>
                    {/*<MbDocTitle title={
                        !causalObj ? "Cliente / Fornitore" : (causalObj.propose_for == 'customer' ? "Cliente" : "Fornitore")
                    }></MbDocTitle>*/}
                    {/*
                        isOpenedDoc ?
                            <Button variant="contained" style={{background: PrimaryColor}} onClick={async () => {
                                setDisplayMsgUpdate(true);
                            }}>AGGIORNA &nbsp;&nbsp;<CachedIcon style={{color: "white", fontSize: "30px"}}/></Button>
                        :
                            <></>
                    */}
                </div>

                <MbDocContainer style={{marginTop: "20px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between", gap: "20px"}}>
                    <MbDocGeneralField id="input_customer_supplier_code" isOpenedDoc={isOpenedDoc} disabled={!causalObj} label="Codice" type="text" value={_code} change={(val) => setCode(val)} search searchClick={(event) => {
                        
                        if(event == 'delete'){
                            setCustomerObj(null);
                            return;
                        }

                        if(causalObj){
                            window.location.hash += "#customer_supplier";
                        }

                    }} submitEvent={async () => {
                        if(_code){
                            const data = await MbApi_CustomerSupplierGetByCode(_code, causalObj.propose_for);
                            if(data){
                                if(data.Result == "OK" && data.Json){
                                    setCustomerObj(data.Json);
                                    setTimeout(() => {
                                        document.querySelector('#input_payment_code').focus();
                                    }, 100);
                                }
                            }
                        }
                    }}/>
                    {
                        window.innerWidth >= 1000 ? 
                            <></>
                        :
                            <div style={{height: "20px"}}/>
                    }  
                    <MbDocGeneralField label="Descrizione" type="text" value={_description} change={(val) => setDescription(val)} disabled/>
                </MbDocContainer>

                <MbDocContainer style={{marginTop: "20px"}}>
                    <MbDocGeneralField label="Ragione sociale" type="text" value={_business_name} change={(val) => setBusinessName(val)} disabled multiline rows={2}/>
                </MbDocContainer>

                <MbDocContainer style={{marginTop: "20px", display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: "space-between", gap: "20px"}}>
                    <MbDocGeneralField label="Partita IVA" type="text" value={_vat_number} change={(val) => setLotteryCode(val)} disabled/>
                    {
                        window.innerWidth >= 1000 ? 
                            <></>
                        :
                            <div style={{height: "20px"}}/>
                    }  
                    <MbDocGeneralField label="Codice fiscale" type="text" value={_fiscal_code} change={(val) => setFiscalCode(val)} disabled/>
                    {
                        window.innerWidth >= 1000 ? 
                            <></>
                        :
                            <div style={{height: "20px"}}/>
                    }  
                    <MbDocGeneralField label="Codice lotteria" type="text" value={_lottery_code} change={(val) => setLotteryCode(val)} disabled/>
                </MbDocContainer>

                {/*<MbDocContainer style={{marginTop: "20px"}}>
                    <MbDocGeneralField label="Codice lotteria" type="text" value={_lottery_code} change={(val) => setLotteryCode(val)} disabled/>
                    {
                        window.innerWidth >= 1000 ? 
                            <></>
                        :
                            <div style={{height: "20px"}}/>
                    }  
                    <MbDocGeneralField label="Codice SDI" type="text" value={_sdi} change={(val) => setSdiCode(val)} disabled/>
                </MbDocContainer>


                <MbDocContainer style={{marginTop: "20px"}}>
                    <MbDocGeneralField label="Pec" type="text" value={_pec} change={(val) => setPec(val)} disabled/>
                </MbDocContainer>*/}
            </div>
        </>
    );

};

// ******************************************** //
// *************** DESTINAZIONI *************** //
// ******************************************** //
const MbDocCustomerSupplierDestination = ({id, code, description, collective, address, zip_code, province, house_number, country_code, objCustomerSupplier, setCustomerSupplierDestinations, isOpenedDoc}) => {

    const [_id, setId]                       = useState(id);
    const [_code, setCode]                   = useState(code);
    const [_description, setDescription]     = useState(description);
    const [_collective, setCollective]       = useState(collective);
    const [_address, setAddress]             = useState(address);
    const [_zip_code, setZipCode]            = useState(zip_code);
    const [_province, setProvince]           = useState(province);
    const [_house_number, setHouseNumber]    = useState(house_number);
    const [_country_code, setCountryCode]    = useState(country_code);

    useEffect(() => {
        setId(id);
        setCode(code);
        setDescription(description);
        setCollective(collective);
        setAddress(address);
        setZipCode(zip_code);
        setProvince(province);
        setHouseNumber(house_number);
        setCountryCode(country_code);
    }, [id]);

    return (
        <div className="general_card">
            
            <MbDocTitle title="Destinazioni" icon={<AddLocationOutlinedIcon/>}></MbDocTitle>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField isOpenedDoc={isOpenedDoc} disabled={!objCustomerSupplier} label="Codice" type="text" value={_code} change={(val) => setCode(val)} search searchClick={(event) => {
                    if(event == 'delete'){
                        setCustomerSupplierDestinations(null);
                        return;
                    }
                    window.location.hash += "#destinations";
                }} submitEvent={async () => {
                    if(_code){
                        const data = await MbApi_DestinationsGetByCode(objCustomerSupplier.id, _code);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setCustomerSupplierDestinations(data.Json);
                            }
                        }
                    }
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Descrizione" type="text" value={_description} change={(val) => setDescription(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Comune" type="text" value={_collective} change={(val) => setCollective(val)} disabled/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Indirizzo" type="text" value={_address} change={(val) => setAddress(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="CAP" type="text" value={_zip_code} change={(val) => setZipCode(val)} disabled/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Provincia" type="text" value={_province} change={(val) => setProvince(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Nazione" type="text" value={_country_code} change={(val) => setCountryCode(val)} disabled/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Numero civico" type="text" value={_house_number} change={(val) => setHouseNumber(val)} disabled/>
            </MbDocContainer>


        </div>
    );

};

// *************************************** //
// *************** VETTORE *************** //
// *************************************** //
const MbDocVector = ({id, code, description, country_code, vat_number, fiscal_code, business_name, setVectorObj, objCustomer}) => {

    const [_id, setId]                       = useState(id);
    const [_code, setCode]                   = useState(code);
    const [_description, setDescription]     = useState(description);
    const [_country_code, setCountryCode]    = useState(country_code);
    const [_fiscalCode, setFiscalCode]       = useState(fiscal_code);
    const [_vatNumber, setVatNumber]         = useState(vat_number);
    const [_businessName, setBusinessName]   = useState(business_name);

    useEffect(() => {
        setId(id);
        setCode(code);
        setDescription(description);
        setCountryCode(country_code);
        setVatNumber(vat_number);
        setFiscalCode(fiscal_code);
        setBusinessName(business_name);
    }, [id]);

    return (
        <div className="general_card">
            
            <MbDocTitle title="Vettore / Corriere" icon={<DeliveryDiningOutlinedIcon/>}></MbDocTitle>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Codice" type="text" value={_code} change={(val) => setCode(val)} disabled={!objCustomer} search searchClick={(event) => {
                    
                    if(event == 'delete'){
                        setVectorObj(null);
                        return;
                    }

                    window.location.hash += "#vector";

                }} submitEvent={async () => {

                    if(_code){
                        const data = await MbApi_VectorGetByCode(_code);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setVectorObj(data.Json);
                            }
                        }
                    }

                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Descrizione" type="text" value={_description} change={(val) => setDescription(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Ragione sociale" type="text" value={_businessName} change={(val) => setBusinessName(val)} disabled/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Codice fiscale" type="text" value={_fiscalCode} change={(val) => setFiscalCode(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Partita IVA" type="text" value={_vatNumber} change={(val) => setVatNumber(val)} disabled/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Nazione" type="text" value={_country_code} change={(val) => setCountryCode(val)} disabled/>
            </MbDocContainer>

        </div>
    );

};

// ************************************** //
// *************** AGENTE *************** //
// ************************************** //
const MbDocAgent = ({id, code, description, name, vat_number, fiscal_code, commission, setAgentObj}) => {

    const [_id, setId]                       = useState(id);
    const [_code, setCode]                   = useState(code);
    const [_description, setDescription]     = useState(description);
    const [_name, setName]                   = useState(name);
    const [_fiscalCode, setFiscalCode]       = useState(fiscal_code);
    const [_vatNumber, setVatNumber]         = useState(vat_number);
    const [_commission, setCommission]       = useState(commission);

    useEffect(() => {
        setId(id);
        setCode(code);
        setDescription(description);
        setName(name);
        setFiscalCode(fiscal_code);
        setVatNumber(vat_number);
        setCommission(commission);
    }, [id]);

    return (
        <div className="general_card">
            
            <MbDocTitle title="Agente" icon={<HailOutlinedIcon/>}></MbDocTitle>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Codice" type="text" value={_code} change={(val) => setCode(val)} search searchClick={(event) => {
                    
                    if(event == 'delete'){
                        setAgentObj(null);
                        return;
                    }
                    window.location.hash += "#agent";

                }} submitEvent={async () => {
                    if(_code){
                        const data = await MbApi_AgentGetByCode(_code);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setAgentObj(data.Json);
                            }
                        }
                    }
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Descrizione" type="text" value={_description} change={(val) => setDescription(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Nome" type="text" value={_name} change={(val) => setName(val)} disabled/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Codice fiscale" type="text" value={_fiscalCode} change={(val) => setFiscalCode(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Partita IVA" type="text" value={_vatNumber} change={(val) => setVatNumber(val)} disabled/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Commissioni" type="text" value={_commission} change={(val) => setCommission(val)} disabled/>
            </MbDocContainer>

        </div>
    );

};

// *************************************************** //
// *************** TAVOLO E PRODUZIONE *************** //
// *************************************************** //
const MbDocTable = ({tableId, table_code, table_description, prodId, prod_code, prod_description, setTableObj, setPrdDepObj}) => {

    const [_tableId, setTableId]                        = useState(tableId);
    const [_prodId, setProdId]                          = useState(prodId);
    const [_table_code, setTableCode]                   = useState(table_code);
    const [_table_description, setTableDescription]     = useState(table_description);
    const [_prod_code, setProdCode]                     = useState(prod_code);
    const [_prod_description, setProdDescription]       = useState(prod_description);

    useEffect(() => {
        setTableId(tableId);
        setTableCode(table_code);
        setTableDescription(table_description);
    }, [tableId]);

    useEffect(() => {
        setProdId(prodId);
        setProdCode(prod_code);
        setProdDescription(prod_description);
    }, [prodId]);

    return (
        <div className="general_card">
            
            <MbDocTitle title="Tavolo e reparto di produzione" icon={<TableBarOutlinedIcon/>}></MbDocTitle>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Codice" type="text" value={_table_code} change={(val) => setTableCode(val)} search searchClick={(event) => {
                    
                    if(event == 'delete'){
                        setTableObj(null);
                        return;
                    }

                    window.location.hash += "#rest_table";

                }} submitEvent={async () => {
                    if(_table_code){
                        const data = await MbApi_RestTableGetByCode(_table_code);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setTableObj(data.Json);
                            }
                        }
                    }
                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Descrizione" type="text" value={_table_description} change={(val) => setTableDescription(val)} disabled/>
            </MbDocContainer>

            <MbDocContainer style={{marginTop: "20px"}}>
                <MbDocGeneralField label="Codice" type="text" value={_prod_code} change={(val) => setProdCode(val)} search searchClick={(event) => {

                    if(event == 'delete'){
                        setPrdDepObj(null);
                        return;
                    }   

                    window.location.hash += "#department_production";

                }} submitEvent={async () => {

                    if(_prod_code){
                        const data = await MbApi_ProductionDepartmentGetByCode(_prod_code);
                        if(data){
                            if(data.Result == "OK" && data.Json){
                                setPrdDepObj(data.Json);
                            }
                        }
                    }

                }}/>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }  
                <MbDocGeneralField label="Descrizione" type="text" value={_prod_description} change={(val) => setProdDescription(val)} disabled/>
            </MbDocContainer>

        </div>
    );

};

export {
    MbDocHead
}