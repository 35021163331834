import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @returns 
 */
const MbApi_DestinationsList = async (page, search, id_customer) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/destinations/list?page=${page}&record_for_page=${RecordsForPage}${search ? `&search=${search}` : ''}&id_customer=${id_customer}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} id_customer_supplier 
 * @param {*} code 
 * @returns 
 */
const MbApi_DestinationsGetByCode = async (id_customer_supplier, code) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/destinations/get_by_code?id_customer_supplier=${id_customer_supplier}&code=${code}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} body 
 * @returns 
 */
const MbApi_DestinationsCreate = async (body, id_customer) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/destinations/create?id_customer=${id_customer}`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} id_destinations
 * @returns 
 */
const MbApi_DestinationsDelete = async (id_destinations) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/destinations/delete?id_destinations=${id_destinations}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

export {
    MbApi_DestinationsList,
    MbApi_DestinationsCreate,
    MbApi_DestinationsDelete,
    MbApi_DestinationsGetByCode
}