import { Button, TextField, Typography, Grid, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Endpoint, RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_ListPriceCreate } from "../mb_api/mb_api_list_price";
import { MbApi_DeviceCreate } from "../mb_api/mb_api_device";
import { MbValidatorDeviceSetting } from "../mb_validator/mb_validator_device_setting";
import { v4 as uuid } from 'uuid';

const MbAnagraficaNewDevice = ({structure, comeback}) => {

    const [id, setId]                               = useState(null);
    const [code, setCode]                           = useState(null);
    const [desc, setDesc]                           = useState(null);
    const [serialNumber, setSerialNumber]           = useState(null);
    const [deviceSetting, setDeviceSetting]         = useState(null);
    const [message, setMessage]                     = useState(null);
    const [centerCost, setCenterCost]               = useState(null);
    const [displayBackdrop, setDisplayBackDrop]     = useState(false);
    const [listCenter, setListCenter]               = useState([]);

    // Validatori
    const [validatorDeviceSetting, setValidatorDeviceSetting] = useState(false);

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){ 
        setValidatorDeviceSetting(window.location.hash.includes("#device_setting") && !validatorDeviceSetting);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
		    setId(structure[0]);
            setCode(structure[1]);
            setDesc(structure[2]);
            setSerialNumber(structure[3]);
            setDeviceSetting(structure[structure.length - 1].device_setting);
            setCenterCost(structure[structure.length - 1].cost_center);
        }else{
            setSerialNumber(uuid());
        }

        fetch(
            `${Endpoint}/api/mb/v1/cost_center/list?page=0&record_for_page=99999`,
            {
                headers: {
                    'jwt': localStorage.getItem('jwt')
                }
            }
        ).then(
            async (response) => {

                if(response.status == 200){
                    const json = await response.json();
                    if(json.Result == "OK"){
                        setListCenter(json.Json.cost_centers);
                    }
                }

            }
        );

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} dispositivo</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={desc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Numero seriale"} style={{width: "100%"}} value={serialNumber} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setSerialNumber(e.target.value);
                    }} disabled/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Parametri"} style={{width: "100%"}} disabled value={deviceSetting ? deviceSetting.code : ''} InputLabelProps={{ shrink: true }} InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#device_setting";
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <FormControl fullWidth size="small">            
                <InputLabel id="demo-simple-select-label" shrink >Centri di costo</InputLabel>
                <Select
                    notched 
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={centerCost ? centerCost.id : null}
                    label="Centro di costo"
                    onChange={(e) => {
                       const index = listCenter.findIndex(i => i.id.toString() == e.target.value.toString());
                       if(index > -1){
                        setCenterCost(listCenter[index]);
                       }
                    }}
                >
                    {
                        listCenter && listCenter.length > 0 ?
                            listCenter.map((item, index) => 
                                <MenuItem value={item.id} key={`center_${index}`}>{item.description}</MenuItem>
                            )
                        :  
                            <></>
                    }
                </Select>
            </FormControl>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!code || !desc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    //if(code.length > 5 || desc.length > 200){
                    //    setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                    //    return;                        
                    //}

                    if(!serialNumber){
                        setMessage('Compilare codice seriale!');
                        return;
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_DeviceCreate(JSON.stringify({
                            id: id,
                            code: code,
                            description: desc,
                            serial_number: serialNumber,
                            device_setting: deviceSetting ? {id : deviceSetting.id} : null,
                            cost_center: centerCost ? {id: centerCost.id} : null
                        }));

                        setDisplayBackDrop(false);

                        if(result){
                            setMessage('Dispositivo creato con successo!');
                            setId(null);
                            setCode('');
                            setDesc('');
                            setSerialNumber('');
                            setDeviceSetting(null);
                            setCenterCost(null);
                            comeback();
                        }else{
                            setMessage('Errore durante la creazione del dispositivo!');
                        }

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

            {
                // Validatore parametri
                validatorDeviceSetting ? 
                    <MbValidatorDeviceSetting 
                        callbackFromValidator={(value) => {
                            setDeviceSetting(value);
                            window.location.hash = window.location.hash.split("#device_setting").join("");
                        }}
                    />
                :
                    <></>
            }

        </>
    );

};

export {
    MbAnagraficaNewDevice
}