import { Button, TextField, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_StorageCreate } from "../mb_api/mb_api_storage";

const MbAnagraficaNewStorage = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [storageCode, setStorageCode] = useState(null);
    const [storageDesc, setStorageDesc] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){ }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            setId(structure[0]);
            setStorageCode(structure[1]);
            setStorageDesc(structure[2]);
        }else{
            setId(null);
            setStorageCode(null);
            setStorageDesc(null);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} deposito</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={storageCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setStorageCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={storageDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setStorageDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!storageCode || !storageDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(storageCode.length > 5 || storageDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => { 
                        
                        const result = await MbApi_StorageCreate(JSON.stringify({
                            id: id,
                            code: storageCode,
                            description: storageDesc
                        }));

                        setDisplayBackDrop(false);

                        if(result){
                            setId(null);
                            setStorageCode('');
                            setStorageDesc('');
                            comeback();
                        }

                        setMessage(result ? "Deposito salvato con successo" : "Errore durante il salvataggio del deposito!");

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

        </>
    );

};

export {
    MbAnagraficaNewStorage
}