import { Button, TextField, Typography, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_DeviceSettingCreate } from "../mb_api/mb_api_device_setting";

const MbAnagraficaNewDeviceSetting = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [device_settingCode, setDeviceSettingCode]            = useState(null);
    const [device_settingDesc, setDeviceSettingDesc]            = useState(null);
    const [fiscal_printer_model, setFiscal_printer_model]       = useState(null);
    const [fiscal_printer_ip, setFiscal_printer_ip]             = useState(null);
    const [fiscal_printer_port, setFiscal_printer_port]         = useState(null);
    const [fiscal_printer_serial, setFiscal_printer_serial]     = useState(null);
    const [thermic_printer_model, setThermic_printer_model]     = useState(null);
    const [thermic_printer_ip, setThermic_printer_ip]           = useState(null);
    const [thermic_printer_port, setThermic_printer_port]       = useState(null);
    const [thermic_printer_serial, setThermic_printer_serial]   = useState(null);
    const [display_for_categories, setDisplayForCategories]     = useState(0);
    const [print_order_web, set_printer_order_web]              = useState(0);
    const [ade_pin, setAdePin]                                  = useState(null);
    const [ade_fiscalCode, setAdeFiscalCode]                    = useState(null);
    const [ade_password, setAdePassword]                        = useState(null);
    const [ade_vat_number, setAdeVatNumber]                     = useState(null);
    const [enable_ade_receipt, setEnabledAdeReceipt]            = useState(0);
    const [ade_business_name, setAdeBusinessName]               = useState(null);
    const [ade_address, setAdeAddress]                          = useState(null);
    const [ade_collective, setAdeCollective]                    = useState(null);
    const [ade_zip_code, setAdeZipCode]                         = useState(null);
    const [ade_house_number, setAdeHouseNumber]                 = useState(null);
    const [ade_province, setAdeProvince]                        = useState(null);
    const [message, setMessage]                                 = useState(null);
    const [displayBackdrop, setDisplayBackDrop]                 = useState(false);

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){ }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            const data = structure[structure.length - 1];
            setId(data.id);
            setDeviceSettingCode(data.code);
            setDeviceSettingDesc(data.description);
            setFiscal_printer_model(data.fiscal_printer_model);
            setFiscal_printer_ip(data.fiscal_printer_ip);
            setFiscal_printer_port(data.fiscal_printer_port);
            setFiscal_printer_serial(data.fiscal_printer_serial);
            setThermic_printer_model(data.thermic_printer_model);
            setThermic_printer_ip(data.thermic_printer_ip);
            setThermic_printer_port(data.thermic_printer_port);
            setThermic_printer_serial(data.thermic_printer_serial);
            setDisplayForCategories(data.display_for_categories);
            set_printer_order_web(data.print_order_from_web);
            setAdePin(data.ade_pin);
            setAdeFiscalCode(data.ade_fiscal_code);
            setAdeVatNumber(data.ade_vat_number);
            setAdePassword(data.ade_password);
            setAdeBusinessName(data.ade_business_name);
            setAdeAddress(data.ade_address);
            setAdeCollective(data.ade_collective);
            setAdeHouseNumber(data.ade_house_number);
            setAdeProvince(data.ade_province);
            setAdeZipCode(data.ade_zip_code);
            setEnabledAdeReceipt(data.receipt_without_rt == 1);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuove"} impostazioni dispositivo</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={device_settingCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDeviceSettingCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={device_settingDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDeviceSettingDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="label-select-fiscal-printer">Modello stampante fiscale</InputLabel>
                        <Select notched size="small" label="Modello stampante fiscale" labelId="label-select-fiscal-printer" value={fiscal_printer_model} onChange={(e) => {
                            setFiscal_printer_model(e.target.value);
                        }}>
                            <MenuItem value="custom_ws">Custom Web Service</MenuItem>
                            <MenuItem value="epson_ws">Epson Web Service</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"IP stampante fiscale"} style={{width: "100%"}} value={fiscal_printer_ip} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setFiscal_printer_ip(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Porta stampante fiscale"} style={{width: "100%"}} value={fiscal_printer_port} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setFiscal_printer_port(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Seriale stampante fiscale"} style={{width: "100%"}} value={fiscal_printer_serial} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setFiscal_printer_serial(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                {/*<Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="label-select-thermic-printer">Modello stampante termica</InputLabel>
                        <Select notched size="small" label="Modello stampante termica" labelId="label-select-thermic-printer" value={thermic_printer_model} onChange={(e) => {
                            setThermic_printer_model(e.target.value);
                        }}>
                            <MenuItem value="custom">Custom</MenuItem>
                            <MenuItem value="epson">Epson</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>*/}
                <Grid item xs={12}>
                    <TextField size="small" variant="outlined" label={"IP stampante termica"} style={{width: "100%"}} value={thermic_printer_ip} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setThermic_printer_ip(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Porta stampante termica"} style={{width: "100%"}} value={thermic_printer_port} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setThermic_printer_port(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Seriale stampante termica"} style={{width: "100%"}} value={thermic_printer_serial} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setThermic_printer_serial(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <FormControlLabel control={<Checkbox checked={display_for_categories ? true : false} onChange={(e, checked) => {
                setDisplayForCategories(checked ? 1 : 0);
            }}/>} label="Raggruppa prodotti per categoria"/>

            <div style={{height: "20px"}}/>

            <FormControlLabel control={<Checkbox checked={print_order_web ? true : false} onChange={(e, checked) => {
                set_printer_order_web(checked ? 1 : 0);
            }}/>} label={"Stampa ordini web"}/>

            <div style={{height: "20px"}}/>

            <Divider/>

            <div style={{height: "20px"}}/>

            <Typography variant="h6">Documento commerciale</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"PIN"} style={{width: "100%"}} value={ade_pin} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdePin(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice fiscale"} style={{width: "100%"}} value={ade_fiscalCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeFiscalCode(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Partita IVA"} style={{width: "100%"}} value={ade_vat_number} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeVatNumber(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Password"} style={{width: "100%"}} value={ade_password} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdePassword(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Ragione sociale"} style={{width: "100%"}} value={ade_business_name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeBusinessName(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Indirizzo"} style={{width: "100%"}} value={ade_address} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeAddress(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Comune"} style={{width: "100%"}} value={ade_collective} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeCollective(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Numero civico"} style={{width: "100%"}} value={ade_house_number} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeHouseNumber(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"CAP"} style={{width: "100%"}} value={ade_zip_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeZipCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Provincia"} style={{width: "100%"}} value={ade_province} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAdeProvince(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <FormControlLabel control={<Checkbox checked={enable_ade_receipt ? true : false} onChange={(e, checked) => {
                setEnabledAdeReceipt(checked ? 1 : 0);
            }}/>} label="Abilita documento commerciale"/>

            <div style={{height: "20px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!device_settingCode || !device_settingDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(device_settingCode.length > 5 || device_settingDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_DeviceSettingCreate(
                            JSON.stringify({
                                id                      : id,
                                code                    : device_settingCode,
                                description             : device_settingDesc,
                                fiscal_printer_model    : fiscal_printer_model,
                                fiscal_printer_ip       : fiscal_printer_ip,
                                fiscal_printer_port     : fiscal_printer_port,
                                fiscal_printer_serial   : fiscal_printer_serial,
                                thermic_printer_model   : thermic_printer_model,
                                thermic_printer_ip      : thermic_printer_ip,
                                thermic_printer_port    : thermic_printer_port,
                                thermic_printer_serial  : thermic_printer_serial,
                                display_for_categories  : display_for_categories,
                                ade_pin                 : ade_pin,
                                ade_fiscal_code         : ade_fiscalCode,
                                ade_vat_number          : ade_vat_number,
                                ade_password            : ade_password,
                                receipt_without_rt      : enable_ade_receipt ? 1 : 0,
                                ade_business_name       : ade_business_name,
                                ade_address             : ade_address,
                                ade_collective          : ade_collective,
                                ade_country_code        : "IT",
                                ade_house_number        : ade_house_number,
                                ade_province            : ade_province,
                                ade_zip_code            : ade_zip_code,
                                print_order_from_web    : print_order_web
                            })
                        );
                        
                        setDisplayBackDrop(false);
                        if(result){
                            setId(null);
                            setDeviceSettingCode('');
                            setDeviceSettingDesc('');
                            setFiscal_printer_model('');
                            setFiscal_printer_ip('');
                            setFiscal_printer_port('');
                            setFiscal_printer_serial('');
                            setThermic_printer_model('');
                            setThermic_printer_ip('');
                            setThermic_printer_port('');
                            setThermic_printer_serial('');
                            setDisplayForCategories(0);
                            setAdeFiscalCode('');
                            setAdePin('');
                            setAdePassword('');
                            setAdeVatNumber('');
                            setEnabledAdeReceipt(0);
                            setAdeBusinessName('');
                            setAdeAddress('');
                            setAdeCollective('');
                            setAdeHouseNumber('');
                            setAdeZipCode('');
                            setAdeProvince('');
                            set_printer_order_web(0);
                            comeback();
                        }

                        setMessage(result ? 'Parametri creati con successo' : 'Errore durante la creazione dei parametri');

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

        </>
    );

};

export {
    MbAnagraficaNewDeviceSetting
}