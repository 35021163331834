import { MbAnagraficaDestinations } from "../mb_anagrafiche/mb_tables/mb_anagrafica_destinations";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorDestinations = ({id_customer_supplier, callbackFromValidator}) => {
    
    return (
        <>
            <MbMessage title="Destinazioni" helper="Selezionare una destinazione" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaDestinations isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }} id_customer_supplier={id_customer_supplier}/>
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorDestinations
}