import { MbAnagraficaStorage } from '../mb_anagrafiche/mb_tables/mb_anagrafica_storage';
import { MbWhere } from '../mb_components/mb_where';
import {MbLayout} from './mb_layout';

const MbStorage = () => {
    return (
        <>
            <MbLayout openedVoices={['tabelle', 'depositi']}>     
                <MbWhere data={["Tabelle", "Depositi"]}/>
                <div style={{height: "20px"}}/>
                <MbAnagraficaStorage/>
            </MbLayout>
        </>
    );

};

export default MbStorage