import { useEffect } from "react";
import { MbAnagraficaDeviceSetting } from "../mb_anagrafiche/mb_tables/mb_anagrafica_device_setting";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorDeviceSetting = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Parametri" helper="Selezionare parametri" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaDeviceSetting isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorDeviceSetting
}