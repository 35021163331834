import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MbApi_AgentDelete, MbApi_AgentList } from "../../mb_api/mb_api_agent";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Permission, RecordsForPage } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import { MbAnagraficaNewAgent } from "../mb_anagrafica_new_agent";

const MbAnagraficaAgent = ({isValidator, callbackFromValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(-1);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const navigate                              = useNavigate();

    function fetchData(){
        MbApi_AgentList(page, search).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.agents.map((item, index) => 
                        items.push([item.id, item.code, item.description, item.name, item.fiscal_code, item.vat_number, item.commission.toFixed(2).replace(".", ",") + "%", item])
                    )
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        
        MbSaveColumnsForTable("agente", ["ID", "Codice", "Descrizione", "Nome", "Codice fiscale", "Partita IVA", "Provvigione"]);

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewAgent
                        comeback={() => {
                            setPage(-1);
                            setStructUpdate(null);
                            //navigate(-1);
                        }}
                        structure={structUpdate}
                    />
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"agente"}
                        columns={
                            MbProposeColumns(
                                'agente',
                                [
					                {"name": "ID"}, 
                                    {"name": "Codice"}, 
                                    {"name": "Descrizione"}, 
                                    {"name": "Nome"}, 
                                    {"name": "Codice fiscale"}, 
                                    {"name": "Partita IVA"}, 
                                    {"name": "Provvigione"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={Permission['agent_create']}
                        displayNew={Permission['agent_create']}
                        displayRead
                        displayUpdate={Permission['agent_create']}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={(data) => {
                            const copy = [...data];
                            copy[data.length - 2] = copy[data.length - 2].split(",").join(".").split("%").join("");
                            setStructUpdate(copy);
                            window.location.hash = '#new';
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_AgentDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(item) => {
                            if(isValidator){
                                callbackFromValidator(item);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_AGENTE"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaAgent
}