import { Button, TextField, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_RestTableCreate } from "../mb_api/mb_api_rest_table";
import { MbValidatorRestRoom } from "../mb_validator/mb_validator_rest_room";

const MbAnagraficaNewRestTable = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [rest_tableCode, setRestTableCode] = useState(null);
    const [rest_tableDesc, setRestTableDesc] = useState(null);
    const [statusTable, setStatusTable] = useState(0);
    const [x, setX] = useState(null);
    const [y, setY] = useState(null);
    const [roomId, setRoomId] = useState(null);
    const [roomCode, setRoomCode] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);

    // Validatori
    const [validatorRoom, setValidatorRoom] = useState(false);

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){
        setValidatorRoom(window.location.hash.includes('#rooms') && !validatorRoom);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            const str = structure[structure.length - 1];
            setId(str.id);
            setRestTableCode(str.code);
            setRestTableDesc(str.description);
            setStatusTable(0);
            setX(str.x);
            setY(str.y);
            setRoomId(str.room.id);
            setRoomCode(str.room.code);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} tavolo</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={rest_tableCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setRestTableCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={rest_tableDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setRestTableDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"X"} style={{width: "100%"}} value={x} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setX(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Y"} style={{width: "100%"}} value={y} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setY(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <TextField size="small" variant="outlined" label={"Sala"} style={{width: "100%"}} value={roomCode} disabled InputLabelProps={{ shrink: true }} InputProps={{
                endAdornment: <SearchIcon/>
            }} onClick={() => {
                window.location.hash += "#rooms";
            }}/>

            <div style={{height: "20px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!rest_tableCode || !rest_tableDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(rest_tableCode.length > 5 || rest_tableDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!roomId){
                        setMessage('Selezionare sala!');
                        return;
                    }

                    let _x = x;
                    let _y = y;

                    if(!_x || (_x && isNaN(_x))){
                        _x = null;
                    }

                    if(!_y || (_y && isNaN(_y))){
                        _y = null;
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

			            const result = await MbApi_RestTableCreate(JSON.stringify({
                            id: id,
                            code: rest_tableCode,
                            description: rest_tableDesc,
                            x: x,
                            y: y,
                            state: statusTable,
                            room: {
                                id: roomId
                            }
                        }));

                        setDisplayBackDrop(false);

                        setMessage(result ? 'Tavolo creato con successo' : 'Errore durante la creazione del tavolo!');

                        if(result){
                            setId(null);
                            setRestTableCode('');
                            setRestTableDesc('');
                            setX('');
                            setY('');
                            setRoomId(null);
                            setRoomCode('');
                            setStatusTable(0);
                            comeback();
                        }

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

            {
                validatorRoom ? 

                    // Validatore sala
                    <MbValidatorRestRoom callbackFromValidator={(item) => {
                        window.location.hash = window.location.hash.split("#rooms").join("");
                        setRoomId(item[0]);
                        setRoomCode(item[1]);
                    }}/>
                :
                    <></>
            }

        </>
    );

};

export {
    MbAnagraficaNewRestTable
}