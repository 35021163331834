import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const MbValidatorEsigibilita = ({close, choosed}) => {

    const [render, setRender] = useState(new Date().getTime());

    useEffect(() => {
        MbSaveColumnsForTable("esigibilita", ["Codice", "Descrizione"]);
        setTimeout(() => setRender(new Date().getTime()), 500);
    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Esigibilità">
                <div style={{paddingLeft: "10px", paddingRight: "10px", marginTop: "25px"}}>
                    <MbTable
                        table={"esigibilita"}
                        columns={
                            MbProposeColumns(
                                'esigibilita',
                                [
                                    { name: "Codice"      },
                                    { name: "Descrizione" }
                                ]
                            )
                        }
                        rows={[
                            ["I", "Iva ad esigibilità immediata"],
                            ["D", "Iva ad esigibilità differita"],
                            ["S", "Scissione dei pagamenti"]
                        ]}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchLocal
                        changedColumnsTable={() => {
                            setRender(new Date().getTime());
                        }}
                    />
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorEsigibilita
}