import { MbAnagraficaRestTable } from "../mb_anagrafiche/mb_tables/mb_anagrafica_rest_table";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorRestTable = ({callbackFromValidator}) => {
    return (
        <>
            <MbMessage title="Tavolo" helper="Selezionare un tavolo" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaRestTable isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorRestTable
}