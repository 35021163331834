import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import {AppLogo, AppName, CurrentPathName, displayOnlyDash, DrawerScroll, Endpoint, EnterpriseType, IsSubUser, MallboxVersion, MediaEndpoint, Permission, PrimaryColor, RemoveHash, SecondayColor, setCurrentPathName, setDrawerScroll, setGettedParams, usePermission, WhiteLabelApp} from '../mb_constants';
import { Accordion, AccordionSummary, Avatar, Backdrop, Button, Collapse, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Popper, TextField, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { MbDocHead_New } from '../mb_doc/mb_doc_model';
import { useState } from 'react';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Input, MenuList } from '@material-ui/core';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import { ExitToApp } from '@material-ui/icons';
import { useRef } from 'react';

var numbersClick = 0;
var pin = ''

const drawerWidth = 270;
const MbLayout = ({children, openedVoices}) => {

  const [displayDrawer, setDisplayDrawer] = React.useState(window.innerWidth >= 1000);
  const nav                               = useNavigate();
  const [opened, setOpened]               = React.useState(false);
  const [logo, setLogo]                   = React.useState(null);
  const [timestamp, setTimestamp]         = useState(new Date().getTime());
  const [anchorPop, setAnchorPop]         = useState(null);
  const [searchRes, setSearchRes]         = useState([]);
  const [expanded, setExpanded]           = useState(null);
  const [showRemoveArchive, setShowRemoveArchive] = useState(false);
  const refPin = useRef()
  const [openedRistorazione, setOpenedRistorazione] = useState(openedVoices ? openedVoices.includes('ristorazione') : false);
  const [openedMagazzino, setOpenedMagazzino] = useState(openedVoices ? openedVoices.includes('magazzino') : false);
  const [openedDocumento, setOpenedDocumento] = useState(openedVoices ? openedVoices.includes('documenti') : false);
  const [openedTabelle, setOpenedTabelle] = useState(openedVoices ? openedVoices.includes('tabelle') : false);

  const permission = usePermission();

  const removeLog4 = async () => {
   const resp = await fetch(
        `${Endpoint}/api/mb/v1/logger/delete_log_4`,
        {
            method: "GET",
            headers: {
                'jwt': localStorage.getItem('jwt')
            }
        }
    )
    
    if(resp.status == 200){
      const json = await resp.json();
       return json.Result
  }
  return false
};

  function resize(){
    setDisplayDrawer(window.innerWidth >= 1000);
  }

  function changedSettings(){
    try{
      const settings = JSON.parse(localStorage.getItem('setting'))[0];
      setLogo(settings.base64_logo);
    }catch(_){}
  }

  const navigate = (pathname) => {
    if(CurrentPathName == pathname && window.location.hash.replace("#", "").trim().length > 0) {
      //nav('/');
      //setTimeout(() => nav(pathname), 500);
      return;
    }
    setCurrentPathName(pathname);
    nav(pathname);
  };

  const LayoutView = () => {
    setTimeout(() => {
      setTimestamp(new Date().getTime());
    }, 1000);
  };

  const scrollEvt = (e) => {
    setDrawerScroll(e.target.scrollTop);
  };

  const multiClick = () => {
    console.log( 'click numero: '+numbersClick)
    if( numbersClick == 0 ){
      numbersClick = 1;
      setTimeout( () =>{ 
        numbersClick = 0
        console.log( 'tempo scaduto' )
      }, 3000);
      return;
    };
    numbersClick ++;
    if( numbersClick == 5 ){
      setShowRemoveArchive(true);
    }
  }

  React.useEffect(() => {

    window.addEventListener('resize', resize);
    window.addEventListener('changed_settings', changedSettings);
    window.addEventListener('layout_view', LayoutView);

    let parentDiv = null;
    try{
      
      const settings = JSON.parse(localStorage.getItem('setting'))[0];
      setLogo(settings.base64_logo);

      parentDiv = document.querySelector('#list_drawer').parentElement;
      if(parentDiv){
        if(DrawerScroll){
          parentDiv.scrollTop = DrawerScroll;
        }
        parentDiv.addEventListener('scroll', scrollEvt);
      }

    }catch(_){}

    return (() => {
      try{
        window.removeEventListener('resize', resize);
      }catch(_){}
      try{
        window.removeEventListener('changed_settings', changedSettings);
      }catch(_){}
      try{
        window.removeEventListener('layout_view', LayoutView);
      }catch(_){}
      try{
        window.removeEventListener('setted_whitelabel', LayoutView);
      }catch(_){}
      try{
        if(parentDiv)
          parentDiv.removeEventListener('scroll', scrollEvt);
      }catch(_){}
    });

  }, []);

  const selectedVoice = (value) => {
    return openedVoices ? (openedVoices.includes(value) ? true : false) : false;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {
        showRemoveArchive && <div style={{ width:'100vw', height: '100vh', backgroundColor:'', position:'absolute', zIndex:'10', display:'grid'}}>
          <div style={{ placeSelf:'center', display:'flex', width:'250px', height:'200px', backgroundColor:'rgba(237, 237, 237, 1)', alignContent:'center', justifyContent:'center', padding: '20px', flexDirection:'column'}}>
            <div style={{width:'100%', textAlign:'center'}}>Conferma?</div>
            <TextField onChange={(e)=> pin = e.target.value } placeholder='Pin' ref={refPin}></TextField>
            <div style={{display:'flex'}}>
              <Button onClick={()=> { pin = ''; setShowRemoveArchive(false)}}>Annulla</Button>
              <Button onClick={async  () => {
                if( pin.trim() != 'Cmh@') return;
                const resp =  await removeLog4();

                if( resp ) setShowRemoveArchive( false );
                console.log('POSSO RIMUOVERE')
              }}>Conferma</Button>
            </div>
             
             </div>
        </div>
      }
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: PrimaryColor, display: displayOnlyDash ? "none" : ""}} id="  " className='mallbox-appbar'>
        
        <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
          
          <div style={{display: "flex"}} className="branding">
            {
              window.innerWidth >= 1000 ? 
                <></>
              :
                <><MenuIcon onClick={() => setOpened(!opened)}/>&nbsp;&nbsp;&nbsp;&nbsp;</>
            }
            <img onClick={()=> multiClick()} src={WhiteLabelApp !== 'mallbox' ? (MediaEndpoint + "/easyappear_dylog_logo.png") : (MediaEndpoint + "/logo-white-mallbox-extended.png")} style={{height: "40px"}}/>
          </div>
            
          <div className="user-area">
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={() => {}} sx={{ p: 0 }}>
                  {/*<span style={{color: "white", fontSize: "18px"}}>vr{ MallboxVersion }&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                  <SettingsOutlinedIcon style={{color: "white", fontSize: "30px", display: permission && permission.p_general_setting_read == 1 ? "block" : "none"}} onClick={() => navigate('/setting')}/>&nbsp;&nbsp;
                  <ExitToApp style={{color: "white", fontSize: "30px"}} onClick={() => {
                    localStorage.removeItem('settings');
                    setGettedParams(false);
                    localStorage.removeItem('jwt');
                    navigate('/');
                  }}/>
                </IconButton>
              </Tooltip>
            </Box>
          </div>

        </Toolbar>

      </AppBar>
      <Drawer className="sidebar-left"  open={window.innerWidth >= 1000 ? true : opened} variant={window.innerWidth >= 1000 ? "permanent" : ""} sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }}}
          onClose={() => {
            setOpened(false);
          }}
          id="drawer_sidebar"
          style={{display: displayOnlyDash ? "none" : ""}}
      >
        <Toolbar />
        <MenuList dense className='dense-menu'>
          <MenuItem onClick={() => navigate('/dashboard')}>
            <ListItemIcon>
              <GridViewIcon fontSize='small'/>
            </ListItemIcon>
            <ListItemText className={selectedVoice('dashboard') ? "selected-voice-menu" : ""}>Dashboard</ListItemText>
          </MenuItem>
          {
            permission && permission.p_easyappear ? 
              <MenuItem onClick={() => navigate('/easyappear')}>
                <ListItemIcon>
                  <MobileScreenShareIcon fontSize='small'/>
                </ListItemIcon>
                <ListItemText className={selectedVoice('easyappear') ? "selected-voice-menu" : ""}>{WhiteLabelApp == 'dylog' ? "DylogApp" : "EasyAppear"}</ListItemText>
              </MenuItem>
            :
              <></>
          }
          {
            permission && permission.p_customer_read ? 
              <MenuItem onClick={() => navigate('/customer')}>
                <ListItemIcon>
                  <EmojiPeopleIcon fontSize='small'/>
                </ListItemIcon>
                <ListItemText className={selectedVoice('clienti') ? "selected-voice-menu" : ""}>Clienti</ListItemText>
              </MenuItem>
            :
              <></>
          }
          {
            permission && permission.p_supplier_read ? 
              <MenuItem onClick={() => navigate('/supplier')}>
                <ListItemIcon>
                  <LocalShippingIcon fontSize='small'/>
                </ListItemIcon>
                <ListItemText className={selectedVoice('fornitori') ? "selected-voice-menu" : ""}>Fornitori</ListItemText>
              </MenuItem>
            :
              <></>
          }
          <MenuItem onClick={(e) => setOpenedMagazzino(!openedMagazzino)}>
            <ListItemIcon>
              <WarehouseOutlinedIcon fontSize='small'/>
            </ListItemIcon>
            <ListItemText>Magazzino</ListItemText>
            {
              openedMagazzino ? 
                <ExpandLess/>
              :
                <ExpandMoreIcon/>
            }
          </MenuItem>
          <Collapse in={openedMagazzino}>
            {
              permission && permission.p_product_read ? 
                <MenuItem onClick={() => navigate('/product')}>
                  <ListItemText inset  className={selectedVoice('prodotti') ? "selected-voice-menu" : ""}>Prodotti</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_department_read ? 
                <MenuItem onClick={() => navigate('/department')}>
                  <ListItemText inset className={selectedVoice('reparti') ? "selected-voice-menu" : ""}>Reparti</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_menu_read ? 
                <MenuItem onClick={() => navigate('/menu')}>
                  <ListItemText inset className={selectedVoice('menu') ? "selected-voice-menu" : ""}>Menù</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_category_read ? 
                <MenuItem onClick={() => navigate('/category/parent')}>
                  <ListItemText inset  className={selectedVoice('categorie') ? "selected-voice-menu" : ""}>Categorie</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_cost_center_read ? 
                <MenuItem onClick={() => navigate('/cost_center')}>
                  <ListItemText inset  className={selectedVoice('centro_di_costo') ? "selected-voice-menu" : ""}>Centro di costo</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_variant_read ? 
                <MenuItem onClick={() => navigate('/variant')}>
                  <ListItemText inset  className={selectedVoice('varianti') ? "selected-voice-menu" : ""}>Varianti</ListItemText>
                </MenuItem>
              :
                <></>
                
            }

          </Collapse>
          <MenuItem onClick={() => setOpenedRistorazione(!openedRistorazione)}>
            <ListItemIcon>
              <RestaurantMenuOutlinedIcon fontSize='small'/>
            </ListItemIcon>
            <ListItemText>Ristorazione</ListItemText>
            {
              openedRistorazione ? 
                <ExpandLess/>
              :
                <ExpandMoreIcon/>
            }
          </MenuItem>
          <Collapse in={openedRistorazione}>
            {
              permission && permission.p_room_read ? 
                <MenuItem onClick={() => navigate('/rest_room')}>
                  <ListItemText inset  className={selectedVoice('sale') ? "selected-voice-menu" : ""}>Sale</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_table_read ? 
                <MenuItem onClick={() => navigate('/rest_table')}>  
                  <ListItemText inset  className={selectedVoice('tavoli') ? "selected-voice-menu" : ""}>Tavoli</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_department_production_read ? 
                <MenuItem onClick={() => navigate('/department_production')}>  
                  <ListItemText inset  className={selectedVoice('reparto_produzione') ? "selected-voice-menu" : ""}>Reparto di produzione</ListItemText>
                </MenuItem>
              :
                <></>
            }
          </Collapse>
          <MenuItem onClick={() => setOpenedDocumento(!openedDocumento)}>
            <ListItemIcon>
              <PlagiarismOutlinedIcon fontSize='small'/>
            </ListItemIcon>
            <ListItemText>Documenti</ListItemText>
            {
              openedDocumento ? 
                <ExpandLess/>
              :
                <ExpandMoreIcon/>
            }
          </MenuItem>
          <Collapse in={openedDocumento}>
          {
            permission && permission.p_document_create ? 
              <MenuItem onClick={() => {
                MbDocHead_New();
                if(window.location.pathname.includes('new_doc')){
                  window.location.pathname = "/new_doc";
                }
                navigate('/new_doc');
              }}>
                <ListItemText inset  className={selectedVoice('nuovo_documento') ? "selected-voice-menu" : ""}>Nuovo documento</ListItemText>
              </MenuItem>
            :
              <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/charge')}>
                  <ListItemText inset  className={selectedVoice('charge') ? "selected-voice-menu" : ""}>Carico</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/supplier_order')}>
                  <ListItemText inset className={selectedVoice('supplier_order') ? "selected-voice-menu" : ""}>Ordine fornitore</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/invoice')}>
                  <ListItemText inset className={selectedVoice('invoice') ? "selected-voice-menu" : ""}>Fattura</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/receipt')}>
                  <ListItemText inset className={selectedVoice('receipt') ? "selected-voice-menu" : ""}>Scontrino</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/cancel')}>
                  <ListItemText inset className={selectedVoice('cancel') ? "selected-voice-menu" : ""}>Annullo</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/receipt_refund')}>
                  <ListItemText inset className={selectedVoice('receipt_refund') ? "selected-voice-menu" : ""}>Reso</ListItemText>
                </MenuItem>
              : 
                <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/suspend')}>
                  <ListItemText inset className={selectedVoice('suspend') ? "selected-voice-menu" : ""}>Sospeso</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_document_read ? 
                <MenuItem onClick={() => navigate('/doc_list/advance_account')}>
                  <ListItemText inset className={selectedVoice('advance_account') ? "selected-voice-menu" : ""}>Pre-conto</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_logger_h ? 
                <MenuItem onClick={() => window.open(`/logger_h/index.html?jwt=${localStorage.getItem('jwt')}&0`)}>
                  <ListItemText inset className={""}>Logger massivo</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_logger_i ? 
                <MenuItem onClick={() => window.open(`/logger_i/index.html?jwt=${localStorage.getItem('jwt')}&0`)}>
                  <ListItemText inset className={""}>Logger dettagliato</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_logger_n ? 
                <MenuItem onClick={() => window.open(`/logger_n/index.html?jwt=${localStorage.getItem('jwt')}&0`)}>
                  <ListItemText inset className={""}>Logger riepilogo IVA</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_logger_l ? 
                <MenuItem onClick={() => window.open(`/logger_l/index.html?jwt=${localStorage.getItem('jwt')}&0`)}>
                  <ListItemText inset className={""}>Logger prodotti</ListItemText>
                </MenuItem>
              :
                <></>
            }
          </Collapse>
          <MenuItem onClick={() => setOpenedTabelle(!openedTabelle)}>
            <ListItemIcon>
              <TableRowsOutlinedIcon fontSize='small'/>
            </ListItemIcon>
            <ListItemText>Tabelle</ListItemText>
            {
              openedTabelle ? 
                <ExpandLess/>
              :
                <ExpandMoreIcon/>
            }
          </MenuItem>
          <Collapse in={openedTabelle}>
            {
              permission && permission.p_myenterprise_read ?
                <MenuItem onClick={() => navigate('/my_enterprise')}>
                  <ListItemText inset className={selectedVoice('la_mia_azienda') ? "selected-voice-menu" : ""}>La mia azienda</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_enterprise_read ?
                <MenuItem onClick={() => navigate('/tree_enterprise')}>
                  <ListItemText inset className={selectedVoice('tree_enterprise') ? "selected-voice-menu" : ""}>Aziende</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_storage_read ?
                <MenuItem onClick={() => navigate('/storage')}>
                  <ListItemText inset className={selectedVoice('depositi') ? "selected-voice-menu" : ""}>Depositi</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_causal_read ?
                <MenuItem onClick={() => navigate('/causal')}>
                  <ListItemText inset className={selectedVoice('causali') ? "selected-voice-menu" : ""}>Causali</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_office_read ?
                <MenuItem onClick={() => navigate('/office_enterprise')}>
                  <ListItemText inset className={selectedVoice('sedi_azienda') ? "selected-voice-menu" : ""}>Sedi</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_operator_read ?
                <MenuItem onClick={() => navigate('/operator')}>
                  <ListItemText inset className={selectedVoice('operatori') ? "selected-voice-menu" : ""}>Operatori</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_um_read ?
                <MenuItem onClick={() => navigate('/um')}>
                  <ListItemText inset className={selectedVoice('um') ? "selected-voice-menu" : ""}>Unità di misura</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_vat_read ?
                <MenuItem onClick={() => navigate('/vat')}>
                  <ListItemText inset className={selectedVoice('aliquote') ? "selected-voice-menu" : ""}>Aliquote</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_list_price_read ?
                <MenuItem onClick={() => navigate('/list_price')}>
                  <ListItemText inset className={selectedVoice('listini') ? "selected-voice-menu" : ""}>Listini</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_payment_read ?
                <MenuItem onClick={() => navigate('/payment')}>
                  <ListItemText inset className={selectedVoice('pagamenti') ? "selected-voice-menu" : ""}>Pagamenti</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_device_read ?
                <MenuItem onClick={() => navigate('/device')}>
                  <ListItemText inset className={selectedVoice('dispositivi') ? "selected-voice-menu" : ""}>Dispositivi</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_device_read ?
                <MenuItem onClick={() => navigate('/device_setting')}>
                  <ListItemText inset className={selectedVoice('parametri_dispositivo') ? "selected-voice-menu" : ""}>Parametri</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_discount_rule_read ?
                <MenuItem onClick={() => navigate('/discount_rules')}>
                  <ListItemText inset className={selectedVoice('regole_di_sconto') ? "selected-voice-menu" : ""}>Regole di sconto</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_discount_rule_read ?
                <MenuItem onClick={() => navigate('/promo')}>
                  <ListItemText inset className={selectedVoice('promo') ? "selected-voice-menu" : ""}>Promo</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_product_read ?
                <MenuItem onClick={() => navigate('/order_products')}>
                  <ListItemText inset className={selectedVoice('ordinamento_prodotti') ? "selected-voice-menu" : ""}>Ordinamento prodotti</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_category_read ?
                <MenuItem onClick={() => navigate('/order_categories')}>
                  <ListItemText inset className={selectedVoice('ordinamento_categorie') ? "selected-voice-menu" : ""}>Ordinamento categorie</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_summary_payments_read ?
                <MenuItem onClick={() => navigate('/summary_payments')}>
                  <ListItemText inset className={selectedVoice('riepilogo_pagamenti') ? "selected-voice-menu" : ""}>Riepilogo pagamenti</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_supervisor_key_read ?
                <MenuItem onClick={() => navigate('/supervisor_keys')}>
                  <ListItemText inset className={selectedVoice('chiavi_supervisore') ? "selected-voice-menu" : ""}>Chiavi supervisore</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_profile_read ?
                <MenuItem onClick={() => navigate('/profiles_v2')}>
                  <ListItemText inset className={selectedVoice('profilo') ? "selected-voice-menu" : ""}>Profili</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_subuser_read ?
                <MenuItem onClick={() => navigate('/subusers_v2')}>
                  <ListItemText inset className={selectedVoice('utenti') ? "selected-voice-menu" : ""}>Utenti</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_launch_cloud_command ?
                <MenuItem onClick={() => navigate('/cloud_command')}>
                  <ListItemText inset className={selectedVoice('invio_comandi') ? "selected-voice-menu" : ""}>Comandi cloud</ListItemText>
                </MenuItem>
              :
                <></>
            }
            {
              permission && permission.p_tipology ?
                <MenuItem onClick={() => navigate('/tipology')}>
                  <ListItemText inset className={selectedVoice('tipologie') ? "selected-voice-menu" : ""}>Tipologie</ListItemText>
                </MenuItem>  
              :
                <></>
            }
          </Collapse>
        </MenuList>

      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar style={{display: displayOnlyDash ? "none" : ""}} />
          {children}
      </Box>
    </Box>
  );
};

export { MbLayout };