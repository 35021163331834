import { Breadcrumbs, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const MbWhere = ({data, left}) => {

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                {
                    (data.length > 0 && data[0] ? data : [data[1]]).map((item) => <Typography color="inherit">{item}</Typography>)
                }
            </Breadcrumbs>
        </>
    );
};

export {
    MbWhere
}