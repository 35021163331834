import { useEffect, useState } from "react";
import { MbApi_CausalDelete, MbApi_CausalList } from "../../mb_api/mb_api_causal";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Permission, RecordsForPage, usePermission } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import { MbAnagraficaNewCausal } from "../mb_anagrafica_new_causal";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const MbAnagraficaCausal = ({isValidator, callbackFromValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(-1);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const navigate                              = useNavigate();
    const permission                            = usePermission();

    function fetchData(){
        MbApi_CausalList(page, search).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.causals.map((item, index) => 
                        items.push([
                            item.id, 
                            item.code, 
                            item.description, 
                            item.doc_desc, 
                            //item.doc_ade_type == 'NF' ? <CloseIcon sx={{color: "red"}}/> : <><CheckIcon sx={{color: "green"}}/> <small>{item.doc_ade_type}</small></>, 
                            //item.charge == 1 ? "+" : item.charge == 0 ? "NO MOV." : "-", 
                            //item.discharge == 1 ? "+" : item.discharge == 0 ? "NO MOV." : "-", 
                            //item.ordered == 1 ? "+" : item.ordered == 0 ? "NO MOV." : "-", 
                            //item.engaged == 1 ? "+" : item.engaged == 0 ? "NO MOV." : "-", 
                            //item.prefix,
                            //item.accounting == 0 ? <CloseIcon sx={{color: "red"}}/> : <CheckIcon sx={{color: "green"}}/>,
                            //item.propose_for == 'empty' ? 'Nessuna' : (item.propose_for == 'customer' ? "Cliente" : "Fornitore"),
                            item.storage.code,
                            item
                        ])
                    )
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        
        MbSaveColumnsForTable("causali", [
            "ID", 
            "Codice", 
            "Descrizione", 
            "Tipo documento", 
            //"Fiscale", 
            //"Carico", 
            //"Scarico", 
            //"Ordinato", 
            //"Impegnato", 
            //"Prefisso", 
            //"Contabilizzazione", 
            //"Controparte", 
            "Deposito"
        ]);

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewCausal
                        structure={structUpdate}
                        comeback={() => {
                            setPage(-1);
                            setStructUpdate(false);
                            //navigate(-1);
                        }}
                    />
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"causali"}
                        columns={
                            MbProposeColumns(
                                'causali',
                                [
					                {"name": "ID"}, 
                                    {"name": "Codice"}, 
                                    {"name": "Descrizione"}, 
                                    //{"name": "Fiscale"}, 
                                    //{"name": "Carico"}, 
                                    //{"name": "Scarico"}, 
                                    //{"name": "Ordinato"}, 
                                    //{"name": "Impegnato"},
                                    //{"name": "Prefisso"},
                                    //{"name": "Contabilizzazione"},
                                    //{"name": "Controparte"},
                                    {"name": "Deposito"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={permission.p_causal_delete == 1}
                        displayNew={permission.p_causal_create == 1}
                        displayRead
                        displayUpdate={permission.p_causal_create == 1}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }} 
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={(data) => {
                            const elm = data[data.length - 1];
                            setStructUpdate(
                                [
                                    elm.id, 
                                    elm.code, 
                                    elm.description, 
                                    elm.doc_desc, 
                                    elm.doc_ade_type, 
                                    elm.doc_enum,
                                    elm.charge, 
                                    elm.discharge, 
                                    elm.ordered, 
                                    elm.engaged, 
                                    elm.storage.id,
                                    elm.storage.code,
                                    elm.prefix,
                                    elm.accounting,
                                    elm.propose_for
                                ]
                            );
                            window.location.hash = '#new';
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_CausalDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(item) => {
                            if(isValidator){
                                callbackFromValidator(item);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_CAUSALI"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaCausal
}