import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} page 
 * @param {*} id_parent_category 
 * @param {*} search 
 * @returns 
 */
const MbApi_CategoryList = async (page, search, id_parent_category) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/category/list?page=${page}&record_for_page=${RecordsForPage}${search ? `&search=${search}` : ''}&id_parent_category=${id_parent_category}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @returns 
 */
const MbApi_CategoryAll = async () => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/category/get_all_categories`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            if(data.Result == "OK"){
                data.Json.categories.filter(item => !item.category).map((item, index) => item.category = {id: null});
            }
            return data;
        }
    }catch(_){
        
    }

    return null;
};


/**
 * 
 * @param {*} body 
 * @param {*} id_parent_category 
 * @returns 
 */
const MbApi_CategoryCreate = async (body, id_parent_category) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/category/create?id_parent_category=${id_parent_category}`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return res.Json.id;
            }
        }
    }catch(_){
        console.log(_);
    }

    return false;
};

/**
 * 
 * @param {*} id_category
 * @returns 
 */
const MbApi_CategoryDelete = async (id_category) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/category/delete?id_category=${id_category}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

export {
    MbApi_CategoryList,
    MbApi_CategoryCreate,
    MbApi_CategoryDelete,
    MbApi_CategoryAll
}