import { Button, TextField, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_VatCreate } from "../mb_api/mb_api_vat";
import { MbValidatorNatura } from "../mb_validator/mb_validator_natura";
import { MbValidatorEsigibilita } from "../mb_validator/mb_validator_esigibilita";

const MbAnagraficaNewVat = ({structure, comeback}) => {

    const [vatCode, setVatCode]                     = useState(null);
    const [vatDesc, setVatDesc]                     = useState(null);
    const [vatValue, setVatValue]                   = useState(null);
    const [vatNumber, setVatNumber]                 = useState(null);
    const [vatEsigibilita, setVatEsigibilita]       = useState(null);
    const [vatNatura, setVatNatura]                 = useState(null);
    const [message, setMessage]                     = useState(null);
    const [displayBackdrop, setDisplayBackDrop]     = useState(false);

    // Validatori
    const [validatorNatura, setValidatorNatura] = useState(false);
    const [validatorEsigibilita, setValidatorEsigibilita] = useState(false);

    const eKeyDown = (e) => {
        if(window.location.hash.includes('#natura') || window.location.hash.includes('#esigibilita')) return;
        SaveAna(e);
    }

    function eHashChange(){
        setValidatorNatura(window.location.hash.includes("#natura") && !validatorNatura);
        setValidatorEsigibilita(window.location.hash.includes('#esigibilita') && !validatorEsigibilita);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            const data = structure[structure.length - 1];
            setVatCode(data.code);
            setVatDesc(data.description);
            setVatValue(data.value);
            setVatNumber(data.department_number);
            setVatEsigibilita(data.collectability);
            setVatNatura(data.nature);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuova"} aliquota</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={vatCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVatCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={vatDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVatDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Valore aliquota"} style={{width: "100%"}} value={vatValue} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVatValue(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Numero di reparto (opzionale)"} style={{width: "100%"}} value={vatNumber} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVatNumber(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Esigiblità aliquota"} style={{width: "100%"}} value={vatEsigibilita} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: <SearchIcon sx={{cursor: "pointer"}}/>
                    }} onClick={() => {
                        window.location.hash += "#esigibilita";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Natura aliquota"} style={{width: "100%"}} value={vatNatura} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: <SearchIcon sx={{cursor: "pointer"}}/>
                    }} onClick={() => {
                        window.location.hash += "#natura";
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>
            
            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!vatCode || !vatDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(vatCode.length > 5 || vatDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!vatValue || (vatValue && isNaN(vatValue))){
                        setMessage("Il valore dell'aliquota è obbligatorio ed è un numero!");
                        return;                        
                    }

                    if(vatNumber && isNaN(vatNumber)){
                        setMessage("Il numero di reparto deve essere numerico");
                        return;                        
                    }

                    if(!vatEsigibilita){
                        setMessage("Esigibilità è un campo obbligatorio!");
                        return;
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const body = JSON.stringify({
                            id                  : structure ? structure[0] : null,
                            code                : vatCode,
                            description         : vatDesc,
                            value               : vatValue,
                            department_number   : vatNumber,
                            nature              : vatNatura,
                            collectability      : vatEsigibilita
                        });

                        const result = await MbApi_VatCreate(body);

                        setDisplayBackDrop(false);
                        if(result){
                            setMessage('Aliquota creata con successo!');
                            setVatCode('');
                            setVatDesc('');
                            setVatValue('');
                            setVatNumber('');
                            setVatNatura('');
                            setVatEsigibilita('');
                            comeback();
                            return;
                        }

                        setMessage("Errore durante la creazione dell'aliquota!");

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

            {
                // Validatore natura
                validatorNatura ? 
                    <MbValidatorNatura
                        close={() => {  
                            window.location.hash = window.location.hash.split("#natura").join("");
                        }}
                        choosed={(value) => {
                            setVatNatura(value[0]);
                            window.location.hash = window.location.hash.split("#natura").join("");
                        }}
                    />
                :
                    <></>
            }

            {
                // Validatore esigibilità
                validatorEsigibilita ? 
                    <MbValidatorEsigibilita
                        close={() => {  
                            window.location.hash = window.location.hash.split("#esigibilita").join("");
                        }}
                        choosed={(value) => {
                            setVatEsigibilita(value[0]);
                            window.location.hash = window.location.hash.split("#esigibilita").join("");
                        }}
                    />
                :
                    <></>
            }

        </>
    );

};

export {
    MbAnagraficaNewVat
}