import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @returns 
 */
const MbApi_OperatorList = async (page, search) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/operator/list?page=${page}&record_for_page=${RecordsForPage}${search ? `&search=${search}` : ''}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} code 
 * @returns 
 */
const MbApi_OperatorGetByCode = async (code) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/operator/get_by_code?code=${code}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} body 
 * @returns 
 */
const MbApi_OperatorCreate = async (body) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/operator/create`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} id_operator
 * @returns 
 */
const MbApi_OperatorDelete = async (id_operator) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/operator/delete?id_operator=${id_operator}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

export {
    MbApi_OperatorList,
    MbApi_OperatorCreate,
    MbApi_OperatorDelete,
    MbApi_OperatorGetByCode
}