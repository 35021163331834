import { MbWhere } from "../mb_components/mb_where";
import { MbLayout } from "../mb_pages/mb_layout";
import { MbDocHead } from "./mb_doc_head";
import { useEffect, useState } from "react";
import { PrimaryColor } from "../mb_constants";
import { MbDocBody } from "./mb_doc_body";
import { MbDocHead_Dto, MbDocHead_New } from "./mb_doc_model";
import { Tabs, Tab, Button } from "@mui/material";
import { MbDocSummary } from "./mb_doc_summary";
import { MbConfirm, MbMessage } from "../mb_components/mb_message";
import { MbDocXml } from "./mb_doc_xml";
import { MbDocManageBill } from "./mb_doc_manage_bill";

const MbDoc = () => {

    const [tab, setTab] = useState(0);
    const [docDate, setDocDate] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayXml, setDisplayXml] = useState(false);
    const [ deleteBillModal, setDeleteBillModal] = useState(false);

    const cleanDoc = () => {
        setTab(0);
        setDocDate(null);
        setMessage(null);
        setDisplayXml(false);
    };

    const typeDocOpened = (e) => {
        if(e.detail == 'invoice'){
            setDisplayXml(true);
        }
    };

    const startUseEffect = () => {

        MbDocHead_New();
        window.addEventListener('type_doc_opened', typeDocOpened);
        window.addEventListener('clean_doc', cleanDoc);

        // Imposta data odierna su data documento
        const date = new Date();
        const today = date.toISOString('en-EN').split("T")[0];
        MbDocHead_Dto.doc_date = today;

    };

    useEffect(() => {

        startUseEffect();

        return (() => {
            try{
                window.removeEventListener('clean_doc', cleanDoc);
            }catch(_){}
            try{
                window.removeEventListener('type_doc_opened', cleanDoc);
            }catch(_){}
        });

    }, []);

    useEffect(() => {
        
        if(tab == 1){
            if(!MbDocHead_Dto || (MbDocHead_Dto && !MbDocHead_Dto.customer_supplier)){
                setMessage('Selezionare un cliente');
                setTab(0);
                return;
            }
        }

        //if(tab == 2){
        //    if(!MbDocHead_Dto || (MbDocHead_Dto && !MbDocHead_Dto.doc_date)){
        //        setMessage('Per gestire le scadenze, impostare una data');
        //        setTab(0);
        //        return;
        //    }
        //}

    }, [tab]);

    return (
        <>
        
            
        <MbConfirm open={deleteBillModal} 
                close={()=> setDeleteBillModal(false)} 
                message={'Eliminare la fattura?'}
                confirm={()=> { 
                console.log('elimina fattura')}}></MbConfirm>
            <MbMessage title={"Mallbox"} message={message} open={message} close={() => setMessage(null)}/>
            
        

            <MbLayout openedVoices={['documenti', 'nuovo_documento']}>     
                <MbWhere data={["Documenti", "Nuovo documento"]}/>
                <div style={{height: "20px"}}/>

                {   
                    window.innerWidth >= 1000 ? 
                        <Tabs value={tab} onChange={(event, newValue) => {
                            setTab(newValue);
                        }} aria-label="basic tabs example">
                            <Tab label="TESTA" id="head" aria-controls="tab_head" style={{color: tab == 0 ? PrimaryColor : ""}} />
                            <Tab label="LINEE DETTAGLIO" id="body" aria-controls="tab_body" style={{color: tab == 1 ? PrimaryColor : ""}}/>
                            {
                                //<Tab label="SCADENZE" id="deadlines" aria-controls="tab_deadlines" style={{color: tab == 2 ? PrimaryColor : ""}}/>
                            }
                            <Tab label="RIEPILOGO" id="footer" aria-controls="tab_footer" style={{color: tab == 2 ? PrimaryColor : ""}}/>
                            {
                                displayXml ?    
                                    <Tab label="XML" id="xml" aria-controls="tab_xml" style={{color: tab == 3 ? PrimaryColor : ""}}/>
                                :
                                    <></>
                            }
                            {
                                displayXml ?    
                                    <Tab label="GESTIONE FATTURA" id="manage_bill" aria-controls="tab_bill" style={{color: tab == 4 ? PrimaryColor : ""}}/>
                                :
                                    <></>
                            }
                         { 

                         //PULSANTE ELIMINA FATTURA
                         false && <Button
                                onClick={()=> {
                                    setDeleteBillModal(true);
                                }}
                                style={{color:'red'}}>ELIMINA FATTURA</Button>
                        }
                        </Tabs>
                        
                    :
                        <div style={{width: "100%", overflow: "auto", marginLeft: "-20px"}}>
                            <Tabs variant="fullWidth" value={tab} onChange={(event, newValue) => {
                                setTab(newValue);
                            }} scrollButtons>
                                <Tab label="TESTA" style={{color: tab == 0 ? PrimaryColor : ""}}></Tab>
                                <Tab label="LINEE" style={{color: tab == 1 ? PrimaryColor : ""}}></Tab>
                                {
                                    //<Tab label="SCADENZE" style={{color: tab == 2 ? PrimaryColor : ""}}></Tab>
                                }
                                <Tab label="RIEPILOGO" style={{color: tab == 2 ? PrimaryColor : ""}}></Tab>
                                {
                                    displayXml ?    
                                        <Tab label="XML" style={{color: tab == 3 ? PrimaryColor : ""}}></Tab>                                
                                    :   
                                        <></>
                                }
                                {
                                    displayXml ?    
                                        <Tab label="GESTIONE FATTURA" style={{color: tab == 4 ? PrimaryColor : ""}}></Tab>                                
                                    :   
                                        <></>
                                }
                            </Tabs>
                        </div>
                }
                        
                {
                    // Testa doc
                }
                <div role="tabpanel" hidden={tab !== 0} id="tab_head" index={0} style={{padding: "20px", marginLeft: window.innerWidth >= 1000 ? "0px" : "-20px"}}>
                    <MbDocHead/>
                </div>

                <div role="tabpanel" hidden={tab !== 1} id="tab_body" index={1} style={{padding: "20px", marginLeft: window.innerWidth >= 1000 ? "0px" : "-20px"}}>
                    <MbDocBody display={tab == 1}/>
                </div>

                {/*<div role="tabpanel" hidden={tab !== 2} id="tab_deadlines" index={2} style={{padding: "20px", marginLeft: window.innerWidth >= 1000 ? "0px" : "-20px"}}>
                    <MbDocDeadlines visible={tab == 2}/>
                </div>*/}

                <div role="tabpanel" hidden={tab !== 2} id="tab_footer" index={2} style={{padding: "20px", marginLeft: window.innerWidth >= 1000 ? "0px" : "-20px"}}>
                    <MbDocSummary visible={tab == 2}/>
                </div>
                
                <div role="tabpanel" hidden={tab !== 3} id="tab_xml" index={3} style={{padding: "20px", marginLeft: window.innerWidth >= 1000 ? "0px" : "-20px"}}>
                    <MbDocXml/>
                </div>

                <div role="tabpanel" hidden={tab !== 4} id="tab_bill" index={3} style={{padding: "20px", marginLeft: window.innerWidth >= 1000 ? "0px" : "-20px"}}>
                    <MbDocManageBill/>
                </div>

            </MbLayout>
        </>
    );

};

export default MbDoc