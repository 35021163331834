import { useEffect, useState } from "react";
import { MbApi_CustomerSupplierDelete, MbApi_CustomerSupplierList } from "../../mb_api/mb_api_customer_supplier";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Permission, PrimaryColor, RecordsForPage, usePermission } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import { MbAnagraficaNewCustomerSupplier } from "../mb_anagrafica_new_customer_supplier";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useNavigate } from "react-router-dom";

const MbAnagraficaCustomerSupplier = ({entity_type, isValidator, callbackFromValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(-1);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const navigate                              = useNavigate();
    const permission                            = usePermission();

    function fetchData(){
        MbApi_CustomerSupplierList(page, search, entity_type).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.customer_suppliers.map((item, index) => 
                        items.push([
                            item.id, 
                            item.code, 
                            item.description, 
                            //item.sdi, 
                            item.email, 
                            //item.pec, 
                            //item.country_code, 
                            item.vat_number, 
                            //item.fiscal_code, 
                            item.business_name, 
                            <GpsFixedIcon sx={{color: PrimaryColor}} onClick={() => {
                                navigate(`/destinations/${item.id}`);
                            }}/>, 
                            item
                        ])
                    )
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        
        MbSaveColumnsForTable("customer_supplier", [
            "ID", 
            "Codice", 
            "Descrizione", 
            //"Codice SDI", 
            //"Codice Lotteria", 
            //"Privato / Pubblica amministrazione", 
            "Email", 
            //"PEC", 
            //"Nazione", 
            "Partita IVA", 
            //"Codice fiscale", 
            "Ragione sociale", 
            //"Destinazioni"
            //"Nome", 
            //"Cognome", 
            //"Titolo", 
            //"Codice EORI", 
            //"Sede"
        ]);

        //fetchData();

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    console.log(permission);
    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewCustomerSupplier
                        ent_type={entity_type}
                        comeback={() => {
                            setPage(-1);
                            setStructUpdate(null);
                            //navigate(-1);
                        }}
                        structure={structUpdate}
                    />
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"customer_supplier"}
                        columns={
                            MbProposeColumns(
                                'customer_supplier',
                                [
					                {"name": "ID"}, 
                                    {"name": "Codice"}, 
                                    {"name": "Descrizione"}, 
                                    //{"name": "Codice SDI"}, 
                                    ////{"name": "Codice Lotteria"}, 
                                    //{"name": "Privato / Pubblica amministrazione"}, 
                                    {"name": "Email"}, 
                                    //{"name": "PEC"}, 
                                    //{"name": "Nazione"}, 
                                    {"name": "Partita IVA"}, 
                                    //{"name": "Codice fiscale"}, 
                                    {"name": "Ragione sociale"}, 
                                    //{"name": "Destinazioni"}
                                    //{"name": "Nome"}, 
                                    //{"name": "Cognome"}, 
                                    //{"name": "Titolo"}, 
                                    //{"name": "Codice EORI"},
                                    //{"name": "Sede"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={entity_type == "customer" ? (permission.p_customer_delete == 1) : (permission.p_supplier_delete == 1)}
                        displayNew={entity_type == "customer" ? (permission.p_customer_create == 1) : (permission.p_supplier_create == 1)}
                        displayRead
                        displayUpdate={entity_type == "customer" ? (permission.p_customer_create == 1) : (permission.p_supplier_delete == 1)}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={(data) => {
                            setStructUpdate(data);
                            window.location.hash = '#new';
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_CustomerSupplierDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(data) => {
                            if(isValidator){
                                callbackFromValidator(data);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_CUSTOMER_SUPPLIER"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaCustomerSupplier
}