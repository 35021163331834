import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Pagination from '@mui/material/Pagination';
import { Endpoint, PrimaryColor, RecordsForPage, SecondayColor, setRecordsForPage } from "../mb_constants";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import './mb_table.css';
import { MbConfirm, MbMessage } from "../mb_components/mb_message";
import { MbTableColumnRendering, MbTableCreateDetail } from "./mb_table_api";
import { MbWhiteSpace, SpaceMobile } from "../mb_components/mb_white_space";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

let clickedAct = false;
const MbTable = ({table, page, columns, rows, totalRecords, recordForPage, displayNew, displayUpdate, displayRead, displayDelete, clickRow, searchLocal, pageChange, changedRecordForPage, changedColumnsTable, displayRecordForPage, displaySetting, searchEventOutside, clickNew, clickUpdate, deleteEvent, isValidator, displayMore, clickDisplayMore}) => {

    const [search, setSearch]                               = useState('');
    const [pages, setPages]                                 = useState(0);
    const [data, setData]                                   = useState(rows);
    const [setting, setSetting]                             = useState(false);
    const [openPage, setOpenPage]                           = useState(false);
    const [textPage, setTextPage]                           = useState(null);
    const [renderForResize, setRenderForResize]             = useState(new Date().getTime());
    const [displayDeleteModal, setDisplayDeleteModal]       = useState(false);
    const [details, setDetails]                             = useState(null);
    const [modalValidus, setModalValidus]                   = useState( false )

    useEffect(() => {
        window.addEventListener('resize_event', () => {
            setRenderForResize(new Date().getTime());
        });
        return (() => {
            try{
                window.removeEventListener('resize_event');
            }catch(_){}
        });
    }, []);

    useEffect(() => {
        
        if(totalRecords && recordForPage){
            setPages(
                parseInt((totalRecords / recordForPage) + (totalRecords % recordForPage == 0 ? 0 : 1))
            )
        }

        setData(rows);
    }, [rows]);

    const searchEvent = () => {
        if(!search) {
            setData(rows);
            return;
        }

        if(searchLocal){
            const tmp = [];
            rows.forEach((item) => {
                let include = false;
                item.forEach((_item) => {
                    if(typeof(_item) == 'string'){
                        if(_item.toLowerCase().includes(search.toLowerCase())){
                            include = true;
                        }
                    }
                });
                if(include){
                    tmp.push(item);
                }
            });
            setData([...tmp]);
        }
    };

    const changePage = (page) => {
        if(page >= 1 && page <= pages){
            pageChange(page);
        }
    };

    return (
        <>
            <MbMessage open={details} close={() => setDetails(null)} title="Dettaglio" helper="Dettagli del record">
                <div style={{width: "300px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px"}} className="record-detail">
                    {   
                        details ? 
                            details.map((item, index) => 
                                <div key={`detail_${index}`}>
                                    <span><b>{item.key}</b>: {item.value}</span>
                                </div>
                            )
                        :   
                            <></>
                    }
                </div>
            </MbMessage>
            <MbConfirm open={displayDeleteModal} message={"Sicuro di voler cancellare il record?"} close={() => setDisplayDeleteModal(false)} confirm={() => {
                const copy = JSON.parse(JSON.stringify(displayDeleteModal));
                setDisplayDeleteModal(false);
                deleteEvent(copy);
            }}/>
            <MbMessage open={setting} close={() => setSetting(false)} title="Impostazioni" helper="Modifica le impostazioni della tua tabella">
                <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                    <MbTableColumnRendering table={table} changedColumns={() => {
                        if(changedColumnsTable)
                            changedColumnsTable();
                    }}/>
                </div>
            </MbMessage>
            <MbMessage open={openPage} close={() => setOpenPage(false)} title="Pagina" helper="Indicare una pagina">
                <div style={{width: "300px", padding: "10px"}}>
                    <TextField label="Pagina n." style={{width: "100%"}} onKeyDown={(e) => {
                        if(e.key == 'Enter'){
                            if(textPage && !isNaN(textPage)){
                                changePage(parseInt(textPage));
                                setOpenPage(false);
                            }
                        }
                    }} onChange={(e) => {
                        setTextPage(e.target.value);
                    }}></TextField>
                    <div style={{width: "100%", textAlign: "right", marginTop: "10px"}}>
                        <Button variant="contained" id="btn_change_page" onClick={() => {
                            if(textPage && !isNaN(textPage)){
                                changePage(parseInt(textPage));
                                setOpenPage(false);
                            }
                        }}>Ok</Button>
                    </div>
                </div>
            </MbMessage>
            <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : ""}}>
                <div style={{display: window.innerWidth >= 1000 ? "" : "flex"}}>
                    {
                        displayRecordForPage ? 
                            <FormControl className="mallbox-cascader" sx={{ minWidth: window.innerWidth >= 1000? 100 : (!isValidator ? "calc(100%)" : "100%"), marginRight: "10px"}}>
                                <InputLabel>Record</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={recordForPage}
                                    label="Record"
                                    onChange={(e) => {
                                        localStorage.setItem('record_for_page', e.target.value.toString());
                                        setRecordsForPage(e.target.value);
                                        changedRecordForPage();
                                    }}
                                >   
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    {/*<MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={150}>150</MenuItem>*/}
                                </Select>
                            </FormControl>
                        :
                            <></>
                    }
                    {
                        window.innerWidth >= 1000 ? 
                            <></>
                        :   
                            displaySetting && !isValidator ? 
                                <></>
                                /*<SettingsOutlinedIcon sx={{cursor: "pointer", marginTop: "5px", color: PrimaryColor, width: "30px", fontSize: "30px"}} onClick={() => {
                                    setSetting(true);
                                }}/>*/
                            :
                                <></>
                    }
                </div>
                <MbWhiteSpace/>

                <TextField className="mallbox-textfield" size="small" id="outlined-basic" label="Ricerca.." variant="outlined" InputProps={{
                    endAdornment: <SearchIcon sx={{cursor: "pointer"}} onClick={() => {
                        if(searchLocal)
                            searchEvent();
                        else{
                            if(searchEventOutside)
                                searchEventOutside(search)
                        }
                    }}></SearchIcon>
                }} sx={{width: window.innerWidth >= 1000 ? (displayNew && !isValidator ? "calc(100% - 150px)" : "100%") : "100%"}} value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={(e) => {
                    
                    if(e.key == 'Enter'){
                        if(searchLocal)
                            searchEvent();
                        else{
                            if(searchEventOutside)
                                searchEventOutside(search)
                        }
                    }

                }}/>
                <MbWhiteSpace/>
                {
                    displayNew && !isValidator? 
                        <>
                            <Modal open={modalValidus} onClose={() => setModalValidus(false)} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <div style={{width:'300px', height:'100px', background:'white',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                    <div> Avvia sincronizzazione?</div>
                                    <div>
                                        <Button 
                                            style={{marginRight:'5px'}}
                                            onClick={async ()=>{
                                                try{
                                                    let resp =  await fetch(Endpoint+'/api/mb/v1/validus/sync_products',{
                                                        headers: {
                                                        'jwt': localStorage.getItem('jwt')
                                                      }})
                                                      if(resp.status == 200 ){
                                                        setModalValidus(false);
                                                        searchEventOutside('')
                                                        alert('Sincronizzazione avvenuta con successo')
                                                      };
                                                }catch(err){
                                                    console.log(err);
                                                    alert( `Errore aggiornamento: ${err}`)
                                                }
                                            }}
                                            >Avvia</Button>
                                        <Button onClick={() => setModalValidus(false)}>Annulla</Button>
                                    </div>
                                    
                                </div>
                            </Modal>
                            <Button id="btn_new_mbtable" variant="contained" sx={{marginLeft: window.innerWidth>= 1000 ? "10px" : "", width: window.innerWidth >= 1000 ? "130px" : "100%", color: "white"}} onClick={() => {
                                if(clickNew)
                                    clickNew();
                            }}>Nuovo</Button>
                            <Button 
                                id="btn_new_mbtable" 
                                variant="contained" 
                                sx={{marginLeft: window.innerWidth>= 1000 ? "10px" : "", 
                                width: window.innerWidth >= 1000 ? "130px" : "100%", 
                                color: "red"}} 
                                onClick={ async () => {
                                    setModalValidus(true)
                                }}>
                             VALIDUS </Button>
                            <MbWhiteSpace/>
                        </>
                    :
                        <></>
                }
                {
                    window.innerWidth >= 1000 ? <>&nbsp;&nbsp;</> : <></>
                }
                {/*
                    window.innerWidth >= 1000 ? 
                        displaySetting && !isValidator ?
                            <Button id="btn_edit_mbtable" variant="contained" onClick={() => {
                                setSetting(true);
                            }}><SettingsOutlinedIcon sx={{cursor: "pointer", marginTop: "5px"}}/></Button>
                        :
                            <></>
                    :
                        <></>
                */}
            </div>
            <TableContainer sx={{marginTop: window.innerWidth >= 1000 ? "20px" : "10px", maxWidth: (window.innerWidth - 48) + "px"}}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    {
                        window.innerWidth >= 1000 ? 
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.filter(item => item.visible).map((item, index) => 
                                            <TableCell className="table-column" key={`column_${index.toString()}`} align="center">{item.name}</TableCell>
                                        )
                                    }
                                    {
                                        displayDelete || displayNew || displayUpdate || displayMore || displayRead ? 
                                            <TableCell className="table-column" align="center">
                                                
                                            </TableCell>
                                        :
                                            <></>
                                    }
                                </TableRow>
                            </TableHead>
                        :
                            <></>
                    }
                    <TableBody>
                        {
                            
                            window.innerWidth >= 1000 ? 
                                data.map((item, index) => 
                                    <TableRow key={`row_${index.toString()}`} style={{background: index % 2 == 0 ? "white" : "#fbfbfb", cursor: "pointer"}} onClick={() => {
                                        setTimeout(() => {
                                                    
                                            if(clickedAct){
                                                clickedAct = false;
                                                return;
                                            }

                                            if(clickRow)
                                                clickRow(item);
                                        }, 200);   
                                    }}>

                                        {
                                            item.map((row, _index) => 
                                                columns[_index] ? 
                                                    columns[_index].visible ? 
                                                        <TableCell align="center">{row}</TableCell>
                                                    :   
                                                        <></>
                                                :
                                                    <></>
                                            )
                                        }

                                        {
                                            displayDelete || displayNew || displayUpdate || displayRead || displayMore ? 
                                                <TableCell align="center" sx={{width: "230px", minHeight: "30px"}}>
                                                    {
                                                        displayRead ?
                                                            <><SearchIcon className="action-button search" onClick={() => {
                                                                clickedAct = true;
                                                                const details = MbTableCreateDetail(table, item);
                                                                if(details && details.length > 0){
                                                                    setDetails(details);
                                                                }
                                                            }}/>&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        :
                                                            <></>
                                                    }
                                                    {
                                                        displayDelete && !isValidator ? 
                                                            <><DeleteOutlineIcon className="action-button delete" onClick={() => {
                                                                clickedAct = true;
                                                                setDisplayDeleteModal(item);
                                                            }}/>&nbsp;&nbsp;&nbsp;&nbsp;</> 
                                                        : 
                                                            <></>
                                                    }
                                                    {
                                                        displayUpdate && !isValidator ? 
                                                            <><EditOutlinedIcon className="action-button modify" onClick={() => {
                                                                clickedAct = true;
                                                                clickUpdate(item);
                                                            }}/>&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        :
                                                            <></>
                                                    }
                                                    {
                                                        displayMore ? 
                                                            <MoreHorizOutlinedIcon className="action-button more" onClick={() => {
                                                                clickedAct = true;
                                                                clickDisplayMore(item);
                                                            }}></MoreHorizOutlinedIcon>
                                                        :
                                                            <></>
                                                    }
                                                </TableCell>
                                            :
                                                <></>
                                        }
                                    </TableRow>
                                )

                            :

                                <>
                                    {
                                        data.map((item, index) => 
                                            <TableRow key={`row_${index.toString()}`} style={{background: index % 2 == 0 ? "white" : "#EAEAEA", cursor: "pointer"}} onClick={() => {
                                                setTimeout(() => {
                                                    
                                                    if(clickedAct){
                                                        clickedAct = false;
                                                        return;
                                                    }

                                                    if(clickRow)
                                                        clickRow(item);
                                                }, window.location.pathname.includes('category') ? 50 : 0);                                                    
                                            }}>
                                                <TableCell>
                                                        {
                                                            item.map((row, _index) => 
                                                                columns[_index] ? 
                                                                    columns[_index].visible ? 
                                                                        <>
                                                                            <span style={{fontWeight: "bold"}}>{columns[_index].name}</span>
                                                                            <MbWhiteSpace customSpace={"5px"}></MbWhiteSpace>
                                                                            <span>{row}</span>
                                                                            <MbWhiteSpace></MbWhiteSpace>
                                                                        </>
                                                                    :   
                                                                        <></>
                                                                :
                                                                    <></>
                                                            )
                                                        }
                                                        <>
                                                            {
                                                                displayRead ?
                                                                    <><SearchIcon className="action-button search" onClick={() => {
                                                                        clickedAct = true;
                                                                        const details = MbTableCreateDetail(table, item);
                                                                        if(details && details.length > 0){
                                                                            setDetails(details);
                                                                        }
                                                                    }}/>&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                :
                                                                    <></>
                                                            }
                                                            {
                                                                displayDelete && !isValidator ? 
                                                                    <><DeleteOutlineIcon className="action-button delete" onClick={() => {
                                                                        clickedAct = true;
                                                                        setDisplayDeleteModal(item);
                                                                    }}/>&nbsp;&nbsp;&nbsp;&nbsp;</> 
                                                                : 
                                                                    <></>
                                                            }
                                                            {
                                                                displayUpdate && !isValidator ? 
                                                                    <><EditOutlinedIcon className="action-button modify" onClick={() => {
                                                                        clickedAct = true;
                                                                        clickUpdate(item);
                                                                    }}/>&nbsp;&nbsp;&nbsp;&nbsp;</> 
                                                                :
                                                                    <></>
                                                            }
                                                            {
                                                                displayMore && !isValidator ? 
                                                                    <MoreHorizOutlinedIcon className="action-button more" onClick={() => {
                                                                        clickedAct = true;
                                                                        clickDisplayMore(item);
                                                                    }}></MoreHorizOutlinedIcon>
                                                                :
                                                                    <></>
                                                            }
                                                        </>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                totalRecords == 0 ?
                    <div style={{textAlign: "center", paddingTop: "30px"}}>
                        <Typography variant="body1" sx={{fontWeight: "500"}}>NON SONO PRESENTI DATI..</Typography>
                    </div>
                :
                    totalRecords == -1 ? 
                        <div style={{textAlign: "center", paddingTop: "30px"}}>
                            <CircularProgress/>
                        </div>
                    :
                        <></>
            }
            <div style={{height: "50px"}}/>
            {
                pages && pages > 0 && totalRecords > 0 ? 
                    <>
                        <Stack spacing={0} style={{position: window.innerWidth >= 1000 ? "fixed" : "", bottom: "0px", right: "0px", width: window.innerWidth < 1000 ? "100%" : ""}}>
                            <Pagination size="large" page={page + 1} shape="rounded" sx={{marginTop: "20px", cursor: "pointer"}} count={pages} onChange={(event, _page) => {
                                pageChange(_page);
                            }}  onClick={(e) => {
                                if(e.target.innerText == '…'){
                                    setOpenPage(true);
                                }
                            }}/>
                        </Stack>
                    </>
                :
                    <></>
            }
            {
                window.innerWidth < 1000 ? 
                    <div style={{height: "30px"}}/>
                :
                    <></>
            } 
        </>
    );
};

export { MbTable }