import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @returns 
 */
const MbApi_CausalList = async (page, search, getAll = null) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/causal/list?page=${page}&record_for_page=${!getAll ? RecordsForPage : "99999"}${search ? `&search=${search}` : ''}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} code 
 * @returns 
 */
const MbApi_CausalGetByCode = async (code) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/causal/get_by_code?code=${code}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} body 
 * @param {*} id_storage 
 * @returns 
 */
const MbApi_CausalCreate = async (body, id_storage) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/causal/create?id_storage=${id_storage}`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return res.Json;
            }
        }
    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} id_causal
 * @returns 
 */
const MbApi_CausalDelete = async (id_causal) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/causal/delete?id_causal=${id_causal}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

export {
    MbApi_CausalList,
    MbApi_CausalCreate,
    MbApi_CausalDelete,
    MbApi_CausalGetByCode
}