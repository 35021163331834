import { useEffect } from "react";
import { MbAnagraficaVat } from "../mb_anagrafiche/mb_tables/mb_anagrafica_vat";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorVat = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Aliquota" helper="Selezionare un'aliquota" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaVat isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorVat
}