import { useEffect } from "react";
import { MbAnagraficaStorage } from "../mb_anagrafiche/mb_tables/mb_anagrafica_storage";
import { MbMessage } from "../mb_components/mb_message";
import { MbStorage } from "../mb_pages/mb_storage";

const MbValidatorStorage = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Depositi" helper="Selezionare il deposito" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaStorage isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorStorage
}