import { createSlice } from '@reduxjs/toolkit'

export const MbReduxProductComposition = createSlice({
    name: "product_composition",
    initialState: {
        value: []
    },
    reducers: { 
        redux_set_product_composition: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {redux_set_product_composition} = MbReduxProductComposition.actions;

export default MbReduxProductComposition.reducer;