import { MediaEndpoint } from "../mb_constants";

const MbSkeleton = ({module_name}) => {

    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center", height: "100vh"}}>
            <div>
                <div>
                    <img src={`${MediaEndpoint}/module_loading.gif`} style={{height: "300px"}}/>
                </div>
                <div>Caricamento modulo {module_name} in corso..</div>
            </div>
        </div>
    );
};

export {
    MbSkeleton
}