import { FormControl, FormGroup, Switch } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import { FormControlLabel } from "@mui/material";
import { useState } from "react";

/**
 * 
 * @param {*} table 
 */
const MbTableCreateDetail = (table, line) => {
    
    const mbTable = localStorage.getItem(`mb_table_${table}`);
    const output = [];
    if(mbTable){
        const json = JSON.parse(mbTable);
        json.forEach((item, index) => {
            output.push({
                key: item.name,
                value: line[index]
            });
        });
    }

    return output;
};

/**
 * 
 * @param {*} table 
 * @param {*} columns 
 */
const MbSaveColumnsForTable = (table, columns) => {
    const mbTable = localStorage.getItem(`mb_table_${table}`);
    if(!mbTable){
        const cls = [];
        columns.forEach((item) => {
           cls.push({name: item, visible: true}); 
        });
        localStorage.setItem(`mb_table_${table}`, JSON.stringify(cls));
    }else{
        const parsed = JSON.parse(mbTable);
        if(parsed.length != columns.length){
            localStorage.removeItem(`mb_table_${table}`);
            MbSaveColumnsForTable(table, columns);
        }
    }
};

/**
 * 
 * @param {*} table 
 * @param {*} columns 
 * @returns 
 */
const MbProposeColumns = (table, columns) => {

    const mbTable = JSON.parse(localStorage.getItem(`mb_table_${table}`));
    let _columns = [];
    if(mbTable){
        mbTable.forEach((item) => {
            _columns.push({name: item.name, visible: item.visible});
        }); 
    }

    return _columns;
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const MbTableColumnRendering = ({table, changedColumns}) => {
    
    const [columns, setColumns] = useState(JSON.parse(localStorage.getItem(`mb_table_${table}`)));
    const [render, setRender] = useState(false);

    return (
        <>
            {
                columns.map((item, index) => 
                    <>
                        <FormGroup>
                            <FormControlLabel control={
                                <Switch checked={item.visible} onChange={(e, val) => {
                                    item.visible = !item.visible;
                                    setRender(!render);
                                    localStorage.setItem(`mb_table_${table}`, JSON.stringify(columns));
                                    if(changedColumns){
                                        changedColumns();
                                    }
                                }} color="primary" sx={{}}/>
                            } label={item.name} />
                        </FormGroup>

                    </>
                )
            }
        </>
    );

};

export {
    MbSaveColumnsForTable,
    MbTableColumnRendering,
    MbProposeColumns,
    MbTableCreateDetail
}