import { Button, TextField, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_VectorCreate } from "../mb_api/mb_api_vector";
import { MbValidatorNations } from "../mb_validator/mb_validator_nations";

const MbAnagraficaNewVector = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [vectorCode, setVectorCode] = useState(null);
    const [vectorDesc, setVectorDesc] = useState(null);
    const [country_code, setCountry_code] = useState(null);
    const [vat_number, setVat_number] = useState(null);
    const [fiscal_code, setFiscal_code] = useState(null);
    const [business_name, setBusiness_name] = useState(null);
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [title, setTitle] = useState(null);
    const [eori_code, setEori_code] = useState(null);
    const [driving_license, setDriving_license] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);

    // Validatori
    const [validatorCountries, setValidatorCountries] = useState(null);

    const eKeyDown = (e) => {
        if(window.location.hash.includes('#countries')){
            return;
        }
        SaveAna(e);
    }

    function eHashChange(){ 
        setValidatorCountries(window.location.hash.includes('#countries') && !validatorCountries);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
		    setId(structure[0]);
            setVectorCode(structure[1]);
            setVectorDesc(structure[2]);
            setCountry_code(structure[3]);
            setVat_number(structure[4]);
            setFiscal_code(structure[5]);
            setBusiness_name(structure[6]);
            setName(structure[7]);
            setSurname(structure[8]);
            setTitle(structure[9]);
            setEori_code(structure[10]);
            setDriving_license(structure[11]);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} vettore</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={vectorCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVectorCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={vectorDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVectorDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Nazione"} style={{width: "100%"}} value={country_code} disabled InputLabelProps={{ shrink: true }} InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#countries";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Partita IVA - 28 caratteri"} style={{width: "100%"}} value={vat_number} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVat_number(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice fiscale (opzionale) - da 11 a 16 caratteri"} style={{width: "100%"}} value={fiscal_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setFiscal_code(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Ragione sociale (opzionale) - 80 caratteri"} style={{width: "100%"}} value={business_name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setBusiness_name(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Nome (opzionale)"} style={{width: "100%"}} value={name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setName(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Cognome (opzionale)"} style={{width: "100%"}} value={surname} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setSurname(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Titolo (opzionale)"} style={{width: "100%"}} value={title} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setTitle(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice EORI (opzionale)"} style={{width: "100%"}} value={eori_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setEori_code(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField size="small" variant="outlined" label={"Numero licenza guida (opzionale) - 20 caratteri"} style={{width: "100%"}} value={driving_license} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDriving_license(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "100px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!vectorCode || !vectorDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(vectorCode.length > 5 || vectorDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!country_code){
                        setMessage('Compilare la nazione!');
                        return;
                    }

                    if(!vat_number || (vat_number && vat_number.length > 28)){
                        setMessage('La partita IVA deve essere compilata con un massimo di 28 caratteri!');
                        return;
                    }

                    if(fiscal_code && fiscal_code.length < 11){
                        setMessage('Il codice fiscale ha lunghezza massima 16 caratteri!');
                        return;
                    }

                    if(business_name && business_name.length > 80){
                        setMessage('La ragione sociale ha lunghezza massima 80 caratteri!');
                        return;
                    }

                    if(driving_license && driving_license.length > 20){
                        setMessage('Il numero di licenza di guida ha lunghezza massima 20 caratteri!');
                        return;
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_VectorCreate(JSON.stringify({
                            id: id,
                            code: vectorCode,
                            description: vectorDesc,
                            country_code: country_code,
                            vat_number: vat_number,
                            fiscal_code: fiscal_code,
                            business_name: business_name,
                            name: name,
                            surname: surname,
                            title: title,
                            eori_code: eori_code,
                            driving_license: driving_license
                        }));

                        setDisplayBackDrop(false);

                        if(result){
                            setId(null);
                            setVectorCode('');
                            setVectorDesc('');
                            setCountry_code('');
                            setVat_number('');
                            setFiscal_code('');
                            setBusiness_name('');
                            setName('');
                            setSurname('');
                            setTitle('');
                            setEori_code('');
                            setDriving_license('');
                            comeback();
                        }

                        setMessage(result ? 'Vettore creato con successo' : 'Errore durante la creazione del vettore!');

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

            {
                // Validatore nazioni
                validatorCountries ? 
                    <MbValidatorNations close={() => {
                        window.location.hash = window.location.hash.split("#countries").join("");
                    }} choosed={(value) => {
                        setCountry_code(value[0]);
                        window.location.hash = window.location.hash.split("#countries").join("");
                    }}/>
                :
                    <></>
            }

        </>
    );

};

export {
    MbAnagraficaNewVector
}