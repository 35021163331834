import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { Endpoint } from "../mb_constants";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const MbValidatorNatura = ({close, choosed}) => {
    
    const [nature, setNature] = useState([]);
    const [render, setRender] = useState(new Date().getTime());

    useEffect(() => {

        MbSaveColumnsForTable("natura", ["Codice", "Descrizione"]);

        const local = localStorage.getItem('natura');
        if(!local){
            fetch(`${Endpoint}/files/natura.json`).then(async (response) => {
                if(response.status == 200){
                    const data = await response.json();
                    const items = [];
                    data.forEach((item) => items.push([item.key, item.value]));
                    localStorage.setItem('natura', JSON.stringify(items));
                    setNature(items);
                }
            });
        }else{
            setNature(JSON.parse(local));
        }

    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Nature IVA">
                <div style={{paddingLeft: "20px", paddingRight: "20px", marginTop: "20px"}}>
                    <MbTable
                        table={"natura"}
                        columns={
                            MbProposeColumns(
                                'natura',
                                [
                                    { name: "Codice"      },
                                    { name: "Descrizione" }
                                ]
                            )
                        }
                        rows={nature}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchLocal
                        changedColumnsTable={() => {
                            setRender(new Date().getTime());
                        }}
                    />
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorNatura
}