import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @returns 
 */
const MbApi_VectorList = async (page, search) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/vector/list?page=${page}&record_for_page=${RecordsForPage}${search ? `&search=${search}` : ''}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} code 
 * @returns 
 */
const MbApi_VectorGetByCode = async (code) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/vector/get_by_code?code=${code}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }
    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} body 
 * @returns 
 */
const MbApi_VectorCreate = async (body) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/vector/create`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} id_vector
 * @returns 
 */
const MbApi_VectorDelete = async (id_vector) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/vector/delete?id_vector=${id_vector}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

export {
    MbApi_VectorList,
    MbApi_VectorCreate,
    MbApi_VectorDelete,
    MbApi_VectorGetByCode
}