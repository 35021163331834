import { MbAnagraficaRestRoom } from "../mb_anagrafiche/mb_tables/mb_anagrafica_rest_room";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorRestRoom = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Sala" helper="Selezionare una sala" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaRestRoom isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorRestRoom
}