import { Fab, TextField, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { Endpoint, PrimaryColor } from "../mb_constants";
import { MbApi_DocUpateXml, MbApi_DocXmlData, MbApi_DownloadXml } from "./mb_doc_api";

const MbDocXml = () => {

    const [id, setId] = useState(null);
    const [xml, setXml] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);

    const formatXml = (sourceXml) => {
        var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
        var xsltDoc = new DOMParser().parseFromString([
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');
    
        var xsltProcessor = new XSLTProcessor();    
        xsltProcessor.importStylesheet(xsltDoc);
        var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        var resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
    };

    const openDoc = async (e) => {
        
        const doc = JSON.parse(e.detail);
        const id = doc.id;
        setId(id);

        const data = await MbApi_DocXmlData(id);
        const formatted = formatXml(data.Json.xml_data);
        setXml(formatted);

    };

    useEffect(() => {

        window.addEventListener('open_doc', openDoc);

        return (() => {
            try{
                window.removeEventListener('open_doc', openDoc);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayLoader}/>
            <div style={{paddingTop: "10px", paddingBottom: "10px", display: "flex", justifyContent: "space-between"}} id="div_billing">
                <Typography variant="h6">Rappresentazione XML Fattura</Typography>
                <Button variant="contained" style={{background: PrimaryColor}} onClick={async () => {

                    setDisplayLoader(true);
                    await new Promise((resolve) => setTimeout(() => resolve(), 1000));

                    const res = await fetch(
                        `${Endpoint}/api/mb/v1/document/update_xml_data_for_bill_change?id_doc=${id}`,
                        {
                            headers: {
                                'jwt': `Bearer ${localStorage.getItem('jwt')}`
                            }
                        }
                    );

                    if(res.status == 200){
                        const json = await res.json();
                        if(json.Result == "OK"){
                            const data = await MbApi_DocXmlData(id);
                            const formatted = formatXml(data.Json.xml_data);
                            setDisplayLoader(false);
                            setXml(formatted);
                            return;
                        }
                    }

                    setDisplayLoader(false);

                }}>Aggiorna fattura</Button>
            </div>
            <TextField multiline value={xml} style={{width: "100%"}} onChange={(e) => {
                setXml(e.target.value);
            }}/>
            <Button variant="contained" className="save-button" onClick={async () => {
                
                setXml(formatXml(xml));
                setDisplayLoader(true);
                setTimeout( async () => {
                    const data = await MbApi_DownloadXml(id);
                    setDisplayLoader(false);
                    const endpoint = Endpoint + data.Json.path;
                    if(data.Result == "OK"){
                        fetch(
                            endpoint
                        ).then(async (response) => {

                            var xmltext = await response.text();
                            var filename = "temp.xml";
                            var pom = document.createElement('a');
                            var bb = new Blob([xmltext], {type: 'text/plain'});
                            pom.setAttribute('href', window.URL.createObjectURL(bb));
                            pom.setAttribute('download', filename);
                            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                            pom.draggable = true; 
                            pom.classList.add('dragout');
                            pom.click();

                        });
                    }
                }, 1000);

            }} id="btn_save">DOWNLOAD XML</Button>
        </>
    );
};

export {
    MbDocXml
}