import { useEffect } from "react";
import { MbAnagraficaPayment } from "../mb_anagrafiche/mb_tables/mb_anagrafica_payment";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorPayment = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Pagamento" helper="Selezionare un pagamento" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaPayment isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorPayment
}