import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { Endpoint, LinkFile } from "../mb_constants";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const MbValidatorDocType = ({close, choosed}) => {
    
    const [docType, setDocType] = useState([]);
    const [render, setRender] = useState(new Date().getTime());

    useEffect(() => {

        MbSaveColumnsForTable("doc_type", ["Descrizione", "Tipo"]);

        fetch(`${LinkFile}/doc_type.json`).then(async (response) => {
            if(response.status == 200){
                const data = await response.json();
                const items = [];
                data.forEach((item) => items.push([item.doc_desc, item.doc_type, item.doc_enum]));
                localStorage.setItem('doc_type', JSON.stringify(items));
                setDocType(items);
            }
        });

    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Tipologie documento">
                <div style={{paddingLeft: "20px", paddingRight: "20px", marginTop: "20px"}}>
                    <MbTable
                        table={"doc_type"}
                        columns={
                            MbProposeColumns(
                                'doc_type',
                                [
                                    { name: "Descrizione"      },
                                    { name: "Tipo"             }
                                ]
                            )
                        }
                        rows={docType}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchLocal
                        changedColumnsTable={() => {
                            setRender(new Date().getTime());
                        }}
                    />
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorDocType
}