import { Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { Button } from "@mui/material"
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import { MbValidatorProduct } from "../mb_validator/mb_validator_product";
import { MbDocHead_Dto } from "./mb_doc_model";
import { PrimaryColor, RemoveHash } from "../mb_constants";
import { MbConfirm, MbMessage } from "../mb_components/mb_message";
import { MbApi_ProductGetByCodeOrBarcode } from "../mb_api/mb_api_product";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const MbLineModel = {
    id: null,
    code: null,
    description: null,
    barcode: null,
    taxable: null,
    quantity: 1,
    discount_increase: null,
    vat: null,
    tax: null,
    um: null,
    multiply_for: 1,
    variants: [],
    related_products: [],
    note: null
};

const MbDocLine = ({line, index, validatorProduct, validateProduct, deleteLine, validateComposition}) => {

    const [render, setRender] = useState(false);
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [chooseBetweenProducts, setChooseBetweenProducts] = useState([]);

    useEffect(() => {
        setTax();
    }, [line.quantity, line.discount_increase, line.taxable, line.vat]);

    const updateLine = () => {
        MbDocHead_Dto.lines[index] = Object.assign({}, line);
        dispatchEvent(new CustomEvent('update_doc_deadlines'));
    };

    const searchProd = async (search_for, value, preparedData = null) => {

        if(!line) return;

        if(search_for == 'code')
            if(!line.code) return;

        if(search_for == 'barcode')
            if(!line.barcode) return;

        let data = preparedData;
        if(!preparedData){
            data = await MbApi_ProductGetByCodeOrBarcode(search_for, value, MbDocHead_Dto.customer_supplier.list_price.id);
        }

        if(data){
            if(data.Result == "OK"){
                if(data.Json.length > 0){
                    const products = data.Json;
                    if(products.length == 1){
                        if(search_for == 'barcode'){
                            if(products[0].barcode !== value){
                                const umsData = products[0].ums.filter(item => item.barcode.toLowerCase() == value.toLowerCase());
                                if(umsData.length > 0){
                                    products[0].description = umsData[0].description;
                                    products[0].barcode = umsData[0].barcode;
                                    products[0].multiply_for = umsData[0].quantity;
                                }
                            }
                        }
                        validateProduct(products[0]);
                    }else{
                        setChooseBetweenProducts(products);
                    }
                }
            }
        }
    };

    const setTax = () => {
        try{
            
            line.discount_increase = line.discount_increase ?? 0;
            if(! 
                ( 
                    (!line.vat && line.vat !== 0)                               || 
                    (!line.taxable && line.taxable !== 0)                       || 
                    (!line.quantity && line.quantity !== 0)                     || 
                    (!line.discount_increase && line.discount_increase !== 0)   || 
                    isNaN(line.vat)                                             || 
                    isNaN(line.taxable)                                         || 
                    isNaN(line.quantity)                                        || 
                    isNaN(line.discount_increase)
                ) 
            ){

                // Somma le varianti
                let variantsAmount = 0.00;
                if(line.variants){
                    line.variants.filter(item => item.checked).forEach((variant) => {
                        variantsAmount += parseFloat(variant.override_price ?? 0);
                    });
                }

                let effective_taxable = parseFloat(line.taxable ?? 0) + variantsAmount;

                const amount = (effective_taxable - ((effective_taxable * parseFloat(line.discount_increase ?? 0)) / 100)) * parseFloat(line.quantity);
                line.tax = ((parseFloat(line.vat) * amount) / 100);
            }

            updateLine();

        }catch(_){

        }
        setRender(!render);
    };

    const lineAmount = () => {
        
        let effective_taxable = parseFloat(line.taxable ?? 0);
        line.variants.filter(v => v.checked).forEach((variant) => {
            effective_taxable += parseFloat(variant.override_price ?? 0);
        });
        effective_taxable += ( (parseFloat(line.discount_increase) * effective_taxable) / 100 );
        effective_taxable *= parseFloat(line.quantity ?? 1);

        return effective_taxable ?? 0;
    };

    const variantsChange = () => {

        const amount_line = lineAmount() ?? 0;
        let effective_tax = (amount_line * parseFloat(line.vat ?? 0)) / 100;
        line.tax = effective_tax;

        setRender(!render);
        updateLine();

    };

    const accordionDetail = () => {
        return (
            <Accordion expanded={accordionExpanded} disabled={!accordionExpanded} style={{border: "none", boxShadow: "none", background: "white", height: accordionExpanded ? "" : "0px"}}>
                <AccordionSummary expandIcon={<></>} style={{background: "white", borderBottom: accordionExpanded ? "1px solid rgb(215, 215, 215)" : "", background:  accordionExpanded ? PrimaryColor : "transparent"}}>
                    {
                        accordionExpanded ? 
                            <Typography variant="h6" sx={{color: "white"}}>Dettaglio linea #{index + 1}</Typography>
                        :
                            <></>
                    }
                </AccordionSummary>
                <AccordionDetails style={{border: "1px solid rgb(235, 235, 235)"}}>
                    <div style={{borderBottom: ( (line.variants && line.variants.length > 0) || (line.related_products && line.related_products.length > 0) ) ? "1px solid rgb(235, 235, 235)" : "", padding: "20px"}}>
                        <Typography variant="h6">Netto linea</Typography>
                        <div style={{height: "10px"}}/>
                        {
                            "€ " + (lineAmount() ?? 0).toString().replace(".", ",")
                        }
                        <Typography variant="h6">Lordo linea</Typography>
                        <div style={{height: "10px"}}/>
                        {
                            "€ " + ((lineAmount() ?? 0) + parseFloat(line.tax ?? 0)).toString().replace(".", ",")
                        }
                    </div>
                    {
                        line.variants && line.variants.length > 0 ? 
                            <div style={{borderBottom: (line.related_products && line.related_products.length > 0 ? "1px solid rgb(235, 235, 235)" : ""), padding: "20px"}}>
                                <Typography variant="h6">Varianti</Typography>
                                <div style={{marginTop: "10px", display: "flex", flexDirection: "column", gap: "20px"}}>
                                    {
                                        line.variants.map((item, index) => 
                                            <div style={{display: "flex", justifyContent: window.innerWidth >= 1000 ? "space-between" : "", flexDirection: window.innerWidth >= 1000 ? "row" : "column", gap: window.innerWidth >= 1000 ? "" : "5px"}}>
                                                
                                                <FormControlLabel control={<Checkbox checked={item.checked} onChange={(e, checked) => { 
                                                    
                                                    if(item.checked == null){
                                                        item.checked = true;
                                                    }else{
                                                        item.checked = !item.checked;
                                                    }

                                                    variantsChange(item);
                                                }}/>} label={item.variant.description}></FormControlLabel>

                                                <TextField size="small" label="Sovraprezzo" InputLabelProps={{shrink: true}} value={item.override_price} onChange={(e) => {
                                                    
                                                    const override = e.target.value;
                                                    if(override){
                                                        item.override_price = override;
                                                    }
                                                    setRender(!render);
                                                    variantsChange(item);

                                                }}></TextField>

                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        :
                            <></>
                    }
                    {

                        line.related_products && line.related_products.length > 0 ?
                            <div style={{padding: "20px"}}>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <Typography variant="h6">Composizione prodotto</Typography>
                                    <div>
                                        {/*<Button variant="contained" id="btn_new_mbtable" onClick={() => {
                                            validateComposition();
                                        }}  style={{width: "100%"}}><AddIcon/>&nbsp;&nbsp;AGGIUNGI</Button>*/}
                                    </div> 
                                </div>
                                {
                                    
                                    line.related_products.map((item, index) => 
                                        {
                                            let qtaValue = parseFloat(item.quantity) * parseFloat(line.quantity) * parseFloat(line.multiply_for ?? 1)
                                            let priceValue = parseFloat(item.override_price ?? 1) * parseFloat(line.quantity);

                                            return (
                                                <>
                                                    <div style={{display: window.innerWidth >= 1000 ? "flex" : "", justifyContent: window.innerWidth >= 1000 ? "space-between" : "", marginTop: "20px"}}>
                                                        <div>
                                                            <TextField size="small" variant="outlined" value={item.quantity ?? ""} onChange={(e) => {
                                                                qtaValue = e.target.value;
                                                                if(qtaValue && !isNaN(qtaValue)){
                                                                    item.quantity = qtaValue;
                                                                }
                                                                updateLine();
                                                                setRender(!render);
                                                            }} label={item.child_product.description + `. Qta`} InputLabelProps={{shrink: true}} style={{width: "100%"}}></TextField>
                                                        </div>
                                                        {/*<div>
                                                            <TextField size="small" variant="outlined" value={item.override_price ?? ""} onChange={(e) => {
                                                                priceValue = e.target.value;
                                                                if(priceValue && !isNaN(priceValue)){
                                                                    item.override_price = priceValue;
                                                                }
                                                                updateLine();
                                                                setRender(!render);
                                                            }} label={"Prezzo"} InputLabelProps={{shrink: true}} style={{width: "100%"}}></TextField>
                                                        </div>*/}
                                                        {
                                                            window.innerWidth >= 1000 ?     
                                                                <></>
                                                            :
                                                                <div style={{height: "5px"}}/>
                                                        }
                                                        <div>
                                                            <Button variant="contained" id="btn_new_mbtable" onClick={() => {
                                                                line.related_products.splice(index, 1);
                                                                setRender(!render);
                                                                updateLine();
                                                            }}  style={{width: "100%"}}>RIMUOVI</Button>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                    )
                                }
                            </div>
                        :
                            <></>
                    }
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <>
            <MbMessage open={chooseBetweenProducts && chooseBetweenProducts.length > 0} title={"Prodotti"} helper="Seleziona uno dei seguenti prodotti" close={() => setChooseBetweenProducts([])}>
                <div style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", paddingTop: "10px", display: "flex", flexDirection: "column", gap: "10px"}}>
                    {
                        chooseBetweenProducts.map((item, index) => 
                            <div style={{cursor: "pointer"}} onClick={() => {
                                searchProd(
                                    null, 
                                    null, 
                                    {
                                        Result: "OK",
                                        Json: [item]
                                    }
                                );
                                setChooseBetweenProducts([]);
                            }}>
                                {
                                    item.description
                                }
                            </div>
                        )
                    }
                </div>
            </MbMessage>
            <div style={{width: "100%", height: window.innerWidth >= 1000 ? "80px" : "", lineHeight: window.innerWidth >= 1000 ? "80px" : "", display: window.innerWidth >= 1000 ? "flex" : "", flexGrow: "1", borderBottom: "1px solid rgb(234, 234, 234)", marginTop: window.innerWidth >= 1000 ? "" : "20px", gap: "10px"}}>
                    
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "20px"}}>
                    <DeleteOutlineIcon sx={{color: "red", cursor: "pointer"}} onClick={() => {
                        if(line.id)
                            deleteLine();
                    }}/>
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <TextField id={"line_" + index.toString()} size="small" InputLabelProps={{shrink: true}} label="Codice" variant="outlined" disabled={line.id} InputProps={{
                        endAdornment: 
                            <SearchIcon sx={{cursor: "pointer"}} onClick={() => {
                                if(line.id) return;
                                validatorProduct();
                            }}/>
                    }} value={line.code ?? ''} onKeyDown={async (e) => {

                        if(e.key == 'Enter'){
                            searchProd('code', line.code);
                        }

                    }} onChange={(e) => {
                        line.code = e.target.value;
                        setRender(!render);
                    }} style={{width: window.innerWidth >= 1000 ? "120px" : "100%"}}/>
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <TextField size="small" InputLabelProps={{shrink: true}} label="Barcode" variant="outlined" disabled={line.id} value={line.barcode ?? ''}
                        onKeyDown={(e) => {
                            if(e.key == 'Enter'){
                                searchProd('barcode', line.barcode);
                            }
                        }}
                        onChange={(e) => {
                            line.barcode = e.target.value;
                            setRender(!render);
                        }}
                        style={{width: window.innerWidth >= 1000 ? "200px" : "100%"}}
                    />
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
                    <TextField size="small" InputLabelProps={{shrink: true}} label="Descrizione" variant="outlined" disabled value={line.description ?? ''} style={{width: "100%"}}/>
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: window.innerWidth >= 1000 ? "240px" : ""}}>
                    <TextField size="small" InputLabelProps={{shrink: true}} id={"txt_qta_line_" + index} label={`Quantità`} variant="outlined" value={line.quantity ?? ''} onChange={(e) => {
                        line.quantity = e.target.value;
                        setTax();
                    }} onKeyDown={(e) => {
                        if(e.key == 'Enter'){
                            document.querySelector('#txt_price_line_' + index).focus();
                        }
                    }} onFocus={(e) => {
                        e.target.select();
                    }}/>
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: window.innerWidth >= 1000 ? "240px" : ""}}>
                    <TextField size="small" InputLabelProps={{shrink: true}} id={"txt_price_line_" + index} label="Prezzo" variant="outlined" value={line.taxable} onChange={(e) => {
                        line.taxable = e.target.value;
                        setTax();
                    }} onKeyDown={(e) => {
                        if(e.key == 'Enter'){
                            document.querySelector('#txt_discount_line_' + index).focus();
                        }
                    }} onFocus={(e) => {
                        e.target.select();
                    }}/>
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: window.innerWidth >= 1000 ? "240px" : ""}}>
                    <TextField size="small" InputLabelProps={{shrink: true}} id={"txt_discount_line_" + index} label="Sconto" variant="outlined" value={line.discount_increase} onChange={(e) => {
                        line.discount_increase = e.target.value;
                        setTax();
                    }} onFocus={(e) => {
                        e.target.select();
                    }}/>
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: window.innerWidth >= 1000 ? "240px" : ""}}>
                    <TextField size="small" InputLabelProps={{shrink: true}} label="Aliquota" variant="outlined" value={line.vat ?? ''} onChange={(e) => {
                        line.vat = e.target.value;
                        setTax();
                    }}/>
                </div>

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                {/*<div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: window.innerWidth >= 1000 ? "120px" : ""}}>
                    <TextField size="small" InputLabelProps={{shrink: true}} label="Imposta" disabled variant="outlined" value={line.tax ?? ''}/>
                </div>*/}


                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                {/*<div style={{display: "flex", flexDirection: "column", justifyContent: "center"}} onClick={() => {
                    setAccordionExpanded(!accordionExpanded);
                }}>
                    {
                        accordionExpanded ? 
                            <ExpandLessIcon sx={{cursor: "pointer"}}/>
                        :
                            <ExpandMoreIcon sx={{cursor: "pointer"}}/>
                    }
                </div>*/}

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }

                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        accordionDetail()   
                }

            </div>
            
            {
                window.innerWidth >= 1000 ? 
                    accordionDetail()
                :
                    <></>
            }
        </>
    );

};

let adHocFuncAddProductRelated = null;

const MbDocBody = ({display}) => {

    const [lineIndex, setLineIndex] = useState(-1);
    const [lines, setLines] = useState([ Object.assign({}, MbLineModel) ]);
    const [validatorProduct, setValidatorProduct] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayDeleteMessage, setDisplayDeleteMessage] = useState(false);
    const [validatorComposition, setValidatorComposition] = useState(false);
    const [overridePrice, setOverridePrice] = useState(false);
    const [overridePriceValue, setOverridePriceValue] = useState(null);
    const [overrideQuantity, setOverrideQuantity] = useState(null);
    const [searchCode, setSearchCode] = useState(null);
    const [searchBarcode, setSearchBarcode] = useState(null);
    const [searchDescription, setSearchDescription] = useState(null);
    const [searchDataValidator, setSearchDataValidator] = useState(null);

    const cleanDoc = () => {
        setLineIndex(-1);
        setLines([ Object.assign({}, MbLineModel) ]);
        setValidatorProduct(null);
        setMessage(null);
        setDisplayDeleteMessage(false);
    };

    const openDoc = (e) => {

        const doc = JSON.parse(e.detail);
        setTimeout(() => {
            setLines(doc.lines);
        }, 1500);

    };

    useEffect(() => {
        document.getElementsByClassName('')
    }, [display]);

    const validateProductEvent = async (obj, lineIndex, moveToLine = true) => {
        
        // Se è un documento di carico o di ordine a fornitore, non seleziono i prodotti relazionati
        /*if( MbDocHead_Dto.causal && (MbDocHead_Dto.causal.charge > 0 || MbDocHead_Dto.causal.ordered > 0) ){
            if(obj && obj.related_products.length > 0){
                return;
            }
        }

        // In caso di vendita o di ordine a cliente, non seleziono gli ingredienti
        if( MbDocHead_Dto.causal && (MbDocHead_Dto.causal.discharge > 0 || MbDocHead_Dto.causal.engaged > 0) ){
            if(obj && obj.product_for_composition == 1){
                return;
            }
        }*/

        // Filtra listino 
        let list_price_id = null;
        try{
            list_price_id = MbDocHead_Dto.customer_supplier.list_price.id;
        }catch(_){}

        // Filtra aliquota
        let vat = null;
        try{
            vat = MbDocHead_Dto.customer_supplier.vat;
        }catch(_){ }

        if(list_price_id){

            // Filtra sul prodotto il listino di riferimento
            const filteredListPrice = obj.prices.filter(item => item.price_list.id == list_price_id);
            if(filteredListPrice && filteredListPrice.length > 0){

                if(filteredListPrice[0].taxable || filteredListPrice[0].taxable == 0){

                    // Aliquota, se presente su cliente imposta quella, in caso contrario
                    // quella del prodotto
                    let vat_value = vat ? vat.value : obj.vat.value;
                    if(MbDocHead_Dto.customer_supplier.vat){
                        vat_value = MbDocHead_Dto.customer_supplier.vat.value;
                    }

                    lines[lineIndex].id = obj.id;
                    lines[lineIndex].code = obj.code;
                    lines[lineIndex].description = obj.description;
                    lines[lineIndex].barcode = obj.barcode;
                    lines[lineIndex].taxable = parseFloat( parseFloat(filteredListPrice[0].taxable).toFixed(2) );
                    lines[lineIndex].vat = vat_value ?? 0;
                    lines[lineIndex].tax = (lines[lineIndex].taxable * lines[lineIndex].vat) / 100;
                    lines[lineIndex].um = obj.um;
                    lines[lineIndex].variants = [...obj.variants];
                    lines[lineIndex].related_products = [...obj.related_products];
                    lines[lineIndex].quantity = (obj.quantity ? obj.quantity : 1) * (obj.multiply_for ?? 1);
                    lines[lineIndex].discount_increase = filteredListPrice[0].discount ? (-1 * parseFloat(filteredListPrice[0].discount)) : 0.00;
                    lines[lineIndex].multiply_for = obj.multiply_for ?? 1;
                    lines[lineIndex].line_type = obj.product_type == 'complex' ? 'production' : 'simple';
                    lines[lineIndex].product_type = obj.role_type;
                    lines[lineIndex].department_number = obj.vat.department_number;
                    //lines[lineIndex].ultimate_cost = obj.ultimate_cost ?? 0;
                    
                    let newLines = [...lines];
                    const addNewLine = lineIndex + 1 == newLines.length;
                    if(addNewLine){
                        newLines.push(Object.assign({}, MbLineModel));
                    }
                    setLines(newLines);
                    RemoveHash();      
                    
                    // Spostati sulla casella quantità
                    if(moveToLine){
                        setTimeout(() => {
                            document.querySelector('#txt_qta_line_' + (newLines.length - 2).toString() ).focus();
                        }, 100);
                    }

                    return;
                }
            }

        }

        setMessage(`Attenzione, il prodotto non ha un prezzo impostato per il listino ${MbDocHead_Dto.customer_supplier.list_price.description}`);

    };

    const cleanLines = () => {
        setLines([ Object.assign({}, MbLineModel) ]);
        setLineIndex(-1);
    };

    useEffect(() => {
        
        function eHashChange(){
            setValidatorProduct(window.location.hash.includes('#product') && !validatorProduct);
            setValidatorComposition(window.location.hash.includes("#composition") && !validatorComposition);
        }

        window.addEventListener('open_doc', openDoc);
        window.addEventListener('clean_lines_doc', cleanLines);
        window.addEventListener('hashchange', eHashChange); 
        window.addEventListener('clean_doc', cleanDoc);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
                window.removeEventListener('clean_doc', cleanDoc);
                window.removeEventListener('clean_lines_doc', cleanLines);
                window.removeEventListener('open_doc', openDoc);
            }catch(_){}
        });

    }, []);

    useEffect(() => {
        MbDocHead_Dto.lines = [...lines];
        dispatchEvent(new CustomEvent('update_doc_deadlines'));
    }, [lines]);

    return (
        <>
            
            <MbMessage open={overridePrice} message="Sovrapprezzo" close={() => setOverridePrice(false)} title={"Sovrapprezzo"}>
                <div style={{padding: "20px", width: "250px"}}>
                    <TextField InputLabelProps={{shrink: true}} variant="outlined" label="Prezzo" style={{marginTop: "10px"}} size="small" value={overridePriceValue ?? ""} onChange={(e) => {
                        setOverridePriceValue(e.target.value);
                    }}></TextField>
                    <TextField InputLabelProps={{shrink: true}} variant="outlined" label="Quantità" style={{marginTop: "15px"}} size="small" value={overrideQuantity ?? ""} onChange={(e) => {
                        setOverrideQuantity(e.target.value);
                    }}></TextField>
                    <div></div>
                    <Button variant="contained" id="btn_new_mbtable" onClick={() => {
                        if( (overridePriceValue && !isNaN(overridePriceValue)) && (overrideQuantity && !isNaN(overrideQuantity) )){
                            if(adHocFuncAddProductRelated){
                                adHocFuncAddProductRelated(parseFloat(overridePriceValue), parseFloat(overrideQuantity));
                            }
                        }
                    }} style={{width: "100%", marginTop: "10px"}}>APPLICA</Button>
                </div>
            </MbMessage>

            <MbConfirm open={displayDeleteMessage} message={"Sicuro di voler cancellare la linea?"} confirm={() => {
                setDisplayDeleteMessage(false);
                const copyLines = [...lines];
                copyLines.splice(lineIndex, 1);
                setLines(copyLines);
            }} close={() => {
                setDisplayDeleteMessage(false);
                setLineIndex(-1);
            }}/>
            <MbMessage open={message} message={message} close={() => setMessage(null)} title="Mallbox"/>
            
            {/*<span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px"}}>Ricerca articoli</span>
            <div style={{width: "100%", display: window.innerWidth >= 1000 ? "flex" : "", flexGrow: window.innerWidth >= 1000 ? "1" : "", gap: window.innerWidth >= 1000 ? "10px" : "", paddingBottom: "10px", marginTop: "20px"}}>
                <div style={{width: window.innerWidth >= 1000 ? "120px" : "100%"}}>
                    <TextField style={{width: "100%"}} id="txt_code_parent" label="Codice" InputLabelProps={{shrink: true}} size="small" InputProps={{
                        endAdornment: 
                            <SearchIcon sx={{cursor: "pointer"}} onClick={() => {
                                setLineIndex(lines.length - 1);
                                window.location.hash += "#product";
                            }}/>
                    }} onKeyDown={async (e) => {
                        
                        if(e.key == 'Enter'){
                            //document.querySelector('#txt_code_parent').focus();
                            const data = await MbApi_ProductGetByCodeOrBarcode("code", searchCode, MbDocHead_Dto.customer_supplier.list_price.id);
                            if(data && data.Result == "OK"){
                                if(data.Json && data.Json.length > 0){
                                    validateProductEvent(data.Json[0], lines.length - 1, false);
                                    setSearchCode('');
                                    setSearchBarcode('');
                                    setSearchDescription('');
                                    return;
                                }
                            }
                            setSearchCode('');
                            setSearchDataValidator(searchCode);
                            window.location.hash += "#product";
                        }


                    }} value={searchCode} onChange={(e) => setSearchCode(e.target.value)}></TextField>
                </div>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }
                <div style={{width: window.innerWidth >= 1000 ? "200px" : "100%"}}>
                    <TextField label="Barcode" style={{width: "100%"}} InputLabelProps={{shrink: true}} size="small" value={searchBarcode} onChange={(e) => setSearchBarcode(e.target.value)}  onKeyDown={async (e) => {
                        
                        if(e.key == 'Enter'){
                            //document.querySelector('#txt_code_parent').focus();
                            const data = await MbApi_ProductGetByCodeOrBarcode("barcode", searchBarcode, MbDocHead_Dto.customer_supplier.list_price.id);
                            if(data && data.Result == "OK"){
                                if(data.Json && data.Json.length > 0){
                                    validateProductEvent(data.Json[0], lines.length - 1, false);
                                    setSearchCode('');
                                    setSearchBarcode('');
                                    setSearchDescription('');
                                    return;
                                }
                            }
                            setSearchCode('');
                            setSearchBarcode('');
                            setSearchDescription('');
                            setSearchDataValidator(searchBarcode);
                            window.location.hash += "#product";
                        }

                    }}></TextField>
                </div>
                {
                    window.innerWidth >= 1000 ? 
                        <></>
                    :
                        <div style={{height: "20px"}}/>
                }
                <div style={{width: "100%"}}>
                    <TextField label="Descrizione" InputLabelProps={{shrink: true}} size="small" style={{width: "100%"}} value={searchDescription} onChange={(e) => {
                        setSearchDescription(e.target.value);
                    }} onKeyDown={(e) => {
                        if(e.key == 'Enter'){
                            document.querySelector('#txt_code_parent').focus();
                            setSearchCode('');
                            setSearchBarcode('');
                            setSearchDescription('');
                            setSearchDataValidator(searchDescription);
                            window.location.hash += "#product";
                        }
                    }}></TextField>
                </div>
            </div>*/}

            <span style={{background: "var(--primary)", color: "white", borderRadius: "10000px", padding: "5px 10px 5px 10px"}}>Dettaglio articoli</span>
            <div style={{height: "10px"}}/>

            {
                lines.map((item, index) => 
                    <MbDocLine key={`line_${index}`} index={index} line={item} validatorProduct={() => {
                        setLineIndex(index);
                        window.location.hash += "#product";
                    }} validateProduct={(obj) => {
                        validateProductEvent(obj, index);
                    }} deleteLine={() => {
                        setDisplayDeleteMessage(true);
                        setLineIndex(index);
                    }} validateComposition={() => {
                        setLineIndex(index);
                        window.location.hash += "#composition";
                    }}/>
                )
            }
            
            {

                // Validatore prodotto
                validatorProduct ? 
                    <MbValidatorProduct
                        displayProductNotForComposition={ (MbDocHead_Dto.causal.discharge > 0 || MbDocHead_Dto.causal.engaged) ? '1' : null }
                        displayOnlySimpleProduct={ (MbDocHead_Dto.causal.charge > 0 || MbDocHead_Dto.causal.ordered) ? '1' : null}
                        searchDataValidator={searchDataValidator}
                        callbackFromValidator={(item) => {

                            const obj = item[item.length - 1];
                            validateProductEvent(obj, lineIndex == -1 ? lines.length - 1 : lineIndex);
                            setSearchDataValidator(null);

                        }}
                    />
                :
                    <></>

            }

            {

                // Validatore composizione
                validatorComposition ? 
                    <MbValidatorProduct
                        callbackFromValidator={(item) => {

                            const obj = item[item.length - 1];
                            lines[lineIndex].related_products = (lines[lineIndex].related_products ?? []);
                            const filtered = lines[lineIndex].related_products.filter( item => item.child_product.id == obj.id );
                            if(filtered.length > 0){
                                filtered[0].quantity = filtered[0].quantity + 1;
                                const newLines = [...lines];
                                setLines(newLines);
                                setOverridePriceValue(null);
                                setOverrideQuantity(null);
                                RemoveHash();
                                return;
                            }

                            adHocFuncAddProductRelated = (override_price, qta) => {
                        
                                lines[lineIndex].related_products.push({id: null, child_product: obj, quantity: qta, override_price: override_price});
                                //lines[lineIndex].taxable = lines[lineIndex].taxable + parseFloat(override_price);
                                const newLines = [...lines];

                                setLines(newLines);
                                setOverridePrice(false);
                                setOverridePriceValue(null);
                                setOverrideQuantity(null);
                                RemoveHash();

                            };

                            setOverridePrice(true);
                        }}
                        displayOnlyForComposition
                    />
                :
                    <></>

            }

        </>
    );

};

export {
    MbDocBody
}