import { Button, TextField, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_AgentCreate } from "../mb_api/mb_api_agent";
import { useNavigate } from "react-router-dom";
import { ReturnRootForNoAuth } from "../mb_api/mb_api_permission";

const MbAnagraficaNewAgent = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [agentCode, setAgentCode] = useState(null);
    const [agentDesc, setAgentDesc] = useState(null);
    const [name, setName] = useState(null);
    const [fiscalCode, setFiscalCode] = useState(null);
    const [vatNumber, setVatNumber] = useState(null);
    const [commission, setCommission] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);
    const navigate = useNavigate();

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){ }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){ 
            setId(structure[0]);
            setAgentCode(structure[1]);
            setAgentDesc(structure[2]);
            setName(structure[3]);
            setFiscalCode(structure[4]);
            setVatNumber(structure[5]);
            setCommission(structure[6]);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} agente</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={agentCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAgentCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={agentDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAgentDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Nome"} style={{width: "100%"}} value={name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setName(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice fiscale (opzionale)"} style={{width: "100%"}} value={fiscalCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setFiscalCode(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Partita IVA (opzionale)"} style={{width: "100%"}} value={vatNumber} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVatNumber(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Provvigioni"} style={{width: "100%"}} value={commission} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCommission(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!agentCode || !agentDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(agentCode.length > 5 || agentDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!name){
                        setMessage('Impostare nome agente!');
                        return;
                    }

                    if(commission && isNaN(commission)){
                        setMessage('Provvigione è un numero!');
                        return;
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_AgentCreate(JSON.stringify({
                            id: id,
                            code: agentCode,
                            description: agentDesc,
                            name: name,
                            fiscal_code: fiscalCode,
                            vat_number: vatNumber,
                            commission: commission ?? 0
                        }));

                        setDisplayBackDrop(false);

                        if(result){
                            setId(null);
                            setAgentCode('');
                            setAgentDesc('');
                            setName('');
                            setFiscalCode('');
                            setVatNumber('');
                            setCommission('');
                            comeback();
                        }
                        
                        setMessage(result ? 'Agente creato con successo!' : "Errore durante la creazione dell'agente");

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

        </>
    );

};

export {
    MbAnagraficaNewAgent
}