import { MbAnagraficaCustomerSupplier } from "../mb_anagrafiche/mb_tables/mb_anagrafica_customer_supplier";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorCustomerSupplier = ({entity_type, callbackFromValidator}) => {
    return (
        <>
            <MbMessage title={entity_type == 'customer' ? 'Cliente' : 'Fornitore'} helper={`Selezionare un ${entity_type == 'customer' ? 'cliente' : 'fornitore'}`} open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaCustomerSupplier entity_type={entity_type} isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorCustomerSupplier
}