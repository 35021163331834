import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { Endpoint } from "../mb_constants";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const MbValidatorNations = ({close, choosed}) => {
    
    const [countries, setCountries] = useState([]);

    useEffect(() => {

        fetch(`${Endpoint}/files/countries.json`).then(async (response) => {
            if(response.status == 200){
                const data = await response.json();
                const items = [];
                for(let key in data){
                    items.push([key, data[key]]);
                }
                setCountries(items);
            }
        });

        MbSaveColumnsForTable("nazioni", ["Codice", "Descrizione"]);

    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Nazioni">
                <div style={{padding: "20px"}}>
                    <MbTable
                        columns={
                            MbProposeColumns(
                                'nazioni',
                                [
                                    { name: "Codice"      },
                                    { name: "Descrizione" }
                                ]
                            )
                        }
                        rows={countries}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchLocal
                    />
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorNations
}