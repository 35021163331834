import { Button, TextField, Typography, Grid, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_ListPriceCreate } from "../mb_api/mb_api_list_price";

const MbAnagraficaNewListPrice = ({structure, comeback}) => {

    const [id, setId]                               = useState(null);
    const [code, setCode]                           = useState(null);
    const [desc, setDesc]                           = useState(null);
    const [useInApp, setUseInApp]                   = useState(null);
    const [message, setMessage]                     = useState(null);
    const [displayBackdrop, setDisplayBackDrop]     = useState(false);

    // Validatori

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){ }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
		    setId(structure[0]);
            setCode(structure[1]);
            setDesc(structure[2]);
            setUseInApp(structure[3] == 1 ? true : false);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} listino</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={desc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={useInApp} onChange={(e, checked) => {
                    setUseInApp(checked);
                }}/>} label="Listino default" />
            </FormGroup>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!code || !desc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(code.length > 5 || desc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_ListPriceCreate(JSON.stringify({
                            id: id,
                            code: code,
                            description: desc,
                            use_in_app: useInApp ? 1 : 0
                        }));

                        setDisplayBackDrop(false);

                        if(result){
                            setMessage('Listino creato con successo!');
                            setId(null);
                            setCode('');
                            setDesc('');
                            setUseInApp(false);
                            comeback();
                        }else{
                            setMessage('Errore durante la creazione del listino!');
                        }

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

        </>
    );

};

export {
    MbAnagraficaNewListPrice
}