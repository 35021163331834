import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import { Endpoint, PrimaryColor } from '../mb_constants';

const MbDocManageBill = () => {

    const [idDoc, setIdDoc] = useState(null);
    const [billName, setBillName] = useState(null);
    const [status, setStatus] = useState(null);
    const [statusEnum, setStatusEnum] = useState(null);
    const [message, setMessage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [arubaToken, setArubaToken] = useState(false);
    const [pdf, setPdf] = useState(null);

    const openDoc = async (e) => {
        
        const doc = JSON.parse(e.detail);
        setIdDoc(doc.id);
        await getBillStatus(doc.id);


    }

    const getBillStatus = async (id) => {

        const res = await fetch(
            `${Endpoint}/api/mb/v1/document/get_bill_status?id_doc=${id}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(res.status == 200){
            const json = await res.json();
            if(json.Result == "OK"){
                
                setStatus(json.Json.bill_status);
                setStatusEnum(json.Json.bill_status_enum);
                setBillName(json.Json.bill_name)

                if(json.Json.bill_name){
                    await getArubaBillStatus(id, json.Json.bill_name);
                }

            }
        }

    };

    const getArubaToken = async () => {

        try{

            if(arubaToken) return arubaToken;

            const res = await fetch(
                `${Endpoint}/api/mb/v1/aruba/get_aruba_token`,
                {
                    headers: {
                        'jwt': `Bearer ${localStorage.getItem('jwt')}`
                    }
                }
            );

            if(res.status == 200){
                const json = await res.json();
                if(json.Result == "OK"){
                    setArubaToken(json.Json.token);
                    return json.Json.token;
                }
            }
        }catch(_){}

        return null;

    };

    const getArubaBillStatus = async (idDoc, billName) => {

        const token = await getArubaToken();
            
        const res = await fetch(
            `${Endpoint}/api/mb/v1/aruba/get_aruba_bill_status?id_doc=${idDoc}`,
            {
                method: "POST",
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token, 
                    filename: billName
                })
            }
        );

        if(res.status == 201){
            const json = await res.json();
            if(json.Result == "OK"){
                setStatus(json.Json.status_code);
                setStatusEnum(json.Json.status_enum);
                setPdf(json.Json.pdf);
            }
        }

    };

    const sendBill = async (idDoc) => {

        setLoader(true);
        const token = await getArubaToken();
        if(token){

            const res = await fetch(
                `${Endpoint}/api/mb/v1/aruba/send_bill_to_aruba`,
                {
                    method: "POST",
                    headers: {
                        'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        aruba_token: token, 
                        id_doc: idDoc
                    })
                }
            );

            setLoader(false);
            if(res.status == 201){
                const json = await res.json();
                if(json.Result == "OK"){
                    setBillName(json.Json.bill_name);
                    setMessage("Fattura inviata. Potrai aggiornare manualmente lo stato per valutarne l'avanzamento");
                    setTimeout(() => {
                        getBillStatus(idDoc)
                    }, 3000);
                }else{
                    setMessage(json.Description);
                }
            }

        }else{
            setLoader(false);
            setMessage('Errore recupero token!');
        }

    };

    useEffect(() => {

        window.addEventListener('open_doc', openDoc);

        return (() => {
            try{
                window.removeEventListener('open_doc', openDoc);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={loader}/>
            <MbMessage message={message} open={message} close={() => setMessage(null)}/>
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "20px"}}>
                {
                    !billName ? 
                        <div style={{textAlign: "center"}}> 
                            <Typography variant="h5">Fattura da inviare</Typography>
                            <Button variant="contained" style={{background: PrimaryColor, marginTop: "10px"}} onClick={async () => await sendBill(idDoc)}>Invia adesso!</Button>
                        </div>
                    :
                        <div style={{textAlign: "center"}}>
                            <Typography variant="h5" style={{color: PrimaryColor, fontWeight: "bold"}}>{billName}</Typography>
                            <div style={{marginTop: "15px"}}>
                                <div style={{fontWeight: "bold", fontSize: "30px", border: "1px solid rgb(220, 220, 220)", padding: "15px"}}>{!statusEnum ? "STATO FATTURA NON DISPONIBILE" : `STATO FATTURA ${statusEnum.toUpperCase()}`}</div>
                                <Button variant="contained" style={{background: PrimaryColor, marginTop: "10px", width: "200px"}}
                                    disabled={
                                        status == '3' || status == '5' || status == '7' || status == '8' || status == '10'
                                    }
                                >Invia nuovamente</Button>
                                <Button variant="contained" style={{background: PrimaryColor, marginTop: "10px", marginLeft: "10px", width: "200px"}} onClick={async () => {
                                    if(billName){
                                        setLoader(true);
                                        setTimeout(async () => {
                                            await getArubaBillStatus(idDoc, billName);
                                            setLoader(false);
                                        }, 1000);
                                    }
                                }}>Aggiorna</Button>
                                <Button variant="contained" style={{background: PrimaryColor, marginTop: "10px", marginLeft: "10px", width: "200px"}} onClick={() => {
                                    if(billName){
                                        setLoader(true);
                                        setTimeout(async () => {
                                            await getArubaBillStatus(idDoc, billName);
                                            setLoader(false);
                                            if(pdf){
                                                let pdfWindow = window.open("");
                                                pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + pdf +"'></iframe>");
                                            }
                                        }, 1000);
                                    }
                                }}>DOWNLOAD PDF</Button>
                            </div>
                        </div> 
                }
            </div>
        </>
    );

};

export {
    MbDocManageBill
}