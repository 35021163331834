const MbWhiteSpace = ({customSpace}) => {
    return (
        window.innerWidth >= 1000 ? <></> : <div style={{height: customSpace ? customSpace : "10px"}}/>
    );
};

const SpaceMobile = 'calc(100% - 90px)';

export {
    MbWhiteSpace,
    SpaceMobile
}