import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { Endpoint } from "../mb_constants";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const recForPage = window.innerWidth >= 1000 ? 30 : 10;
const MbValidatorComuni = ({close, choosed}) => {
    
    const [comuni, setComuni] = useState([]);
    const [render, setRender] = useState(new Date().getTime());
    const [page, setPage] = useState(0);

    const wrapComuni = () => {
        const skip = page * recForPage;
        const sliceResult = comuni.slice(skip, skip + recForPage);
        return sliceResult;
    };

    useEffect(() => {

        MbSaveColumnsForTable("comuni", ["Codice", "CAP", "Nome", "Provincia", "Nome provincia"]);

        const local = localStorage.getItem('comuni');
        if(local){
            setComuni(JSON.parse(local));
            return;
        }

        const _comuni = [];
        fetch(`${Endpoint}/files/it.json`).then(async (response) => {
            if(response.status == 200){
                const data = await response.json();
                data.regioni.forEach((item) => {
                    item.province.forEach((_item) => {
                        _item.comuni.forEach((__item) => {
                            _comuni.push([__item.code, __item.cap, __item.nome, _item.code, _item.nome]);
                        })
                    });
                });
                localStorage.setItem('comuni', JSON.stringify(_comuni));
                setComuni(_comuni);
            }
        });

    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Comuni" isFullscreen>
                <div style={{paddingLeft: "10px", paddingRight: "10px", marginTop: "20px"}}>
                    <MbTable
                        table={"comuni"}
                        columns={
                            MbProposeColumns(
                                'comuni',
                                [
                                    { name: "Codice"            },
                                    { name: "CAP"               },
                                    { name: "Nome"              },
                                    { name: "Provincia"         },
                                    { name: "Nome provincia"    }
                                ]
                            )
                        }
                        rows={wrapComuni(page)}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchEventOutside={(value) => {
                            if(value){
                                const filtered = [];
                                const filterOver = JSON.parse(localStorage.getItem('comuni'));
                                filterOver.forEach((item) => {
                                    if(item[0].toLowerCase().includes(value.toLowerCase()) || item[1].toLowerCase().includes(value.toLowerCase()) || item[2].toLowerCase().includes(value.toLowerCase())){
                                        filtered.push(item);
                                    }
                                });
                                setComuni(filtered);
                            }else{
                                setComuni(JSON.parse(localStorage.getItem('comuni')));
                            }
                            setPage(0);
                        }}
                        changedColumnsTable={() => {
                            setRender(new Date().getTime());
                        }}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        page={page}
                        recordForPage={recForPage}
                        totalRecords={comuni.length}
                    />
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorComuni
}