import { Button, TextField, Typography, Grid, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_DestinationsCreate } from "../mb_api/mb_api_destinations";
import { MbValidatorNations } from "../mb_validator/mb_validator_nations";
import { MbValidatorComuni } from "../mb_validator/mb_validator_comuni";

const MbAnagraficaNewDestinations = ({structure, comeback, id_customer}) => {

    const [id, setId] = useState(null);
    const [destinationsCode, setDestinationsCode] = useState(null);
    const [destinationsDesc, setDestinationsDesc] = useState(null);
    const [collective, setCollective] = useState(null);
    const [address, setAddress] = useState(null);
    const [zip_code, setZip_code] = useState(null);
    const [province, setProvince] = useState(null);
    const [house_number, setHouse_number] = useState(null);
    const [country_code, setCountry_code] = useState(null);
    const [isDefault, setDefault] = useState(false);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);

    // Validatori
    const [validatorCountry, setValidatorCountry] = useState(false);
    const [validatorCollective, setValidatorCollective] = useState(false);

    const eKeyDown = (e) => {
        if(window.location.hash.includes('#countries') || window.location.hash.includes('#comuni')){
            return;
        }
        SaveAna(e);
    }

    function eHashChange(){ 
        setValidatorCountry(window.location.hash.includes('#countries') && !validatorCountry);
        setValidatorCollective(window.location.hash.includes('#comuni') && !validatorCollective);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            setId(structure[0]);
            setDestinationsCode(structure[1]);
            setDestinationsDesc(structure[2]);
            setCollective(structure[3]);
            setAddress(structure[4]);
            setZip_code(structure[5]);
            setProvince(structure[6]);
            setHouse_number(structure[7]);
            setCountry_code(structure[8]);
            setDefault(structure[9] == 1 ? true : false);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuova"} destinazione</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={destinationsCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDestinationsCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={destinationsDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setDestinationsDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Comune"} style={{width: "100%"}} value={collective} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCollective(e.target.value);
                    }} InputProps={{
                        endAdornment: <SearchIcon sx={{cursor: "pointer"}} onClick={() => {
                            window.location.hash += "#comuni";
                        }}/>
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Indirizzo"} style={{width: "100%"}} value={address} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAddress(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"CAP"} style={{width: "100%"}} value={zip_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setZip_code(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Nazione"} style={{width: "100%"}} value={country_code} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#countries";
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Numero civico (opzionale)"} style={{width: "100%"}} value={house_number} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setHouse_number(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                <TextField size="small" variant="outlined" label={"Provincia"} style={{width: "100%"}} value={province} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setProvince(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={isDefault} onChange={(e, checked) => {
                    setDefault(checked);
                }}/>} label="Destinazione predefinita" />
            </FormGroup>

            <div style={{height: "100px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!destinationsCode || !destinationsDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(destinationsCode.length > 5 || destinationsDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!address){
                        setMessage('Compilare indirizzo');
                        return;                        
                    }

                    if(!collective){
                        setMessage('Compilare comune');
                        return;                        
                    }

                    if(!zip_code){
                        setMessage('Compilare CAP');
                        return;                        
                    }

                    if(!province || (province && province.length != 2)){
                        setMessage('Compilare provincia con 2 caratteri!');
                        return;                        
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_DestinationsCreate(JSON.stringify({
                            id: id,
                            code: destinationsCode,
                            description: destinationsDesc,
                            collective: collective,
                            address: address,
                            zip_code: zip_code,
                            country_code: country_code,
                            house_number: house_number,
                            province: province,
                            default: isDefault ? 1 : 0
                        }), id_customer);

                        setDisplayBackDrop(false);
                        if(result){
                            setId(null);
                            setDestinationsCode('');
                            setDestinationsDesc('');
                            setCollective('');
                            setAddress('');
                            setZip_code('');
                            setCountry_code('');
                            setHouse_number('');
                            setProvince('');
                            setDefault(false);
                            comeback();
                        }

                        setMessage(result ? 'Destinazione creata con successo' : 'Errore durante la creazione della destinazione!');

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

            {
                // Validatore nazioni
                validatorCountry ? 
                    <MbValidatorNations close={() => {
                        window.location.hash = window.location.hash.split("#countries").join("");
                    }} choosed={(value) => {
                        setCountry_code(value[0]);
                        window.location.hash = window.location.hash.split("#countries").join("");
                    }}/>
                :
                    <></>
            }

            {

                // Validatore comuni

                validatorCollective ?
                    <MbValidatorComuni
                        choosed={(value) => {
                            window.location.hash = window.location.hash.split("#comuni").join("");
                            setCollective(value[2]);
                            setProvince(value[3]);
                            setZip_code(value[1]);
                        }}
                    />
                :
                    <></>

            }

        </>
    );

};

export {
    MbAnagraficaNewDestinations
}