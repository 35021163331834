import { MbAnagraficaOperator } from "../mb_anagrafiche/mb_tables/mb_anagrafica_operator";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorOperator = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Operatore" helper="Selezionare un operatore" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaOperator isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorOperator
}