import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @returns 
 */
const MbApi_VariantList = async (page, search, getAll = false) => {
    
    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/variant/list?page=${page}&record_for_page=${!getAll ? RecordsForPage : "9999"}${search ? `&search=${search}` : ''}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }

    }catch(_){
        
    }

    return null;
};

/**
 * 
 * @param {*} body 
 * @returns 
 */
const MbApi_VariantCreate = async (body) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/variant/create`,
        {
            method: "POST",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            },
            body: body
        }
    );

    try{
        if(response.status == 201){
            const res = await response.json();
            if(res.Result == "OK"){
                return res.Json;
            }
        }
    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} id_variant
 * @returns 
 */
const MbApi_VariantDelete = async (id_variant) => {

    const response = await fetch(
        `${Endpoint}/api/mb/v1/variant/delete?id_variant=${id_variant}`,
        {
            method: "DELETE",
            headers: {
                'jwt': `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': "application/json"
            }
        }
    );

    try{
        if(response.status == 200){
            const res = await response.json();
            if(res.Result == "OK"){
                return true;
            }
        }
    }catch(_){

    }

    return false;

};

export {
    MbApi_VariantList,
    MbApi_VariantCreate,
    MbApi_VariantDelete
}