import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { Endpoint } from "../mb_constants";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const MbValidatorModalitaPagamento = ({close, choosed}) => {

    const [modalita, setModalita] = useState([]);
    const [render, setRender] = useState(new Date().getTime());

    useEffect(() => {

        MbSaveColumnsForTable("modalita_pagamento", ["Codice", "Descrizione"]);

        const local = localStorage.getItem('modalita_pagamento');
        if(!local){
            fetch(`${Endpoint}/files/modalita_pagamento.json`).then(async (response) => {
                if(response.status == 200){
                    const data = await response.json();
                    const items = [];
                    data.forEach((item) => items.push([item.key, item.value]));
                    localStorage.setItem('modalita_pagamento', JSON.stringify(items));
                    setModalita(items);
                }
            });
        }else{
            setModalita(JSON.parse(local));
        }

    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Modalita Pagamento">
                <div style={{paddingLeft: "20px", paddingRight: "20px", marginTop: "20px"}}>
                    <MbTable
                        table={"modalita_pagamento"}
                        columns={
                            MbProposeColumns(
                                'modalita_pagamento',
                                [
                                    { name: "Codice"      },
                                    { name: "Descrizione" }
                                ]
                            )
                        }
                        rows={modalita}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchLocal
                        changedColumnsTable={() => {
                            setRender(new Date().getTime());
                        }}
                    />
                </div>
            </MbMessage>
        </>
    );

};

export {
    MbValidatorModalitaPagamento
}