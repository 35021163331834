import { Button, TextField, Typography, Grid, FormGroup, FormControlLabel, Switch, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_OperatorCreate } from "../mb_api/mb_api_operator";
import { MbValidatorDeviceSetting } from "../mb_validator/mb_validator_device_setting";
import { useNavigate } from "react-router-dom";

const MbAnagraficaNewOperator = ({structure, comeback}) => {

    const [id, setId] = useState(null);
    const [operatorCode, setOperatorCode] = useState(null);
    const [operatorDesc, setOperatorDesc] = useState(null);
    const [name, setName] = useState(null);
    const [access_code, setAccess_code] = useState(null);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);
    const [idSetting, setIdSetting] = useState(null);
    const [codeSetting, setCodeSetting] = useState(null);
    const [password, setPassword] = useState(null);
    const [printAdvanceAccount, setPrintAdvanceAccount] = useState(0);
    const [deleteAllSale, setDeleteAllSale] = useState(0);
    const [cancelReceipt, setCancelReceipt] = useState(0);
    const [fiscalClosure, setFiscalClosure] = useState(0);
    const [displayTxn, setDisplayTxn] = useState(0);
    const navigate = useNavigate();

    // Validatore parametri
    const [validatorParams, setValidatorParams] = useState(false);

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){ 
        setValidatorParams(window.location.hash.includes("#params") && !validatorParams);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
            setId(structure[0]);
            setOperatorCode(structure[1]);
            setOperatorDesc(structure[2]);
            setName(structure[3]);
            setAccess_code(structure[4]);
            setPrintAdvanceAccount(structure[structure.length - 1].print_advance_account);
            setDeleteAllSale(structure[structure.length - 1].delete_all_sale);
            setCancelReceipt(structure[structure.length - 1].cancel_receipt);
            setFiscalClosure(structure[structure.length - 1].fiscal_closure);
            setDisplayTxn(structure[structure.length - 1].display_transactions);
            setIdSetting(structure[structure.length - 1].device_setting ? structure[structure.length - 1].device_setting.id : null)
            setCodeSetting(structure[structure.length - 1].device_setting ? structure[structure.length - 1].device_setting.code : null)
            setPassword(structure[structure.length - 1].password);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} operatore</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={operatorCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setOperatorCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={operatorDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setOperatorDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Nome (opzionale)"} style={{width: "100%"}} value={name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setName(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice di accesso"} style={{width: "100%"}} value={access_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setAccess_code(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <TextField size="small" variant="outlined" label={"Password (opzionale)"} style={{width: "100%"}} value={password} InputLabelProps={{ shrink: true }} onChange={(e) => {
                setPassword(e.target.value);
            }}/>

            <div style={{height: "20px"}}/>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField size="small" variant="outlined" value={codeSetting} label={"Parametri (opzionale)"} style={{width: "100%"}} disabled InputLabelProps={{ shrink: true }} InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#params";
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            {/*<Divider/>

            <div style={{height: "20px"}}/>*/}

            {/*<Typography variant="caption" style={{fontSize: "25px"}}>PERMESSI OPERATORE IN APP</Typography>

            <FormGroup>
                <FormControlLabel label="Stampa di un preconto" control={<Switch checked={printAdvanceAccount == 1} onChange={(e, checked) => setPrintAdvanceAccount(checked == 1)}/>}></FormControlLabel>
                <FormControlLabel label="Cancella intera vendita" control={<Switch checked={deleteAllSale == 1} onChange={(e, checked) => setDeleteAllSale(checked == 1)}/>}></FormControlLabel>
                <FormControlLabel label="Annullo scontrino" control={<Switch checked={cancelReceipt == 1} onChange={(e, checked) => setCancelReceipt(checked == 1)}/>}></FormControlLabel>
                <FormControlLabel label="Chiusura fiscale" control={<Switch checked={fiscalClosure == 1} onChange={(e, checked) => setFiscalClosure(checked == 1)}/>}></FormControlLabel>
                <FormControlLabel label="Mostra transazioni" control={<Switch checked={displayTxn == 1} onChange={(e, checked) => setDisplayTxn(checked == 1)}/>}></FormControlLabel>
            </FormGroup>*/}

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!operatorCode || !operatorDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(operatorCode.length > 5 || operatorDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!access_code){
                        setMessage('Impostare codice di accesso operatore!');
                        return;
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_OperatorCreate(JSON.stringify({
                            id: id,
                            code: operatorCode,
                            description: operatorDesc,
                            name: name,
                            access_code: access_code,
                            print_advance_account: printAdvanceAccount ? 1 : 0,
                            delete_all_sale: deleteAllSale ? 1 : 0,
                            cancel_receipt: cancelReceipt ? 1 : 0,
                            fiscal_closure: fiscalClosure ? 1 : 0,
                            display_transactions: displayTxn ? 1 : 0,
                            password: password,
                            device_setting: idSetting ? { id: idSetting } : null,
                        }));

                        setDisplayBackDrop(false);

                        setMessage(result ? 'Operatore creato con successo' : "Errore durante la creazione dell'operatore!");   

                        if(result){
                            setId(null);
                            setOperatorCode('');
                            setOperatorDesc('');
                            setName('');
                            setAccess_code('');
                            setId(null);
                            setCodeSetting('');
                            setPassword('');
                            setPrintAdvanceAccount(0);
                            setDeleteAllSale(0);
                            setCancelReceipt(0);
                            setFiscalClosure(0);
                            setDisplayTxn(0);
                            comeback();
                        }

                    }, 1000);   
                    
                }} id="btn_save">SALVA</Button>
            </div>

            {
                // Validatore parametri
            }

            {
                validatorParams ? 
                    <MbValidatorDeviceSetting
                        callbackFromValidator={(value) => {
                            setIdSetting(value.id);
                            setCodeSetting(value.code);
                            navigate(-1);
                        }}
                    />
                :
                    <></>
            }

        </>
    );

};

export {
    MbAnagraficaNewOperator
}