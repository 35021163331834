import { Button, TextField, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_UmCreate } from "../mb_api/mb_um_api";

const MbAnagraficaNewUm = ({structure, comeback}) => {

    const [id, setId]         = useState(null);
    const [umCode, setUmCode] = useState(null);
    const [umDesc, setUmDesc] = useState(null);
    
    const [message, setMessage]                     = useState(null);
    const [displayBackdrop, setDisplayBackDrop]     = useState(false);

    // Validatori

    const eKeyDown = (e) => {
        SaveAna(e);
    }

    function eHashChange(){  }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
		    setId(structure[0]);
            setUmCode(structure[1]);
            setUmDesc(structure[2]);
        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>
            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuova"} unità di misura</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={umCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setUmCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={umDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setUmDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!umCode || !umDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    if(umCode.length > 5 || umDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_UmCreate(JSON.stringify({
                            id: id,
                            code: umCode,
                            description: umDesc
                        }));

                        setDisplayBackDrop(false);

                        let eMsg = "";
                        if(result){
                            setId(null);
                            setUmCode('');
                            setUmDesc('');
                            eMsg = "Unità di misura creata con successo!";
                            comeback();
                        }

                        setMessage(eMsg ? eMsg : "Errore durante la creazione dell'unità di misura!");

                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

        </>
    );

};

export {
    MbAnagraficaNewUm
}