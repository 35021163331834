import { Backdrop, CircularProgress } from "@mui/material";

const MbBackdrop = ({display, strong}) => {
    return (
        !strong ? 
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={display}>
                <CircularProgress color="inherit" />
            </Backdrop>
        :
            <Backdrop sx={{ background: 'white', zIndex: (theme) => theme.zIndex.drawer + 1}} open={display}>
                <CircularProgress color="inherit" />
            </Backdrop>
    );
};

export {
    MbBackdrop
}