var MbDocHead_Dto = {

    id                              : null,
    doc_date                        : null,
    note                            : null,
    operator                        : null,
    causal                          : null,
    payment                         : null,
    second_payment                  : null,
    customer_supplier               : null, 
    destination                     : null,
    vector                          : null,
    agent                           : null,
    table                           : null,
    department_production           : null,
    device                          : null,
    first_payment_paid              : null,
    second_payment_paid             : null,
    receipt_n_close                 : null,
    receipt_n_doc                   : null,
    ade_progressive                 : null,
    ade_id_transaction              : null,
    discount                        : null,
    transaction_unique_code         : null,
    expirations                     : [],
    lines                           : []

};

const MbDocHead_New = () => {
    MbDocHead_Dto = {
        id                              : null,
        doc_date                        : null,
        note                            : null,
        operator                        : null,
        causal                          : null,
        payment                         : null,
        second_payment                  : null,
        customer_supplier               : null, 
        destination                     : null,
        vector                          : null,
        agent                           : null,
        table                           : null,
        department_production           : null,
        device                          : null,
        first_payment_paid              : null,
        second_payment_paid             : null,
        receipt_n_close                 : null,
        receipt_n_doc                   : null,
        ade_progressive                 : null,
        ade_id_transaction              : null,
        discount                        : null,
        transaction_unique_code         : null,
        expirations                     : [],
        lines                           : []
    };
};

export {
    MbDocHead_New,
    MbDocHead_Dto
}