import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";
import { PrimaryColor } from "../mb_constants";

const MbDocGeneralField = ({value, type, label, change, disabled, search, searchClick, submitEvent, isOpenedDoc, id, style, multiline, rows}) => {

    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal(value);
    }, [value]);

    return (
        <TextField rows={rows} multiline={multiline} id={id ? id : ""} size="small" variant="outlined" type={type} label={label} style={{width: "100%", ...style}} disabled={disabled || isOpenedDoc} value={val ?? ''} InputLabelProps={{shrink: true}} onChange={(e) => {
            setVal(e.target.value);
            change(e.target.value);
        }} InputProps={{
            endAdornment: 
            
                search && !isOpenedDoc ? 
                    <>
                        <SearchIcon sx={{cursor: "pointer"}} onClick={() => {
                            if(disabled) return;
                            if(searchClick){
                                searchClick();
                            }
                        }}/> 
                        <>
                            {
                                val ? 
                                    <>
                                        &nbsp;
                                        <CloseIcon sx={{cursor: "pointer"}} onClick={() => {
                                            if(searchClick){
                                                searchClick('delete');
                                            }
                                        }}/>
                                    </>
                                :
                                    <></>
                            }
                        </>
                    </>
                : 
                    <></>

        }} onKeyDown={(e) => {
            if(submitEvent){
                if(e.key == 'Enter'){
                    submitEvent();
                }
            }
        }}></TextField>
    );

};

const MbDocContainer = ({children, style}) => {
    return (
        <div style={{width: "100%", ...style}} >
            {
                children
            }
        </div>
    );
};

const MbDocTitle = ({icon, title}) => {
    return (
        <>
            <Typography variant="h6" style={{color: PrimaryColor, fontWeight: "bold"}}>{icon}&nbsp;&nbsp;{title}</Typography>
        </>
    );
};

export {
    MbDocGeneralField,
    MbDocContainer,
    MbDocTitle
}