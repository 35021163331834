import { useEffect } from "react";
import { MbAnagraficaAgent } from "../mb_anagrafiche/mb_tables/mb_anagrafica_agent";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorAgent = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Agente" helper="Selezionare un agente" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaAgent isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorAgent
}