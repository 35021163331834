import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { Endpoint } from "../mb_constants";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const MbValidatorProvince = ({choosed, close}) => {

    const [province, setProvince] = useState([]);

    useEffect(() => {

        MbSaveColumnsForTable("province", ["Codice", "Descrizione"]);

        const local = localStorage.getItem('province');
        if(local){
            setProvince(JSON.parse(local));
            return;
        }

        fetch(`${Endpoint}/files/it.json`).then(async (response) => {
            if(response.status == 200){
                const data = await response.json();
                const items = [];
                data.regioni.forEach((item) => {
                    item.province.forEach((prv) => items.push([prv.code, prv.nome]));
                })
                localStorage.setItem('province', JSON.stringify(items));
                setProvince(items);
            }
        });

    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Province">
                <div style={{ padding: "20px"}}>
                    <MbTable
                        columns={
                            MbProposeColumns(
                                'province',
                                [
                                    { name: "Codice"      },
                                    { name: "Descrizione" }
                                ]
                            )
                        }
                        rows={[
                            ...province
                        ]}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchLocal
                    />
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorProvince
}