import { useEffect } from "react";
import { MbAnagraficaListPrice } from "../mb_anagrafiche/mb_tables/mb_anagrafica_list_price";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorListPrice = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Listino" helper="Selezionare un listino" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaListPrice isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorListPrice
}