import { useEffect, useState } from "react";
import { MbApi_DeviceSettingDelete, MbApi_DeviceSettingList } from "../../mb_api/mb_api_device_setting";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Permission, RecordsForPage, usePermission } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import { MbAnagraficaNewDeviceSetting } from "../mb_anagrafica_new_device_setting";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from "react-router-dom";

const MbAnagraficaDeviceSetting = ({isValidator, callbackFromValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(0);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const navigate                              = useNavigate();
    const permission                            = usePermission();

    function fetchData(){
        MbApi_DeviceSettingList(page, search).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.device_settings.map((item, index) => 
                        items.push([
                            item.id, 
                            item.code, 
                            item.description, 
                            item.fiscal_printer_model == 'custom_ws' ? "Custom Web Service" : "Epson Web Service", 
                            item.fiscal_printer_ip, 
                            item.fiscal_printer_port, 
                            item.fiscal_printer_serial, 
                            item.thermic_printer_model == 'custom' ? "Custom" : "Epson", 
                            item.thermic_printer_ip, 
                            item.thermic_printer_port, 
                            item.thermic_printer_serial,
                            item.display_for_categories == 1 ? <CheckIcon style={{color: "green"}}/> : <CloseIcon style={{color: "red"}}/>,
                            item.receipt_without_rt == 1 ? <CheckIcon style={{color: "green"}}/> : <CloseIcon style={{color: "red"}}/>,
                            item
                        ])
                    )
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        
        MbSaveColumnsForTable(
            "device_settings", 
            [
                "ID", 
                "Codice", 
                "Descrizione", 
                //"Modello fiscale", 
                //"IP fiscale", 
                //"Porta fiscale", 
                //"Seriale fiscale", 
                //"Modello termica", 
                //"IP termica", 
                //"Porta termica", 
                //"Seriale termica",
                //"Raggruppa in categorie",
                //"Documento commerciale"
            ]
        );

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewDeviceSetting
                        comeback={() => {
                            setPage(-1);
                            setStructUpdate(null);
                            //navigate(-1);
                        }}
                        structure={structUpdate}
                    />
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"device_settings"}
                        columns={
                            MbProposeColumns(
                                'device_settings',
                                [
					                {"name": "ID"}, 
                                    {"name": "Codice"}, 
                                    {"name": "Descrizione"}, 
                                    //{"name": "Modello fiscale"}, 
                                    //{"name": "IP fiscale"}, 
                                    //{"name": "Porta fiscale"}, 
                                    //{"name": "Seriale fiscale"}, 
                                    //{"name": "Modello termica"}, 
                                    //{"name": "IP termica"}, 
                                    //{"name": "Porta termica"}, 
                                    //{"name": "Seriale termica"},
                                    //{"name": "Raggruppa in categorie"},
                                    //{"name": "Documento commerciale"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={permission.p_param_delete == 1}
                        displayNew={permission.p_param_create == 1}
                        displayRead
                        displayUpdate={permission.p_param_create == 1}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={(data) => {
                            setStructUpdate(data);
                            window.location.hash = '#new';
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_DeviceSettingDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(data) => {
                            if(isValidator){
                                callbackFromValidator(data[data.length - 1]);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_DEVICE_SETTINGS"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaDeviceSetting
}