import { MbDocHead_Dto } from "./mb_doc_model";
import { v4 as uuidv4 } from 'uuid';

const MbDocGetAmount = () => {

    let taxableAmount = 0.00;

    if(MbDocHead_Dto && MbDocHead_Dto.lines.length > 0){
        MbDocHead_Dto.lines.filter(item => item.id).forEach((item) => {
        
            let taxable = parseFloat(item.taxable ?? 0);
            const discount_increase = parseFloat(item.discount_increase ?? 0);
            const quantity = parseFloat(item.quantity ?? 0);
            
            if(item.variants){
                item.variants.filter(v => v.checked).forEach((variant) => taxable += parseFloat(variant.override_price));
            }

            let effective_taxable = (taxable - ((taxable * discount_increase) / 100)) * quantity;
            taxableAmount += effective_taxable;

        });
    }

    return taxableAmount;
};

const MbDocGetAmountTax = () => {

    let taxAmount = 0.00;

    if(MbDocHead_Dto && MbDocHead_Dto.lines.length > 0){
        MbDocHead_Dto.lines.filter(item => item.id).forEach((item) => {
            taxAmount += parseFloat(item.tax ?? 0);
        });

    }

    return taxAmount;
};

const MbGetAmountLine = (item) => {

    let taxable_lordo = ( ( parseFloat(item.taxable ?? 0) + (MbSumVariants(item) ?? 0) ) );
    taxable_lordo -= ( (taxable_lordo * parseFloat(item.discount_increase ?? 0)) / 100 );
    taxable_lordo *= parseFloat(item.quantity ?? 1);
    return taxable_lordo;

};

const MbDocVatCastelletto = () => {

    let aliq = [];
    try{
        if(MbDocHead_Dto && MbDocHead_Dto.lines.length > 0){
            MbDocHead_Dto.lines.filter(item => item.id).forEach((item) => {

                const amount = item.tax ?? 0;
                const indexAliq = aliq.findIndex(a => a.vat == item.vat);
                if(indexAliq > -1){
                    aliq[indexAliq].amount = parseFloat(aliq[indexAliq].amount) + amount;
                }else{
                    aliq.push({vat: parseFloat(item.vat), amount: parseFloat(amount) });
                }
            });
        }
    }catch(_){
        aliq = [];
    }

    return aliq;
};

const MbSumVariants = (item) => {
    let amount = 0.00;
    if(item.variants && item.variants.length > 0 && item.variants.filter(v => v.checked).length > 0){
        item.variants.filter(v => v.checked).forEach((v) => amount += parseFloat(v.override_price ?? 0));
    }
    return amount;
};

const GetJsonDoc = (firstPaymentAmount, secondPaymentAmount) => {

    const lines         = [];
    const expirations   = [];
    
    MbDocHead_Dto.lines.filter(item => item.id)
    .forEach((item) => {

        const composedVariants = JSON.stringify(item.variants ?? []);

        const product_for_composition = [];
        (item.related_products ?? []).forEach((prd) => {
            product_for_composition.push({
                code                : prd.child_product.code ?? '',
                description         : prd.child_product.description ?? '',
                taxable             : 0,//parseFloat(prd.override_price) * parseFloat(item.quantity ?? 1),
                discount_increase   : 0,
                vat                 : parseFloat(item.vat ?? 0),
                tax                 : 0,
                quantity            : parseFloat(prd.quantity ?? 1) * parseFloat(item.quantity ?? 1),
                department_number   : item.department_number ?? 0,
                ultimate_cost       : 0.00,
                line_type           : 'simple',
                product_type        : prd.product_type,
                charge              : MbDocHead_Dto.causal.charge,
                discharge           : MbDocHead_Dto.causal.discharge,
                engaged             : MbDocHead_Dto.causal.engaged,
                ordered             : MbDocHead_Dto.causal.ordered,
                product             : { id: prd.child_product.id },
                storage             : { id: MbDocHead_Dto.causal.storage.id },
                variants            : "[]"
            });
        });

        lines.push({
            code                : item.code ?? '',
            description         : item.description ?? '',
            barcode             : item.barcode,
            taxable             : parseFloat(item.taxable ?? 0),
            discount_increase   : parseFloat(item.discount_increase ?? 0),
            vat                 : parseFloat(item.vat ?? 0),
            tax                 : parseFloat(item.tax ?? 0),
            quantity            : parseFloat(item.quantity ?? 1),
            department_number   : item.department_number ?? 0,
            ultimate_cost       : 0.00,
            line_type           : item.line_type,
            product_type        : item.product_type,
            charge              : MbDocHead_Dto.causal.charge,
            discharge           : MbDocHead_Dto.causal.discharge,
            engaged             : MbDocHead_Dto.causal.engaged,
            ordered             : MbDocHead_Dto.causal.ordered,
            variants            : composedVariants,
            production_lines    : product_for_composition,
            product             : { id: item.id },
            storage             : { id: MbDocHead_Dto.causal.storage.id }
        });
    });

    (MbDocHead_Dto.expirations ?? []).forEach((item) => {
        expirations.push({
            deadline_date   : item.date,
            percentage      : parseFloat( item.value_in_percentage ?? 0 ),
            status          : 'to_pay',
            value           : parseFloat( item.value ?? 0 ),
            description     : item.description ?? ''
        });
    });

    const transaction_unique_code = MbDocHead_Dto.transaction_unique_code ? MbDocHead_Dto.transaction_unique_code :  uuidv4().split("-").join("").toLocaleUpperCase().substring(0, 10);

    return {
        id                                  : MbDocHead_Dto.id,
        doc_date                            : MbDocHead_Dto.doc_date,
        doc_prefix                          : MbDocHead_Dto.causal.prefix,
        doc_counter                         : 0,
        doc_type                            : MbDocHead_Dto.causal.doc_ade_type,
        doc_area                            : MbDocHead_Dto.causal.doc_enum,
        accounting                          : MbDocHead_Dto.causal.accounting,
        note                                : MbDocHead_Dto.note,
        commission                          : MbDocHead_Dto.agent ? parseFloat(MbDocHead_Dto.agent.commission ?? 0) : 0,
        payment_code                        : MbDocHead_Dto.payment.code,
        payment_description                 : MbDocHead_Dto.payment.description,
        payment_type                        : MbDocHead_Dto.payment.payment_type,
        second_payment_code                 : MbDocHead_Dto.second_payment ? MbDocHead_Dto.second_payment.code : null,
        second_payment_description          : MbDocHead_Dto.second_payment ? MbDocHead_Dto.second_payment.description : null,
        second_payment_type                 : MbDocHead_Dto.second_payment ? MbDocHead_Dto.second_payment.payment_type : null,
        agent_code                          : MbDocHead_Dto.agent ? MbDocHead_Dto.agent.code : null,
        agent_description                   : MbDocHead_Dto.agent ? MbDocHead_Dto.agent.description : null,
        agent_name                          : MbDocHead_Dto.agent ? MbDocHead_Dto.agent.name : null,
        agent_fiscal_code                   : MbDocHead_Dto.agent ? MbDocHead_Dto.agent.fiscal_code : null,
        agent_vat_number                    : MbDocHead_Dto.agent ? MbDocHead_Dto.agent.vat_number : null,
        customer_supplier_type              : MbDocHead_Dto.customer_supplier.entity_type,
        customer_supplier_code              : MbDocHead_Dto.customer_supplier.code,
        customer_supplier_description       : MbDocHead_Dto.customer_supplier.description,
        customer_supplier_business_name     : MbDocHead_Dto.customer_supplier.business_name,
        customer_supplier_fiscal_code       : MbDocHead_Dto.customer_supplier.fiscal_code,
        customer_supplier_vat_number        : MbDocHead_Dto.customer_supplier.vat_number,
        customer_supplier_sdi_code          : MbDocHead_Dto.customer_supplier.sdi,
        customer_supplier_pec               : MbDocHead_Dto.customer_supplier.pec,
        customer_supplier_lottery_code      : MbDocHead_Dto.customer_supplier.lottery_code,
        destination_code                    : MbDocHead_Dto.destination ? MbDocHead_Dto.destination.code : null,
        destination_description             : MbDocHead_Dto.destination ? MbDocHead_Dto.destination.description : null,
        destination_address                 : MbDocHead_Dto.destination ? MbDocHead_Dto.destination.address : null,
        destination_zip_code                : MbDocHead_Dto.destination ? MbDocHead_Dto.destination.zip_code : null,
        destination_province                : MbDocHead_Dto.destination ? MbDocHead_Dto.destination.province : null,
        destination_house_number            : MbDocHead_Dto.destination ? MbDocHead_Dto.destination.house_number : null,
        destination_country_code            : MbDocHead_Dto.destination ? MbDocHead_Dto.destination.country_code : null,
        vector_code                         : MbDocHead_Dto.vector ? MbDocHead_Dto.vector.code : null,
        vector_description                  : MbDocHead_Dto.vector ? MbDocHead_Dto.vector.description : null,
        vector_country_code                 : MbDocHead_Dto.vector ? MbDocHead_Dto.vector.country_code : null,
        vector_vat_number                   : MbDocHead_Dto.vector ? MbDocHead_Dto.vector.vat_number : null,
        vector_fiscal_code                  : MbDocHead_Dto.vector ? MbDocHead_Dto.vector.fiscal_code : null,
        vector_business_name                : MbDocHead_Dto.vector ? MbDocHead_Dto.vector.business_name : null,
        operator_code                       : MbDocHead_Dto.operator ? MbDocHead_Dto.operator.code : null,
        operator_description                : MbDocHead_Dto.operator ? MbDocHead_Dto.operator.description : null,
        operator_name                       : MbDocHead_Dto.operator ? MbDocHead_Dto.operator.name : null,
        table_code                          : MbDocHead_Dto.table ? MbDocHead_Dto.table.code : null,
        table_description                   : MbDocHead_Dto.table ? MbDocHead_Dto.table.description : null,
        production_department_code          : MbDocHead_Dto.department_production ? MbDocHead_Dto.department_production.code : null,
        production_department_description   : MbDocHead_Dto.department_production ? MbDocHead_Dto.department_production.description : null,
        storage_code                        : MbDocHead_Dto.causal.storage.code,
        storage_description                 : MbDocHead_Dto.causal.storage.description, 
        payment                             : { id: MbDocHead_Dto.payment.id },
        second_payment                      : MbDocHead_Dto.second_payment ? { id: MbDocHead_Dto.second_payment.id } : null,
        agent                               : MbDocHead_Dto.agent ? { id: MbDocHead_Dto.agent.id } : null,
        customer_supplier                   : { id: MbDocHead_Dto.customer_supplier.id },
        destination                         : MbDocHead_Dto.destination ? { id: MbDocHead_Dto.destination.id } : null,
        vector                              : MbDocHead_Dto.vector ? { id: MbDocHead_Dto.vector.id } : null,
        operator                            : MbDocHead_Dto.operator ? { id: MbDocHead_Dto.operator.id } : null,
        table                               : MbDocHead_Dto.table ? { id: MbDocHead_Dto.table.id } : null,
        production_department               : MbDocHead_Dto.department_production ? { id: MbDocHead_Dto.department_production.id } : null,
        storage                             : { id: MbDocHead_Dto.causal.storage.id },
        device_code                         : MbDocHead_Dto.device ? MbDocHead_Dto.device.code : null,
        device_description                  : MbDocHead_Dto.device ? MbDocHead_Dto.device.description : null,
        device                              : { id: MbDocHead_Dto.device ? MbDocHead_Dto.device.id : null },
        discount                            : MbDocHead_Dto.discount ? parseFloat(MbDocHead_Dto.discount) : 0.00,
        lines                               : lines,
        expirations                         : expirations,
        user_data                           : null,
        payment_paid                        : firstPaymentAmount,
        second_payment_paid                 : secondPaymentAmount,
        receipt_n_close                     : MbDocHead_Dto.receipt_n_close,
        receipt_n_doc                       : MbDocHead_Dto.receipt_n_doc,
        ade_progressive                     : MbDocHead_Dto.ade_progressive,
        ade_id_transaction                  : MbDocHead_Dto.ade_id_transaction,
        transaction_unique_code             : transaction_unique_code
    };

};

export {
    MbDocGetAmount,
    MbGetAmountLine,
    MbSumVariants,
    MbDocVatCastelletto,
    MbDocGetAmountTax,
    GetJsonDoc
}