import { Endpoint, RecordsForPage } from "../mb_constants";

/**
 * 
 * @param {*} obj 
 * @returns 
 */
const MbApi_DocCreate = async (obj) => {

    try{
        const response = await fetch(
            `${Endpoint}/api/mb/v1/document/create`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                },
                body: JSON.stringify(obj)
            }
        );

        if(response.status == 201){
            const data = await response.json();    
            if(data.Result == "OK"){
                return data.Json;
            }
        }

    }catch(_){

    }

    return false;
};

/**
 * 
 * @param {*} page 
 * @param {*} search 
 * @param {*} doc_type 
 * @param {*} year 
 * @returns 
 */
const MbApi_DocList = async (page, search, doc_type, small_version = '1') => {

    try{

        let exercise = localStorage.getItem('exercise');
        if(!exercise){
            exercise = new Date().getFullYear().toString();
            localStorage.setItem('exercise', exercise);
        }

        const response = await fetch(
            `${Endpoint}/api/mb/v1/document/list?page=${page}&record_for_page=${RecordsForPage}&year=${exercise}&doc_area=${doc_type}&small_version=${small_version}${search ? `&search=${search}` : ''}`,
            {
                method: "GET",
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }

    }catch(_){ }

    return null;
};

/**
 * 
 * @param {*} id 
 * @returns 
 */
const MbApi_DocUserData = async (id) => {

    try{

        const response = await fetch(
            `${Endpoint}/api/mb/v1/document/get_user_data?id_doc=${id}`,
            {
                method: "GET",
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }

    }catch(_){ }

    return null;
};

/**
 * 
 * @param {*} id 
 * @returns 
 */
 const MbApi_DocXmlData = async (id) => {

    try{

        const response = await fetch(
            `${Endpoint}/api/mb/v1/document/get_xml_data?id_doc=${id}`,
            {
                method: "GET",
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            return data;
        }

    }catch(_){ }

    return null;
};

/**
 * 
 * @param {*} id 
 * @param {*} xml 
 * @returns 
 */
const MbApi_DocUpateXml = async (id, xml) => {

    try{

        const json = JSON.stringify({
            xml_document: xml
        });

        const response = await fetch(
            `${Endpoint}/api/mb/v1/document/update_xml_data?id_doc=${id}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                },
                body: json
            }
        );

        if(response.status == 201){
            const data = await response.json();
            if(data.Result == "OK") return true;
        }

    }catch(_){ }

    return false;
};

/**
 * 
 * @param {*} id 
 * @returns 
 */
 const MbApi_DownloadXml = async (id) => {

    try{

        const response = await fetch(
            `${Endpoint}/api/mb/v1/document/download_xml?id_doc=${id}`,
            {
                headers: {
                    'jwt': `Bearer ${localStorage.getItem('jwt')}`
                }
            }
        );

        if(response.status == 200){
            const data = await response.json();
            if(data.Result == "OK") return data;
        }

    }catch(_){ }

    return null;
};

export {
    MbApi_DocCreate,
    MbApi_DocList,
    MbApi_DocUserData,
    MbApi_DocXmlData,
    MbApi_DocUpateXml,
    MbApi_DownloadXml
}