import { configureStore } from "@reduxjs/toolkit";
import variantReducer from './mb_redux_variants';
import productCompositionReducer from './mb_redux_composition_product';
import categoriesReducer from './mb_redux_categories';

export default configureStore({
    reducer: {
        variants: variantReducer,
        product_composition: productCompositionReducer,
        categories: categoriesReducer
    }
});