import { useEffect, useState } from "react";
import { MbMessage } from "../mb_components/mb_message";
import { MbTable } from "../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../mb_table/mb_table_api";

const MbValidatorCustomerType = ({choosed, close}) => {

    const [render, setRender] = useState(false);

    useEffect(() => {
        MbSaveColumnsForTable("customer_type", ["Codice", "Descrizione"]);
        setTimeout(() => {
            setRender(!render);
        }, 250);
    }, []);

    return (
        <>
            <MbMessage open close={() => close()} title="Tipologia cliente" helper="Indica la tipologia di cliente">
                <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
                    <MbTable
                        columns={
                            MbProposeColumns(
                                'customer_type',
                                [
                                    { name: "Codice"      },
                                    { name: "Descrizione" }
                                ]
                            )
                        }
                        rows={[
                            ["FPA12", "Fattura verso pubblica amministrazione"],
                            ["FPR12", "Fattura verso privati"]
                        ]}
                        clickRow={(value) => {
                            choosed(value);
                        }}
                        searchLocal
                    />
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorCustomerType
}