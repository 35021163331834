import { useEffect } from "react";
import { MbAnagraficaVector } from "../mb_anagrafiche/mb_tables/mb_anagrafica_vector";
import { MbMessage } from "../mb_components/mb_message";

const MbValidatorVector = ({callbackFromValidator}) => {

    return (
        <>
            <MbMessage title="Corrieri / Vettori" helper="Selezionare un corriere" open isFullscreen>
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <MbAnagraficaVector isValidator callbackFromValidator={(item) => {
                        callbackFromValidator(item);
                    }}/>
                </div>
            </MbMessage>
        </>
    );
};

export {
    MbValidatorVector
}