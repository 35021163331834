import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { AppLogo, Endpoint, getRecordForPage, InDebug, MediaEndpoint, PrimaryColor, SecondayColor, setDisplayOnlyDash, setEnterpriseDto, setEnterpriseType, setIsSubUser, setPermission, WhiteLabelApp, _PassedFromLogin } from "../mb_constants";
import '../global_style.css';
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MbMessage } from "../mb_components/mb_message";

const MbLogin = () => {

    const [loading, setLoading]                 = useState(true);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const [message, setMessage]                 = useState("");
    const [type, setType]                       = useState("");
    const refUsername                           = useRef('');
    const refPassword                           = useRef('');
    const navigate                              = useNavigate();
    const [loginSub, setLoginSub]               = useState(false);
    const [forceRender, setForceRender]         = useState(new Date().getTime());
    const [rs, set_rs]                          = useState(null);
    const [asSub, setAsSub]                     = useState(false);

    const expireControl = (value) => {

        const date_expiration = new Date(value);
        if(new Date() >= date_expiration){
            return true;
        }
    
        return false;
    };

    const setPermissionFix = () => {

        setPermission(
            {
                "code": "",
                "description": "",
                "my_enterprise_create": 1,
                "my_enterprise_read": 1,
                "wallet_create": 1,
                "wallet_read": 1,
                "enterprise_office_create": 1,
                "enterprise_office_read": 1,
                "pos_operator_create": 1,
                "pos_operator_read": 1,
                "customer_supplier_create": 1,
                "customer_supplier_read": 1,
                "agent_create": 1,
                "agent_read": 1,
                "vector_create": 1,
                "vector_read": 1,
                "causal_create": 1,
                "causal_read": 1,
                "um_create": 1,
                "um_read": 1,
                "vat_create": 1,
                "vat_read": 1,
                "payment_create": 1,
                "payment_read": 1,
                "list_price_create": 1,
                "list_price_read": 1,
                "category_create": 1,
                "category_read": 1,
                "variant_create": 1,
                "variant_read": 1,
                "tipology_create": 1,
                "tipology_read": 1,
                "device_create": 1,
                "device_read": 1,
                "device_parameters_create": 1,
                "device_parameters_read": 1,
                "doc_create": 1,
                "doc_read": 1,
                "storage_create": 1,
                "storage_read": 1,
                "product_create": 1,
                "product_read": 1,
                "rest_room_create": 1,
                "rest_room_read": 1,
                "rest_table_create": 1,
                "rest_table_read": 1,
                "department_production_create": 1,
                "department_production_read": 1,
                "setting_create": 1,
                "setting_read": 1,
                "transaction_create": 1,
                "transaction_read": 1,
                "beb_room_create": 1,
                "beb_room_read": 1,
                "beb_planning_create": 1,
                "beb_planning_read": 1,
                "rest_table_planning_create": 1,
                "rest_table_planning_read": 1
            }
        );

    };

    const login = async (likeSubUser) => {

        localStorage.removeItem('subuser_id'); // Rimuovi subutente 

        setIsSubUser(false);
        setPermission(null);

        const username = refUsername.current.value;
        const password = refPassword.current.value;
        
        if(username){
            
            setLoading(true);
            
            if(asSub){
                try{
                    const resSub = 
                        await fetch(
                            `${Endpoint}/api/mb/v2/subuser/login`,
                            {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    email: username,
                                    password: password
                                })
                            }
                        );
                    if(resSub.status == 201){
                        const json = await resSub.json();
                        if(json.Result == "OK"){

                            setPermissionFix();
                            setLoading(false);

                            localStorage.setItem('subuser_id', json.Json.id_subuser);
                            localStorage.setItem('role_permission', JSON.stringify(json.Json.profile));
                            setEnterpriseType(json.Json.enterprise.enterprise_type);
                            dispatchEvent(new CustomEvent('layout_view'));
                            setEnterpriseDto(json.Json.enterprise);
                            localStorage.setItem('jwt', json.Json.bearer);
                            
                            setType("INFO");
                            setMessage("Benvenuto in Mallbox");
                            setDisplayMessage(true);
    
                            navigate('/dashboard');
                            return;
                        }
                    }

                }catch(_){}
                setLoading(false);
                setMessage("Login fallito! Riprova..");
                setDisplayMessage(true);

                return;
            }

            const pathLogin = likeSubUser ? "/api/mb/v1/subuser/login" : "/api/mb/v1/jwt/bearer_auth";

            fetch(
                `${Endpoint}${pathLogin}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        email: username,
                        password: password
                    })
                }
            ).then(async (response) => {
    
                setLoading(false);
    
                if(response && response.status == 201){
                    const decoded = await response.json();
                    if(decoded.Result == "OK"){

                        // Controlla modulo..
                        if(!decoded.Json.enterprise.packet || decoded.Json.enterprise.packet.length == 0){
                            setMessage('Nessun modulo abbinato!');
                            setDisplayMessage(true);
                            return;
                        }

                        if(decoded.Json.enterprise.locked_for_wallet_insufficient == 1){
                            setMessage('Bloccato per insufficienza di credito sul wallet..');
                            setDisplayMessage(true);
                            return;
                        }

                        // Controlla scadenza
                        if(decoded.Json.enterprise.subscription_type !== 'unlimited'){
                            if(expireControl(decoded.Json.enterprise.subscription_expiration)){
                                navigate('/renew_subscription');
                                return;
                            }
                        }

                        // Se sub utente, fai una breve analisi dei permessi..
                        if(decoded.Json.type == 'sub_user'){ 

                            setIsSubUser(true);
                            setPermission(decoded.Json.permission);

                        }else{
                            localStorage.setItem('role_permission', 'admin');
                            setPermissionFix();
                        }

                        setEnterpriseType(decoded.Json.enterprise.enterprise_type);
                        dispatchEvent(new CustomEvent('layout_view'));
                        setEnterpriseDto(decoded.Json.enterprise);
                        localStorage.setItem('jwt', decoded.Json.token);
                        
                        setType("INFO");
                        setMessage("Benvenuto in Mallbox");
                        setDisplayMessage(true);

                        navigate('/dashboard');
                        return;
                    }
                }

                setType("ERROR");
                setMessage("Login fallito! Riprova..");
                setDisplayMessage(true);
                refUsername.current.value = username;
                refPassword.current.value = password;
    
            });
    
    
            return;
        }
    };

    useEffect(() => {

        localStorage.setItem('exercise', new Date().getFullYear().toString());

        // Per eventuale login forzata!
        try{
  
            if(window.location.search){
                
                const splitted = window.location.search.replace("?", "").split("&");
                
                if(splitted[0].startsWith("onlyDashboard")){                    
                    const jwt = splitted[1].split("=")[1].trim();
                    localStorage.setItem('jwt', jwt);
                    try{
                        localStorage.removeItem('display_training');
                        if(window.location.search.includes("withTrain=1")){
                            localStorage.setItem('display_training', '1')
                        }else{
                            localStorage.setItem('display_training', '0')
                        }
                    }catch(_){}
                    try{
                        if(window.location.search.includes('locAmount')){
                            let splitted = window.location.search.split("&");
                            localStorage.setItem('local_amount', splitted[splitted.length - 1].replace("locAmount=", "").trim());
                        }else{
                            localStorage.removeItem('local_amount');
                        }
                    }catch(_){}
                    setDisplayOnlyDash();
                }

                if(splitted[0] == "clean_jwt"){
                    localStorage.removeItem('jwt');
                }
            
                if(splitted[1].startsWith("email") && splitted[2].startsWith("password")){
                    const email_to_place    = splitted[1].split("=")[1];
                    const password_to_place = splitted[2].split("=")[1];
                    setTimeout(() => {
                        document.querySelector('#mb_username').value = email_to_place;
                        document.querySelector('#mb_password').value = password_to_place;
                        document.querySelector('#mb_button_login').click();
                    }, 150);
                }
                
                window.history.replaceState({}, document.title, '/');
            }
        
        }catch(_){}

        getRecordForPage();

        setTimeout( async () => {

            _PassedFromLogin(true);

            let pathToRedirect = '/dashboard';
            if(window.location.pathname !== "/"){
                pathToRedirect = window.location.pathname;
                localStorage.setItem('path', pathToRedirect);
                window.location.pathname = "/";
                return;
            }

            if(localStorage.getItem('path')){
                pathToRedirect = localStorage.getItem('path');
                localStorage.removeItem('path');
            }

            const jwt = localStorage.getItem('jwt');
            if(jwt){
                
                const response = await fetch(
                    `${Endpoint}/api/mb/v1/jwt/validate_token`,
                    {
                        method: "GET",
                        headers: {
                            "jwt": `Bearer ${jwt}`
                        }
                    }
                );

                if(response.status == 200){
                    
                    const json = await response.json();
                    if(json.Result == "ERR"){
                        localStorage.removeItem('jwt');    
                    }else{

                        // Se subtenza aggiorna permission
                        try{
                            if(localStorage.getItem('subuser_id')){
                                const resPermission = 
                                    await fetch(
                                        `${Endpoint}/api/mb/v2/subuser/get_by_id?id_subuser=${localStorage.getItem('subuser_id')}`,
                                        {
                                            headers: {
                                                'jwt': localStorage.getItem('jwt')
                                            }
                                        }
                                    );
                                if(resPermission.status == 200){
                                    const jsonPermission = await resPermission.json();
                                    if(jsonPermission.Result == "OK"){
                                        localStorage.setItem('role_permission', JSON.stringify(jsonPermission.Json.profile));
                                    }
                                }
                            }
                        }catch(_){}

                        // Controlla modulo..
                        if(!json.Json.packet || json.Json.packet.length == 0){
                            setMessage('Nessun modulo abbinato!');
                            setDisplayMessage(true);
                            localStorage.removeItem('jwt');
                            navigate('/');
                            return;
                        }

                        if(json.Json.locked_for_wallet_insufficient == 1){
                            setMessage('Bloccato per insufficienza di credito sul wallet..');
                            setDisplayMessage(true);
                            localStorage.removeItem('jwt');
                            navigate('/');
                            return;
                        }

                        // Controlla scadenza
                        if(json.Json.subscription_type !== 'unlimited'){
                            if(expireControl(json.Json.subscription_expiration)){
                                navigate('/renew_subscription');
                                return;
                            }
                        }

                        if(json.Json.is_sub_user){
                            setIsSubUser(true);
                            setPermission(json.Json.permission);
                        }else{
                            setIsSubUser(false);
                            setPermission(
                                {
                                    "code": "",
                                    "description": "",
                                    "my_enterprise_create": 1,
                                    "my_enterprise_read": 1,
                                    "wallet_create": 1,
                                    "wallet_read": 1,
                                    "enterprise_office_create": 1,
                                    "enterprise_office_read": 1,
                                    "pos_operator_create": 1,
                                    "pos_operator_read": 1,
                                    "customer_supplier_create": 1,
                                    "customer_supplier_read": 1,
                                    "agent_create": 1,
                                    "agent_read": 1,
                                    "vector_create": 1,
                                    "vector_read": 1,
                                    "causal_create": 1,
                                    "causal_read": 1,
                                    "um_create": 1,
                                    "um_read": 1,
                                    "vat_create": 1,
                                    "vat_read": 1,
                                    "payment_create": 1,
                                    "payment_read": 1,
                                    "list_price_create": 1,
                                    "list_price_read": 1,
                                    "category_create": 1,
                                    "category_read": 1,
                                    "variant_create": 1,
                                    "variant_read": 1,
                                    "tipology_create": 1,
                                    "tipology_read": 1,
                                    "device_create": 1,
                                    "device_read": 1,
                                    "device_parameters_create": 1,
                                    "device_parameters_read": 1,
                                    "doc_create": 1,
                                    "doc_read": 1,
                                    "storage_create": 1,
                                    "storage_read": 1,
                                    "product_create": 1,
                                    "product_read": 1,
                                    "rest_room_create": 1,
                                    "rest_room_read": 1,
                                    "rest_table_create": 1,
                                    "rest_table_read": 1,
                                    "department_production_create": 1,
                                    "department_production_read": 1,
                                    "setting_create": 1,
                                    "setting_read": 1,
                                    "transaction_create": 1,
                                    "transaction_read": 1,
                                    "beb_room_create": 1,
                                    "beb_room_read": 1,
                                    "beb_planning_create": 1,
                                    "beb_planning_read": 1,
                                    "rest_table_planning_create": 1,
                                    "rest_table_planning_read": 1
                                }
                            ); 
                        }

                        setEnterpriseType(json.Json.enterprise_type);
                        dispatchEvent(new CustomEvent('layout_view'));
                        setEnterpriseDto(json.Json);
                        navigate(pathToRedirect);
                        return;
                    }

                }else{
                    localStorage.removeItem('jwt');
                }

            }

            setLoading(false);

        }, 0);

        const eHashChange = () => {
            setForceRender(new Date().getTime());
        };  

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            window.removeEventListener('hashchange', eHashChange);
        });

    }, []);

    return (
        <>
            <MbMessage open={displayMessage} message={message} close={()=> setDisplayMessage(false)}/>
            <MbBackdrop display={loading}/>
            {
                loading ? 
                    <></>
                :
                    <>
                        {
                            // classe = page-login
                        }
                        <div style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", flexDirection: "column"}}> 
                            {
                                // classe = login-form-container
                            }
                            <div style={{width: "100%", minHeight: "350px", maxHeight: "600px", display: "flex", justifyContent: "center"}}>
                                {
                                    // classe = login-form
                                }
                                <div className="login-form" style={{width: "400px"}}>
                                    <img src={WhiteLabelApp !== "mallbox" ? AppLogo : (MediaEndpoint + "/mallbox_logo_colored.svg")} style={{height: "70px"}}/>
                                        {
                                            window.location.hash == "#signup" ? 
                                                <>                                                
                                                    <TextField label="Ragione sociale" size="small" variant="outlined" value={rs} style={{marginTop: "30px"}} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                                        set_rs(e.target.value);
                                                    }}/>
                                                </>
                                            :
                                                <>
                                                </>
                                        }
                                        <TextField id="mb_username" label="Email / Username" size="small" variant="outlined" inputRef={refUsername} style={{marginTop: window.location.hash !== "#signup" ? "30px" : "20px", width: "400px"}} InputLabelProps={{ shrink: true }} onKeyDown={(e) => {
                                            if(window.location.hash !== "#signup"){
                                                if(e.key == 'Enter'){
                                                    login(loginSub);    
                                                }
                                            }
                                        }}/>
                                        <TextField id="mb_password" label="Password" variant="outlined" size="small" style={{marginTop: "20px", width: "400px"}} type="password"  inputRef={refPassword} InputLabelProps={{ shrink: true }} onKeyDown={(e) => {
                                            if(window.location.hash !== "#signup"){
                                                if(e.key == 'Enter'){
                                                    login(loginSub);    
                                                }
                                            }
                                        }}/>
                                        <Button variant="contained" sx={{backgroundColor: SecondayColor, height: "50px", marginTop: "20px", width: "400px"}} onClick={ async () => {
                                            if(window.location.hash == "#signup"){
                                                
                                                if(!refUsername.current.value || !refPassword.current.value || !rs) return;


                                                const response = await fetch(
                                                    `${Endpoint}/api/mb/v1/jwt/bearer_auth`,
                                                    {
                                                        method: "POST",
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            email: InDebug ? 'man@gmail.com' : "info@cmh.it",
                                                            password: InDebug ? 'manuel' : "Cmh@2022"
                                                        })
                                                    }
                                                );

                                                if(response.status == 201){
                                                    const json = await response.json();
                                                    if(json.Result == "OK"){
                                                        const resSignUp = await fetch(
                                                            `${Endpoint}/api/mb/v1/enterprise/create_sub_enterprise`,
                                                            {
                                                                method: "POST",
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    'jwt': json.Json.token
                                                                },
                                                                body: JSON.stringify({
                                                                    'description': rs,
                                                                    'business_name': rs,
                                                                    'fiscal_code': '00000000000000',
                                                                    'vat_number': '0000000000',
                                                                    'email_billing': "pec_" +refUsername.current.value,
                                                                    'email': refUsername.current.value,
                                                                    'password': refPassword.current.value,
                                                                    'enterprise_type': 'reseller',
                                                                    'subscription_type': 'unlimited',
                                                                    'subscription_price': 0,
                                                                    'credit_cost': 1, 
                                                                    'subscription_expiration': '2030-01-01',
                                                                    'wallet_credit': 0.00
                                                                })
                                                            }
                                                        );
                                                        if(resSignUp.status == 201){
                                                            if((await resSignUp.json()).Result == "OK"){
                                                                setDisplayMessage(true);
                                                                setMessage('Registrazione avvenuta con successo! Effettua login.');
                                                                setTimeout(() => {
                                                                    window.location.href = window.location.href.replace("#signup", "") + `?clean_jwt&email=${refUsername.current.value}&password=${refPassword.current.value}`;
                                                                }, 3000);
                                                                return;
                                                            }
                                                        }
                                                        setDisplayMessage(true);
                                                        setMessage('Errore durante la registrazione!');
                                                    }
                                                }

                                                return;
                                            }
                                            login(loginSub);
                                        }} id="mb_button_login">{window.location.hash == "#signup" ? "Registrati" : "Login"}</Button>
                                        {/*
                                            window.location.hash !== "#signup" ? 
                                                <>
                                                    <p onClick={() => {
                                                        window.location.hash = "#signup";
                                                    }}>Non hai un account? <b style={{color: PrimaryColor, cursor: "pointer"}}>Registrati!</b></p>
                                                </>
                                            :
                                                <></>
                                        */}
                                        <FormControlLabel control={<Checkbox checked={asSub} onChange={(e) => {
                                            setAsSub(e.target.checked);
                                        }}></Checkbox>} label="Login come utente profilato"></FormControlLabel>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export {
    MbLogin
}