import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryColor } from "../mb_constants";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const MbMessage = ({open, message, close, children, title, helper, isFullscreen, comebackWithoutRefresh}) => {

    const navigate = useNavigate();

    return (
        <>
            <Dialog open={open} onClose={() => {
                if(children) {
                    close();
                }
            }} fullScreen={isFullscreen}>
                {
                    children ? 
                        <>
                            <DialogTitle sx={{backgroundColor: PrimaryColor, color: "white", cursor: "pointer"}} onClick={() => {
                                if(isFullscreen && !comebackWithoutRefresh)
                                    navigate(-1);
                                else{
                                    close();
                                }
                            }}>
                                {
                                    isFullscreen ? 
                                        <>
                                            <ArrowBackIcon style={{color: "white", fontSize: "25px", cursor: "pointer"}}/>&nbsp;&nbsp;
                                        </>
                                    :
                                        <></>
                                }
                                {title}
                            </DialogTitle>
                            {
                                helper ? 
                                    <DialogContent sx={{marginTop: "15px"}}>
                                        <Typography>{helper}</Typography>
                                    </DialogContent>
                                :
                                    <></>
                            }
                            <div style={{marginTop: isFullscreen ? "10px" : "-10px"}}>
                                {children}
                            </div> 
                        </>
                    :
                        <>
                            <DialogTitle align="center">Mallbox</DialogTitle>
                            <DialogContent sx={{textAlign: "center"}}>
                                <Typography>{message}</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={close}>Ok</Button>
                            </DialogActions>
                        </>
                }
            </Dialog>
        </>
    );
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const MbConfirm = ({open, message, confirm, close}) => {

    return (
        <>
            <Dialog open={open} onClose={() => close()} >
                <DialogTitle align="center">Mallbox</DialogTitle>
                <DialogContent sx={{textAlign: "center"}}>
                    <Typography>{message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>Annulla</Button>
                    <Button onClick={confirm}>Conferma</Button>
                </DialogActions>
            </Dialog>
        </>
    );

};

export {  
    MbMessage,
    MbConfirm
}